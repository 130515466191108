<!DOCTYPE html>
<html lang="en">

<head>
  <meta http-equiv="X-UA-Compatible" content="IE=Edge" />
  <meta charset="utf-8" />
  <meta http-equiv="X-UA-Compatible" content="IE=edge" />
  <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no" />
  <link rel="icon" type="image/png" href="img/favicon.png" />
  <title>VSure.life</title>

  <style>
    li {
      display: block;
    }

    @media only screen and (min-width: 1200px) {
      .hadd_img {
        position: absolute;
        margin-top: 60px;
        margin-left: 93px;
      }
    }

    /* Small Devices, Tablets */
    /* Small Devices, Tablets */
    @media only screen and (max-width: 768px) {
      .hadd_img {
        position: none;
        margin-top: 52px;
        /* margin-left: -93px; */
      }

      .mob-v {
        margin-left: 30px;
      }
    }

    /* Extra Small Devices, Phones */
    @media only screen and (min-width: 480px) {
      .hadd_img {
        position: none;
        /* margin-top: -52px;
margin-left: -93px; */
      }
    }

    /*==========  Non-Mobile First Method  ==========*/


    .buy-new {
    margin-top: 5px ! important;
  
}
  </style>
</head>

<body id="mainBody">
  <header class="vsure-header">
    <div class="container">
      <div class="row">
        <div class="col-xs-2 col-sm-4 hidden-md hidden-lg">
          <div class="burger-menu">
            <a href="javascript:void(0)" data-toggle="collapse" data-target="#vsure-navbar"
              onClick="sendEvent('Menu', 'Click', 'Burger menu');">
              <i class="fa fa-bars" aria-hidden="true"></i>
            </a>
          </div>
        </div>
        <div class="col-md-5 hidden-xs hidden-sm">
          <ul class="top-left-nav top-nav">
            <li>
              <a class="smooth-scroll" href="#about" onClick="sendEvent('Menu', 'Click', 'About Vsure');">About
              </a>
            </li>
            <li>
              <a class="smooth-scroll" href="#works" onClick="sendEvent('Menu', 'Click', 'works');">How it Works</a>
            </li>
            <li>
              <a class="smooth-scroll" href="#offer" onClick="sendEvent('Burger-Menu', 'Click', 'Blog');"
                target="_blank">What We Offer</a>
            </li>
            <li>
              <a class="smooth-scroll" href="#partners" onClick="sendEvent('Menu', 'Click', 'Team');">Partners</a>
            </li>
            <!-- <li>
              <a class="smooth-scroll" href="#buynow" onClick="sendEvent('Menu', 'Click', 'Team');">Buy Now</a>
            </li> -->
            <li>
              <a class="smooth-scroll" href="/team" onClick="sendEvent('Menu', 'Click', 'Team');">Team</a>
            </li>
            <li>
              <a class="smooth-scroll" href="#contact" onClick="sendEvent('Menu', 'Click', 'Team');">Contact Us</a>
            </li>
          </ul>
        </div>
        <div class="col-xs-8 col-sm-4 col-md-2 text-center logo" style="padding-top:20px;">
          <a href="index.html" onClick="ga('send', 'event', 'landing page | home', 'mainmenu', 'logo');">
            <img src="assets/img/vsure_logo.svg" width="auto" height="64" class="black_logo" />
            <img src="assets/img/vsure_white.svg" width="auto" height="64" class="white_logo" /></a>
        </div>
        <div class="col-sm-4 col-md-5 hidden-xs">
          <ul class="top-nav">
            <li class="col-xs-1 pull-right">
              <a href="https://www.facebook.com/vsure.life/" target="_blank"><i class="fa fa-facebook"></i><span
                  class="sr-only">Facebook</span></a>
            </li>
            <!-- <li class="col-xs-1 pull-right">
                            <a href="#" target="_blank"><i class="fa fa-twitter"></i><span class="sr-only">Twitter</span></a>
                        </li> -->
            <li class="col-xs-1 pull-right">
              <a href="https://www.linkedin.com/company/vsuredotcom" target="_blank"><i class="fa fa-linkedin"></i><span
                  class="sr-only">LinkedIn</span></a>
            </li>
            <!-- <li class="col-xs-1 pull-right">
                            <a href="https://www.instagram.com/vsure.life/" target="_blank"><i class="fa fa-instagram"></i><span
                                    class="sr-only">Instagram</span></a>
                        </li> -->
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 hidden-md hidden-lg">
          <div class="collapsing vsure-navbar" id="vsure-navbar">
            <div class="container">
              <ul class="col-xs-12">
                <li>
                  <a class="smooth-scroll" href="#about"
                    onClick="sendEvent('Burger-Menu', 'Click', 'About Vsure');">About
                  </a>
                </li>
                <li>
                  <a class="smooth-scroll" href="#works" onClick="sendEvent('Menu', 'Click', 'works');">How it
                    Works</a>
                </li>

                <li>
                  <a class="smooth-scroll" href="#offer" onClick="sendEvent('Burger-Menu', 'Click', 'Blog');"
                    target="_blank">What We Offer</a>
                </li>

                <li>
                  <a class="smooth-scroll" href="#partner" onClick="sendEvent('Menu', 'Click', 'works');">Partners</a>
                </li>
                <!-- <li>
                  <a class="smooth-scroll" href="#buynow" onClick="sendEvent('Menu', 'Click', 'Team');">Buy Now</a>
                </li> -->
                <li>
                  <a class="smooth-scroll" href="/team" onClick="sendEvent('Menu', 'Click', 'Team');">Team</a>
                </li>
                <li>
                  <a class="smooth-scroll" href="/news" onClick="sendEvent('Menu', 'Click', 'Team');">News &
                    Events</a>
                </li>
              </ul>
              <ul class="col-xs-12 hidden-sm sub-nav">
                <li>
                  <a class="smooth-scroll" target="”_blank”" href="/assets/pdf/privacy-policy.pdf"
                    onClick="sendEvent('Footer', 'Click', 'Privacy Policy');">Privacy Policy</a>
                </li>
              </ul>
              <div class="social col-xs-12 hidden-sm">
                <h6>Follow us on Social Media</h6>
                <ul class="">
                  <li class="col-xs-1 pull-right">
                    <a href="https://www.facebook.com/vsure.life/" target="_blank"><i class="fa fa-facebook"></i><span
                        class="sr-only">Facebook</span></a>
                  </li>
                  <!-- <li class="col-xs-1 pull-right">
                                        <a href="#" target="_blank"><i class="fa fa-twitter"></i><span class="sr-only">Twitter</span></a>
                                    </li> -->
                  <li class="col-xs-1 pull-right">
                    <a href="https://www.linkedin.com/company/vsuredotcom" target="_blank"><i
                        class="fa fa-linkedin"></i><span class="sr-only">LinkedIn</span></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>

  <div class="home">
    <section id="banner" class="vsure-section vsure-hero"
      style="background-image: url(assets/img/vsure-mountain-banner.jpg);">
      <div class="container">
        <div class="banner-title-holder">
          <h1 class="banner-title">
            <p>On-Demand <br />Lifestyle Insurance</p>
          </h1>
          <p style="color:#fff;">
            Turn on or turn off your protection whenever, wherever and however
            you need it, for all types of protection
          </p>
          <h1 class="banner-title" style="font-size:30px; line-height: 40px;font-weight:400;">
            <p>Download VSure.life <br />Get your PROTECTION BUDDY now!</p>
          </h1>

          <div class="banner-watch">
            <a href="https://play.google.com/store/apps/details?id=com.vsure.live" target="_blank">
              <img src="assets/img/googleplay.png" width="150" /></a>
            &nbsp;
            <a href="https://itunes.apple.com/my/app/vsure-life-on-demand-insurance/id1460213328?mt=8" target="_blank">
              <img src="assets/img/app.png" width="150" /></a>
          </div>
          <div class="banner-watch">
            <button class="btn btn-lg btn-video smooth-scroll cta" data-parent="#accordion-next" data-toggle="collapse"
              data-target="#collapseForm-guineapig" onClick="sendEvent('Banner', 'Click', 'Get early access');">
              Get Notified
            </button>

            <!-- <button class="btn btn-lg btn-video smooth-scroll cta" data-parent="#accordion-next" data-toggle="collapse"
              data-target="#collapseForm-guineapig1" onClick="sendEvent('Banner', 'Click', 'Get early access');">
              Download App Now
            </button> -->
          </div>
        </div>
      </div>
      <video id="background-video" autoplay loop muted class="hidden-xs">
        <source src="./assets/video/vsure-combined-bg-720.mp4" type="video/mp4" />
      </video>
      <div class="color-overlay"></div>
    </section>

    <div class="beta"></div>

    <!------new section-->
    <div class="container-fluid" id="app" style="background-color:#cb3a79;">
      <section class="team">
        <div class="container" id="collapseForm-guineapig1">
          <div class="row" id="collapseForm-guineapig3" id="about">
            <br />
            <div class="col-md-10 col-md-offset-1">
              <div class="col-lg-12">
                <h3 style="color:#fff; font-size:35px; ">
                  VSure.Life Mobile App
                </h3>
                <span style="color:#fff; font-size:20px;">1st On-Demand Lifestyle Insurance in South East
                  Asia</span><br /><br />
                <span style="color:#fff; font-size:12px;">Easiest and most convenient way to get protected for your
                  active lifestyle.</span><br />
                <span style="color:#ffff; font-size:12px;">
                  Fast and easy process, gives you the Peace of Mind & Take
                  Control of what you buy.</span>

                <div class="row pt-md">
                  <br /><br /><br />
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 profile">
                    <div class="img-box">
                      <img src="assets/img/step5.png" class="img-responsive" />
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 profile">
                    <div class="img-box qr-code">
                      <p style="text-align: center; color:#ffffff; margin-top: 10px;">
                        GET OUR APP! DOWNLOAD NOW!
                      </p>
                      <div>
                        <div class="banner-watch">
                          <a href="https://play.google.com/store/apps/details?id=com.vsure.live" target="_blank">
                            <img src="assets/img/googleplay.png" width="130" /></a>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <a href="https://itunes.apple.com/my/app/vsure-life-on-demand-insurance/id1460213328?mt=8"
                            target="_blank">
                            <img src="assets/img/app.png" width="130" /></a>
                        </div>
                      </div>
                      <img src="assets/img/qr.png" class="img-responsive hidden-xs" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="container-fluid" style="height:5px; background-color:#e24f8f;"></div>
    <!----new section close-->

    <!-- START You Only Pay What You Use -->
    <section class="story">
      <div class="container">
        <div class="row">
          <div class="col-md-offset-2 col-md-8">
            <h2>You Only Pay What You Use</h2>
            <!-- <h3>
              VSure.life is South East Asia's first On-Demand Lifestyle
              Insurer, for all types of protection.
            </h3> -->
            <h3 style="line-height:30px">
              VSure.life is South East Asia's first On-Demand Lifestyle
              Insurer, for all types of protection.
            </h3>
            <h3 style="line-height:30px">
              VSure.life is South East Asia’s first On-Demand Lifestyle
              Digital Insurance for all types of protection. We have partnered
              with reputable insurers, in providing affordable insurance plans
              covering your lifestyle needs. Our mobile application is
              specifically design for easy on-boarding, viewing of insurance
              products, price look-up, viewing of the insurance benefits and
              coverage, making payment and keeping a history of your
              purchases.
            </h3>
            <div align="center">
              <img src="assets/img/life-insurance-landing-img.png" class="img-responsive" />
            </div>
            <div class="text">
              <h3 style="line-height:30px">
                VSure.life is founded by 9 ordinary individuals with a bold
                ambition to serve South East Asia's communities with insurance
                protection they truly deserve.<br />
                We envision VSure.life to be leading Digital Lifestyle Risk
                Partner & Insurer of South East Asia.<br />
                <strong>We are on the mission to provide </strong><br />
                <ul>
                  <li>people with access to affordable insurance</li>
                  <li>
                    on-demand & lifestyles protection in a simple & innovative
                    way
                  </li>
                  <li>
                    enabling social economic empowerment, adequate protection
                    and financial identity
                  </li>
                </ul>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- END You Only Pay What You Use -->

    <!-- START How Vsure.life Works -->
    <section class="bg-alternate" id="works">
      <div class="container margin-bottom-70">
        <div class="row">
          <div class="col-md-offset-2 col-md-8">
            <h2>How Vsure.life Works</h2>
            <div class="text">
              <div>
                Facial identification and verification is a security feature
                to protect the consumer from identity fraud. VSure wants to
                make sure you are who you are purchasing insurance.
              </div>
            </div>
            <div class="text">
              <div>In four easy steps, you will get protected on-the-go</div>
            </div>
          </div>
        </div>
      </div>
      <div id="carousel-gallery" class="carousel slide" data-ride="carousel" data-interval="false">
        <div class="container">
          <div class="row mob-v">
            <div id="thumbs">
              <div class="slider-thumbnail selected" id="carousel-selector-0"
                onClick="sendEvent('HowItWorks', 'Step 0');">
                <div class="vsure-teal-secondary-bg">
                  <img src="assets/img/step1.png" class="img-responsive hidden-sm hidden-xs" />
                  <div>
                    <span class="hidden-sm hidden-xs">STEP&nbsp;</span>1
                  </div>
                </div>
              </div>
              <div class="slider-thumbnail " id="carousel-selector-1" onClick="sendEvent('HowItWorks', 'Step 1');">
                <div class="vsure-teal-secondary-bg">
                  <img src="assets/img/step2.png" class="img-responsive hidden-sm hidden-xs" />
                  <div>
                    <span class="hidden-sm hidden-xs">STEP&nbsp;</span>2
                  </div>
                </div>
              </div>
              <div class="slider-thumbnail " id="carousel-selector-2" onClick="sendEvent('HowItWorks', 'Step 2');">
                <div class="vsure-teal-secondary-bg">
                  <img src="assets/img/step3.png" class="img-responsive hidden-sm hidden-xs" />
                  <div>
                    <span class="hidden-sm hidden-xs">STEP&nbsp;</span>3
                  </div>
                </div>
              </div>
              <div class="slider-thumbnail " id="carousel-selector-3" onClick="sendEvent('HowItWorks', 'Step 3');">
                <div class="vsure-teal-secondary-bg">
                  <img src="assets/img/step4.png" class="img-responsive hidden-sm hidden-xs" />
                  <div>
                    <span class="hidden-sm hidden-xs">STEP&nbsp;</span>4
                  </div>
                </div>
              </div>
              <div class="slider-thumbnail " id="carousel-selector-4" onClick="sendEvent('HowItWorks', 'Step 4');">
                <div class="vsure-teal-secondary-bg">
                  <img src="assets/img/step-5.png" class="img-responsive hidden-sm hidden-xs" />
                  <div>
                    <span class="hidden-sm hidden-xs">STEP&nbsp;</span>5
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-inner" role="listbox">
          <div class="item vsure-teal-secondary-bg active" data-slide-number="0">
            <div class="container">
              <div class="row">
                <div class="col-xs-12 item-content">
                  <div class="col-md-offset-0 col-md-5 col-xs-offset-1 col-xs-10">
                    <img src="assets/img/step1.png" class="img-responsive" />
                  </div>
                  <div class="col-md-7 col-xs-12">
                    <div class="item-title"><span> Step 1</span></div>
                    <p>
                      - Download & Register/Login
                      <br />- Easy onboarding with Email / Facebook / Google+
                      registration or login
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="item vsure-teal-secondary-bg " data-slide-number="1">
            <div class="container">
              <div class="row">
                <div class="col-xs-12 item-content">
                  <div class="col-md-offset-0 col-md-5 col-xs-offset-1 col-xs-10">
                    <img src="assets/img/step2.png" class="img-responsive" />
                  </div>
                  <div class="col-md-7 col-xs-12">
                    <div class="item-title">
                      <span>Step 2 </span>
                    </div>
                    <p>
                      - Select Insurance to purchase
                      <br />
                      - Choose from listing of insurance products that suits
                      your lifestyle
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="item vsure-teal-secondary-bg " data-slide-number="2">
            <div class="container">
              <div class="row">
                <div class="col-xs-12 item-content">
                  <div class="col-md-offset-0 col-md-5 col-xs-offset-1 col-xs-10">
                    <img src="assets/img/step3.png" class="img-responsive" />
                  </div>
                  <div class="col-md-7 col-xs-12">
                    <div class="item-title"><span>Step 3 </span></div>
                    <p>
                      - View Insurance product plans and information
                      <br />
                      - Insurance product plans and information at your
                      fingertips
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="item vsure-teal-secondary-bg " data-slide-number="3">
            <div class="container">
              <div class="row">
                <div class="col-xs-12 item-content">
                  <div class="col-md-offset-0 col-md-5 col-xs-offset-1 col-xs-10">
                    <img src="assets/img/step4.png" class="img-responsive" />
                  </div>
                  <div class="col-md-7 col-xs-12">
                    <div class="item-title"><span>Step 4 </span></div>
                    <p>
                      - Provide Personal Information (KYC)
                      <br />
                      - One time provision of your personal information upon
                      purchase on our Know-Your-Customer (KYC) page for future
                      purchases and your information is protected.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="item vsure-teal-secondary-bg " data-slide-number="4">
            <div class="container">
              <div class="row">
                <div class="col-xs-12 item-content">
                  <div class="col-md-offset-0 col-md-5 col-xs-offset-1 col-xs-10">
                    <img src="assets/img/step-5.png" class="img-responsive" />
                  </div>
                  <div class="col-md-7 col-xs-12">
                    <div class="item-title"><span>Step 5 </span></div>
                    <p>
                      - Buy & Make Payment
                      <br />
                      - Simple and quick purchase & making payment online
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a class="left carousel-control" href="#carousel-gallery" role="button" data-slide="prev"
          onClick="sendEvent('HowItWorks', 'Prev');">
          <i class="fa fa-chevron-left"></i>
          <span class="sr-only">Previous</span>
        </a>
        <a class="right carousel-control" href="#carousel-gallery" role="button" data-slide="next"
          onClick="sendEvent('HowItWorks', 'Next');">
          <i class="fa fa-chevron-right"></i>
          <span class="sr-only">Next</span>
        </a>
      </div>
    </section>
    <!-- END How Vsure.life Works -->

    <!-- START The VSure.life Way -->
    <section class="bg-alternate ways" id="offer">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h2>The VSure.life Way</h2>
          </div>
        </div>
        <div class="row way-holder">
          <div class="col-md-4 col-xs-12 way">
            <img src="assets/img/vsure-website-april2016-design-elements-03-teal.png" class="img-circle img-holder"
              alt="Fair" onClick="sendEvent('ABetterWay', 'Click', 'Fair');" />
            <p class="title margin-top-15" onClick="sendEvent('ABetterWay', 'Click', 'Fair');">
              Health & Medical
            </p>

            <div class="content-holder">
              <div class="details content margin-top-20">
                <p>
                  Caring for your health & medical needs with the right
                  protection
                </p>
                <br />
                <a href="/e-medic">
                  <p style="font-size:14px;color:#cb3a79">
                    Online Medical/Hospitalization Insurance <br>
               <b>( eMedic ) </b>
                    <span><br><button class="btn btn-md btn-video buy-new "> Buy Now </button></span>
                  </p>
                  </a>
                  
                <a href="/cancercare" >
                  <p style="font-size:14px;color:#cb3a79; margin-top: 20px;">
                    Critical Illness Insurance<br>
                    
                  <b>( CancerCare )</b>  <br />
                    <span><button class="btn btn-md btn-video buy-new"> Buy Now </button></span>
                  </p>
                </a>
              </div>
            </div>
          </div>

          <div class="col-md-4 col-xs-12 way">
            <img src="assets/img/vsure-website-april2016-design-elements-01-teal.png" class="img-circle img-holder"
              alt="Relevant" onClick="sendEvent('ABetterWay', 'Click', 'Relevant');" />
            <p class="title margin-top-15" onClick="sendEvent('ABetterWay', 'Click', 'Relevant');">
              Life Protection
            </p>
            <div class="content-holder">
              <div class="details content margin-top-20">
                <p>
                  Giving you a peace of mind knowing that your loved ones are
                  well taken care
                </p>
                <br />
                <a href="/e-life">
                  <p style="font-size:14px;color:#cb3a79">
                    Online Affordable Term Life<br> 
                  <b>( eLife Protector )</b>  <br />
                    <span><button class="btn btn-md btn-video buy-new "> Buy Now </button></span>
                  </p>
                </a>
              </div>
            </div>
          </div>

          <div class="col-md-4 col-xs-12 way">
            <img src="assets/img/vsure-website-april2016-design-elements-04-teal.png" class="img-circle img-holder"
              alt="Clear" onClick="sendEvent('ABetterWay', 'Click', 'Clear');" />
            <p class="title margin-top-15" onClick="sendEvent('ABetterWay', 'Click', 'Clear');">
              On-Demand Insurance
            </p>
            <div class="content-holder">
              <div class="details content margin-top-20">
                <p>
                  Covering everything every time and covering precisely the
                  risks faced at a certain moment. You only pay what you need.
                </p>

                <p style="cursor: pointer;" class="smooth-scroll cta" data-parent="#accordion-next"
                  data-toggle="collapse" data-target="#collapseForm-guineapig"
                  onClick="sendEvent('Banner', 'Click', 'Get early access');" style="font-size:14px;color:#cb3a79"><br>
                  Coming Soon
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="row way-holder">
          <div class="col-md-4 col-xs-12 way">
            <img src="assets/img/travelinsurance.png" class="img-circle img-holder" alt="Fair"
              onClick="sendEvent('ABetterWay', 'Click', 'Fair');" />
            <p class="title margin-top-15" onClick="sendEvent('ABetterWay', 'Click', 'Fair');">
              Travel Insurance
            </p>
            <div class="content-holder">
              <div class="details content margin-top-20">
                <p>
                  Let us take care of you when you are traveling for business
                  or enjoying your holiday
                </p>

                <a href="/travel">
                  <br>
                  <p style="font-size:14px;color:#cb3a79">
                    Travel Insurance
                    <br />
                  
                    <b>( Travel Care )
                    </b> <br> <span><button class="btn btn-md btn-video buy-new "> Buy Now </button></span>
                  </p>
                </a>
                <!-- <a href="/travel">
                  <p style="font-size:14px;color:#cb3a79">
                   <b>( Travel Care )</b>
                  </p>
                </a> -->

               
                <!-- <a href="/roadside">
                    <p style="font-size:14px;color:#cb3a79">Enhanced Road Warrior (Roadside Assistance)</p>
                    <p style="font-size:14px;color:#cb3a79">
                         <b>( Road Side )</b>
                    </p>
                  </a> -->
                <!-- <p style="cursor: pointer;" class="smooth-scroll cta" data-parent="#accordion-next"
                    data-toggle="collapse" data-target="#collapseForm-guineapig"
                    onClick="sendEvent('Banner', 'Click', 'Get early access');" style="font-size:14px;color:#cb3a79">
                    Coming Soon
                  </p> -->
              </div>
            </div>
          </div>

          <div class="col-md-4 col-xs-12 way">
            <img src="assets/img/motar.png" class="img-circle img-holder" alt="Relevant"
              onClick="sendEvent('ABetterWay', 'Click', 'Relevant');" />
            <p class="title margin-top-15" onClick="sendEvent('ABetterWay', 'Click', 'Relevant');">
              Motor Insurance
            </p>
            <div class="content-holder">
              <div class="details content margin-top-20">
                <p>
                  Get a reliable protection on your daily commute asset
                  (motorcycle and car)
                </p>

                <a href="/motor">
                  <br>
                  <p style="font-size:14px;color:#cb3a79">
                    Motor Insurance
                    <br />
                    
                    <b>( Motor Comprehensive Cover )
                    </b><br><span><button class="btn btn-md btn-video buy-new "> Buy Now </button></span>
                  </p>
                </a>

                <a href="/roadside">
                  <p style="font-size:14px;color:#cb3a79; margin-top: 20px;">
                    Roadside Assistance
                    <br /> <b>( Enhanced Road Warrior )
                    </b><br><span><button class="btn btn-md btn-video buy-new"> Buy Now </button></span>
                  </p>
                </a>
                <!-- <p style="cursor: pointer;" class="smooth-scroll cta" data-parent="#accordion-next"
                    data-toggle="collapse" data-target="#collapseForm-guineapig"
                    onClick="sendEvent('Banner', 'Click', 'Get early access');" style="font-size:14px;color:#cb3a79">
                   


                   <a href="/e-life">
                    <p style="font-size:14px;color:#cb3a79">
                   
                      <br /><b>(Motor)</b>
                    </p>
                  </a>
                  </p> -->
              </div>
            </div>
          </div>

          <div class="col-md-4 col-xs-12 way">
            <img src="assets/img/home.png" class="img-circle img-holder" alt="Clear"
              onClick="sendEvent('ABetterWay', 'Click', 'Clear');" />
            <p class="title margin-top-15" onClick="sendEvent('ABetterWay', 'Click', 'Clear');">
              Home Insurance
            </p>
            <div class="content-holder">
              <div class="details content margin-top-20">
                <p>
                  Home is a place blessed, where you and your family can be
                  secure. Protect your priced assets that you love.
                </p>


                <a href="/homeinsurance">
                  <br>
                  <p style="font-size:14px;color:#cb3a79">
                    Home Insurance
                    <br /><b>( Smart Home Cover )

                    </b> <br><span><button class="btn btn-md btn-video buy-new"> Buy Now </button></span>
                  </p>
                </a>
                <!-- <a href="/homeinsurance">
                  <p style="font-size:14px;color:#cb3a79">
                  <b>( Smart Home Cover )</b>
                  </p>
                </a> -->
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
    <!-- END The VSure.life Way -->

    <!-- <section class="bg-alternate ways" id="buynow" style="background-color: #e24f8f">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h2 class="item-title" style="color:#fff">
              Insurance @ VSure Online
            </h2>
            <h2 class="item-title" style="color:#fff; font-size: 24px;">
              Get protected! BUY NOW!
            </h2>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-xs-12 item-content">
              <div class="col-md-12 col-xs-12" style="text-align: center">
                <br />
                <p class="title margin-top-15" style="font-size:26px; color:#fff">
                  <span style="font-weight: bold">VSure.Life</span> –
                  <span style="font-size:26px; ">
                    On-Demand Lifestyle Insurance<br />
                  </span>
                </p>

                <p style="font-size:20px;color:#fff; margin-top: 20px; ">
                  If you are interested to purchase an insurance now, you can
                  also purchase through VSure online
                </p>
                <p style="font-size:20px;color:#fff;">
                  Don’t wait to get protected. Start to get yourself covered
                  now!
                </p>

              </div>
              <div class="col-md-offset-0 col-md-0 col-xs-offset-1 col-xs-10 visible-lg">
                <br /><br />
                <br />
                  </div>
              <div class="col-md-offset-0 col-md-6 col-xs-12 visible-xs">
                <br /><br />
                <br />
                   </div>
            </div>
          </div>
        </div>
      </div>
    </section> -->
    <section class="team" id="partners" class="hidden-xs">
      <div class="container">
        <div class="row">
          <div class="col-md-10 col-md-offset-1">
            <div class="col-lg-12">
              <h2>Our Partners & Affiliates</h2>
              <div class="row pt-md">
                <div class="col-lg-3 col-md-3 col-sm-4 col-xs-6 profile">
                  <div class="img-box">
                    <img src="assets/img/p1.jpg" class="img-responsive" />
                  </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-4 col-xs-6 profile">
                  <div class="img-box">
                    <img src="assets/img/p2.jpg" class="img-responsive" />
                  </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-4 col-xs-6 profile">
                  <div class="img-box">
                    <img src="assets/img/p3.jpg" class="img-responsive" />
                  </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-4 col-xs-6 profile">
                  <div class="img-box">
                    <img src="assets/img/p4.jpg" class="img-responsive" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <br /><br />
          <div class="col-md-10 col-md-offset-1">
            <div class="col-lg-12">
              <div class="row pt-md">
                <div class="col-lg-3 col-md-3 col-sm-4 col-xs-6 profile">
                  <div class="img-box">
                    <img src="assets/img/p5.jpg" class="img-responsive" />
                  </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-4 col-xs-6 profile">
                  <div class="img-box">
                    <img src="assets/img/p6.jpg" class="img-responsive" />
                  </div>
                </div>
                <!-- <div class="col-lg-3 col-md-3 col-sm-4 col-xs-6 profile">
                                    <div class="img-box">
                                        <img src="assets/img/p11.png" class="img-responsive">
                                    </div>
                                </div> -->
                <div class="col-lg-3 col-md-3 col-sm-4 col-xs-6 profile">
                  <div class="img-box">
                    <img src="assets/img/p7.png" class="img-responsive" />
                  </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-4 col-xs-6 profile">
                  <div class="img-box">
                    <img src="assets/img/p8.png" class="img-responsive" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <br /><br />
          <div class="col-md-10 col-md-offset-1">
            <div class="col-lg-12">
              <div class="row pt-md">
                <div class="col-lg-3 col-md-3 col-sm-4 col-xs-6 profile">
                  <div class="img-box">
                    <img src="assets/img/p9.png" class="img-responsive" />
                  </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-4 col-xs-6 profile">
                  <div class="img-box">
                    <img src="assets/img/p10.png" class="img-responsive" />
                  </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-4 col-xs-6 profile">
                  <div class="img-box">
                    <!-- <img src="assets/img/p8.png" class="img-responsive"> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="team" id="partner" class="hidden-lg hidden-md hidden-sm ">
      <div class="container">
        <div class="row">
          <div class="col-md-10 col-md-offset-1">
            <div class="col-lg-12">
              <h2>Our Partners & Affiliates</h2>
              <div class="row pt-md">
                <div class="col-lg-3 col-md-3 col-sm-4 col-xs-6 profile">
                  <div class="img-box" style="margin-top: 8px;">
                    <img src="assets/img/p1.jpg" class="img-responsive" />
                  </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-4 col-xs-6 profile">
                  <div class="img-box" style="margin-top: 8px;">
                    <img src="assets/img/p2.jpg" class="img-responsive" />
                  </div>
                </div>

                <div class="col-lg-3 col-md-3 col-sm-4 col-xs-6 profile">
                  <div class="img-box" style="margin-top: 8px;">
                    <img src="assets/img/p3.jpg" class="img-responsive" />
                  </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-4 col-xs-6 profile">
                  <div class="img-box" style="margin-top: 8px;">
                    <img src="assets/img/p4.jpg" class="img-responsive" />
                  </div>
                </div>
                <br />
                <div class="col-lg-3 col-md-3 col-sm-4 col-xs-6 profile">
                  <div class="img-box" style="margin-top: 8px;">
                    <img src="assets/img/p5.jpg" class="img-responsive" />
                  </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-4 col-xs-6 profile">
                  <div class="img-box" style="margin-top: 8px;">
                    <img src="assets/img/p6.jpg" class="img-responsive" />
                  </div>
                </div>
                <!-- <div class="col-lg-3 col-md-3 col-sm-4 col-xs-6 profile">
                  <div class="img-box" style="margin-top: 8px;">
                    <img src="assets/img/p11.png" class="img-responsive">
                  </div>
                </div> -->
                <div class="col-lg-3 col-md-3 col-sm-4 col-xs-6 profile">
                  <div class="img-box" style="margin-top: 8px;">
                    <img src="assets/img/p7.png" class="img-responsive" />
                  </div>
                </div>

                <div class="col-lg-3 col-md-3 col-sm-4 col-xs-6 profile">
                  <div class="img-box" style="margin-top: 8px;">
                    <img src="assets/img/p8.png" class="img-responsive" />
                  </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-4 col-xs-6 profile">
                  <div class="img-box" style="margin-top: 8px;">
                    <img src="assets/img/p9.png" class="img-responsive" />
                  </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-4 col-xs-6 profile">
                  <div class="img-box" style="margin-top: 8px;">
                    <img src="assets/img/p10.png" class="img-responsive" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- <section class="bg-alternate ways" id="partners">
                <div class="container">
                        <div class="row">
                          <div class="col-md-10 col-md-offset-1">
                            <div class="col-lg-12">
                                  <h2>Our Partners & Affiliates</h2>
                              <div class="row pt-md">
                                <div class="col-lg-3 col-md-3 col-sm-4 col-xs-12 profile">
                                  <div class="img-box">
                                    <img src="assets/img/1.jpg" class="img-responsive">
                                  
                                  </div>
                                  
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-4 col-xs-12 profile">
                                      <div class="img-box">
                                        <img src="assets/img/p1.jpg" class="img-responsive">
                                       
                                      </div>
                                      
                                    </div>
                                    <div class="col-lg-3 col-md-3 col-sm-4 col-xs-12 profile">
                                          <div class="img-box">
                                            <img src="assets/img/p1.jpg" class="img-responsive">
                                          
                                          </div>
                                          
                                        </div>
                                        <div class="col-lg-3 col-md-3 col-sm-4 col-xs-12 profile">
                                              <div class="img-box">
                                                <img src="assets/img/p1.jpg" class="img-responsive">
                                              
                                              </div>
                                              
                                            </div>
                                          
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
        </section> -->
    <!-- <section class="team padding-top-50 " id="team">
                <div class="container">
                    <div class="row">
                        <h2 class="col-xs-12 margin-bottom-20">The VSure.life Team</h2>
                        <div class="text margin-bottom-70"> We are ordinary people aiming to serve ASEAN communities with <br> Insurance they truly deserve </div>
                    </div>
                </div>
                <div id="accordion-team" class="visible-xs visible-sm">
                
                	<div class="panel team-member">
                        <div class="container preview clearfix" data-parent="#accordion-team" data-toggle="collapse" data-target="#collapseTeamMember-1" aria-expanded="false" aria-controls="collapseTeamMember-1">
                            <div class="row">
                                <div class="col-xs-12" onClick="sendEvent('Bios', 'Click', 'Ben');">
                                    <div> 
                                        <img src="assets/img/Eddy_Wong.jpg" class="img-circle pull-left">
                                        <p class="name pull-left">Eddy Wong </p>
                                    </div>
                                    <div class="pull-right arrow-toggle"> <i class="fa fa-chevron-down"></i> </div>
                                </div>
                            </div>
                        </div>
						<div class="vsure-teal-bg collapse drop-shadow" id="collapseTeamMember-1">
							<div class="container">
								<div class="row">
									<div class="col-xs-12 details">
										<p class="name">Co-Founder/Chairman</p>
										<p>More than 22 Years of Corporate & Commercial Management & People Leadership experience with 3 MNCs (Prudential Asia / WorleyParsons / Experian). Vast experience in the areas of global software development & delivery centre of excellence(COE)/ shared services/ BPO/ KPO, financial systems, enterprise performance management (EPM), governance & controls, auditing and risk consulting ranging from Information & Data Services, Financial Services (Insurance, Banking, Asset Management), Constructions and Property Development, Manufacturing, Retail and Trading Services, Facilities Management, Oil and Gas, IT Services and Integrations, Corporate Services and Financial Intermediaries. </p>
									</div>
								</div>
							</div>
						</div>
					</div>
                    
                    <div class="panel team-member">
                        <div class="container preview clearfix" data-parent="#accordion-team" data-toggle="collapse" data-target="#collapseTeamMember-0" aria-expanded="false" aria-controls="collapseTeamMember-0">
                            <div class="row">
                                <div class="col-xs-12" onClick="sendEvent('Bios', 'Click', 'Andrew');">
                                    <div> 
                                        <img src="assets/img/Jason_Ho.jpg" class="img-circle pull-left">
                                        <p class="name pull-left">Jason Ho</p>
                                    </div>
                                    <div class="pull-right arrow-toggle"> <i class="fa fa-chevron-down"></i> </div>
                                </div>
                            </div>
                        </div>
                        <div class="vsure-teal-bg collapse drop-shadow" id="collapseTeamMember-0">
                            <div class="container">
                                <div class="row">
                                    <div class="col-xs-12 details">
                                        <p class="name">Co-Founder/CEO</p>
                                        <p> More than 25 years of experience in the Information Technology industry (with different MNCs – Siemens, Logica, Experian)
                                        covering Global Product Software Development & Delivery Centre of Excellence, Global IT Service Delivery, Project Management,
                                        IT Outsourcing, Business Application Implementation and Roll-out covering a range of business domains from Credit Risk Management,
                                        Fleet Cards Management, Planning Permission and Building Control, Customer Information and Billing System, Document / Content
                                        Management and Workflow System in a number of different industries from Banking and Finance, Public Utilities (Energy and
                                        Water), Government agencies – City and Town Councils, Forestry and Environment, Oil and Gas.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="panel team-member">
						<div class="container preview clearfix" data-parent="#accordion-team" data-toggle="collapse" data-target="#collapseTeamMember-3" aria-expanded="false" aria-controls="collapseTeamMember-3">
							<div class="row">
								<div class="col-xs-12" onClick="sendEvent('Bios', 'Click', 'Dana');">
									<div> 
										<img src="assets/img/Jason_Wong.jpg" class="img-circle pull-left">
										<p class="name pull-left">Jason Wong</p>
									</div>
									<div class="pull-right arrow-toggle"> <i class="fa fa-chevron-down"></i> </div>
								</div>
							</div>
						</div>
						<div class="vsure-teal-bg collapse drop-shadow" id="collapseTeamMember-3">
							<div class="container">
								<div class="row">
									<div class="col-xs-12 details">
										<p class="name">Co-Founder/CMO</p>
										<p>Jason is currently the Biz Development Director of VenturionTech Global S/B. He has over 16 years of experience in IT sales, PMO, software development and delivery with Select-TV, CSA Bhd and Oneworks S/B. His early involvement in the insurance industry was with the motor insurance in regards to motor vehicle digital claims process flow. He was also one of the key driver behind the success of an APICTA award winning startup.</p>
									</div>
								</div>
							</div>
						</div>
					</div>
                    
                    <div class="panel team-member">
						<div class="container preview clearfix" data-parent="#accordion-team" data-toggle="collapse" data-target="#collapseTeamMember-2" aria-expanded="false" aria-controls="collapseTeamMember-2">
							<div class="row">
								<div class="col-xs-12" onClick="sendEvent('Bios', 'Click', 'Chris');">
									<div> 
										<img src="assets/img/Sree_Murthi.jpg" class="img-circle pull-left">
										<p class="name pull-left">Sree Murthi </p>
									</div>
									<div class="pull-right arrow-toggle"> <i class="fa fa-chevron-down"></i> </div>
								</div>
							</div>
						</div>
						<div class="vsure-teal-bg collapse drop-shadow" id="collapseTeamMember-2">
							<div class="container">
								<div class="row">
									<div class="col-xs-12 details">
										<p class="name">Co-Founder/CTO</p>
										<p>With more than 16 years of Information Technology and Software development , Sree fits right in with V-sure vision to become Asia's No.1 on-demand insurance provider. His vast knowledge in the Ai, Blockchain and Business intelligent.</p>
									</div>
								</div>
							</div>
						</div>
					</div>
      
					<div class="panel team-member">
						<div class="container preview clearfix" data-parent="#accordion-team" data-toggle="collapse" data-target="#collapseTeamMember-5" aria-expanded="false" aria-controls="collapseTeamMember-5">
							<div class="row">
								<div class="col-xs-12" onClick="sendEvent('Bios', 'Click', 'Greg');">
									<div> 
										<img src="assets/img/Jerry_Yeoh.jpg" class="img-circle pull-left">
										<p class="name pull-left">Jerry Yeoh</p>
									</div>
									<div class="pull-right arrow-toggle"> <i class="fa fa-chevron-down"></i> </div>
								</div>
							</div>
						</div>
						<div class="vsure-teal-bg collapse drop-shadow" id="collapseTeamMember-5">
							<div class="container">
								<div class="row">
									<div class="col-xs-12 details">
										<p class="name">Co-Founder</p>
										<p>Esteemed Finance Professional (Regional Financial Controllership) with vast experience in various industries namely Information Services, IT, Media & Advertising, Oil & Gas, Event Management, Trading as well as Auditing. With strong sense of responsibility, hands on management & excellent communication skills of over 20 years.</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					
					<div class="panel team-member">
						<div class="container preview clearfix" data-parent="#accordion-team" data-toggle="collapse" data-target="#collapseTeamMember-4" aria-expanded="false" aria-controls="collapseTeamMember-4">
							<div class="row">
								<div class="col-xs-12" onClick="sendEvent('Bios', 'Click', 'Graham');">
									<div> 
										<img src="assets/img/Alston_Yong.jpg" class="img-circle pull-left">
										<p class="name pull-left">Alston Yong</p>
									</div>
									<div class="pull-right arrow-toggle"> <i class="fa fa-chevron-down"></i> </div>
								</div>
							</div>
						</div>
						<div class="vsure-teal-bg collapse drop-shadow" id="collapseTeamMember-4">
							<div class="container">
								<div class="row">
									<div class="col-xs-12 details">
										<p class="name">Co-Founder</p>
										<p>Alston is a regional pricing actuary in one of the top global reinsurance company. He is currently based in Singapore. He has over 4 years industry experience, specialist in General Insurance. He has also engaging with various insurtech projects, like operation automation, big data in insurance, and market studies on liberalisation of property and motor insurance in Malaysia.</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="panel team-member">
						<div class="container preview clearfix" data-parent="#accordion-team" data-toggle="collapse" data-target="#collapseTeamMember-7" aria-expanded="false" aria-controls="collapseTeamMember-7">
							<div class="row">
								<div class="col-xs-12" onClick="sendEvent('Bios', 'Click', 'Lachlan');">
									<div> 
										<img src="assets/img/Shi_Hao.jpg" class="img-circle pull-left">
										<p class="name pull-left">Shi Hao</p>
									</div>
									<div class="pull-right arrow-toggle"> <i class="fa fa-chevron-down"></i> </div>
								</div>
							</div>
						</div>
						<div class="vsure-teal-bg collapse drop-shadow" id="collapseTeamMember-7">
							<div class="container">
									<div class="row">
										<div class="col-xs-12 details">
											<p class="name">Co-Founder</p>
										</div>
								</div>
							</div>
						</div>
					</div>
					
					<div class="panel team-member">
						<div class="container preview clearfix" data-parent="#accordion-team" data-toggle="collapse" data-target="#collapseTeamMember-6" aria-expanded="false" aria-controls="collapseTeamMember-6">
							<div class="row">
								<div class="col-xs-12" onClick="sendEvent('Bios', 'Click', 'Jeremy');">
									<div> 
										<img src="assets/img/Kah_Seong.jpg" class="img-circle pull-left">
										<p class="name pull-left">Kah Seong</p>
									</div>
									<div class="pull-right arrow-toggle"> <i class="fa fa-chevron-down"></i> </div>
								</div>
							</div>
						</div>
						<div class="vsure-teal-bg collapse drop-shadow" id="collapseTeamMember-6">
							<div class="container">
								<div class="row">
									<div class="col-xs-12 details">
										<p class="name">Co-Founder</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="panel team-member">
						<div class="container preview clearfix" data-parent="#accordion-team" data-toggle="collapse" data-target="#collapseTeamMember-8" aria-expanded="false" aria-controls="collapseTeamMember-8">
							<div class="row">
								<div class="col-xs-12" onClick="sendEvent('Bios', 'Click', 'Lincoln');">
									<div> 
										<img src="assets/img/Alex_Chi.jpg" class="img-circle pull-left">
										<p class="name pull-left">Alex Chi</p>
									</div>
									<div class="pull-right arrow-toggle"> <i class="fa fa-chevron-down"></i> </div>
								</div>
							</div>
						</div>
						<div class="vsure-teal-bg collapse drop-shadow" id="collapseTeamMember-8">
							<div class="container">
								<div class="row">
									<div class="col-xs-12 details">
										<p class="name">Co-Founder</p>
										<p> Worked as Head of Operations for Hitwise, an Australia internet intelligence startup, acquired by Experian on 2007. Rich experience in consumer insights and digital transformation expert with 16 years of diverse experience in Fortune 500 companies namely Citigroup, Royal Dutch Shell and Hewlett Packard.</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
        
                <div id="team-members-md" class="visible-md visible-lg">
                    <div class="team-row">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-12 padding-bottom-25">
                                    <div class="row">
                                    	<div class="team-member">
                                            <div class="col-md-offset-1 col-md-2">
                                                <div class="img-holder">
                                                    <div class="team-member-overlay trigger" onClick="sendEvent('Bios', 'Click', 'Ben');">Read Bio</div>
                                                    <img src="assets/img/Eddy_Wong.jpg" class="img-circle img-responsive trigger"> 
                                                </div>
                                                    <p class="name data-holder trigger" data-name="Co-Founder/Chairman"  data-linkedin="" data-email="" data-excerpt="<p>More than 22 Years of Corporate & Commercial Management & People Leadership experience with 3 MNCs (Prudential Asia / WorleyParsons / Experian). Vast experience in the areas of global software development & delivery centre of excellence(COE)/ shared services/ BPO/ KPO, financial systems, enterprise performance management (EPM), governance & controls, auditing and risk consulting ranging from Information & Data Services, Financial Services (Insurance, Banking, Asset Management), Constructions and Property Development, Manufacturing, Retail and Trading Services, Facilities Management, Oil and Gas, IT Services and Integrations, Corporate Services and Financial Intermediaries.</p>">Eddy Wong</p>
                                                <div class="triangle"></div>
                                            </div>
                                        </div>
                                        
                                        <div class="team-member">
                                            <div class="col-md-offset-2 col-md-2">
                                                <div class="img-holder">
                                                    <div class="team-member-overlay trigger" onClick="sendEvent('Bios', 'Click', 'Andrew');">Read Bio</div>
                                                    <img src="assets/img/Jason_Ho.jpg" class="img-circle img-responsive trigger"> 
                                                </div>
                                                    <p class="name data-holder trigger" data-name="Co-Founder/CEO" data-linkedin="" data-email="" data-excerpt="<p>More than 25 years of experience in the Information Technology industry (with different MNCs – Siemens, Logica, Experian)
                                                    covering Global Product Software Development & Delivery Centre of Excellence, Global IT Service Delivery, Project Management,
                                                    IT Outsourcing, Business Application Implementation and Roll-out covering a range of business domains from Credit Risk Management,
                                                    Fleet Cards Management, Planning Permission and Building Control, Customer Information and Billing System, Document / Content
                                                    Management and Workflow System in a number of different industries from Banking and Finance, Public Utilities (Energy and
                                                    Water), Government agencies – City and Town Councils, Forestry and Environment, Oil and Gas.</p>">Jason Ho</p>
                                                <div class="triangle"></div>
                                            </div>
                                        </div>
                                        
                                        <div class="team-member">
                                            <div class="col-md-offset-2 col-md-2">
                                                <div class="img-holder">
                                                    <div class="team-member-overlay trigger" onClick="sendEvent('Bios', 'Click', 'Dana');">Read Bio</div>
                                                    <img src="assets/img/Jason_Wong.jpg" class="img-circle img-responsive trigger">
                                                </div>
                                                    <p class="name data-holder trigger" data-name="Co-Founder/CMO" data-linkedin="" data-email="" data-excerpt="<p>Jason is currently the Biz Development Director of VenturionTech Global S/B. He has over 16 years of experience in IT sales, PMO, software development and delivery with Select-TV, CSA Bhd and Oneworks S/B. His early involvement in the insurance industry was with the motor insurance in regards to motor vehicle digital claims process flow. He was also one of the key driver behind the success of an APICTA award winning startup.</p>">Jason Wong</p>
                                                <div class="triangle"></div>
                                            </div>
                                        </div>
                                        
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="vsure-teal-bg collapse drop-shadow team-member-detail-holder">
                            <div class="container">
                                <div class="row">
                                    <div class="col-md-offset-1 col-md-10 details-md">
                                        <p class="name"></p>
                                        <div class="description"></div>
                                    <div> 
                                        <a class="btn btn-teal-inverse anchor-email" href="#" target="_blank" onClick="sendEvent('Bios', 'GetInTouch', 'Chris');">get in touch</a> <a class="btn btn-teal-inverse anchor-linkedin" href="#" target="_blank" onClick="sendEvent('Bios', 'LinkedIn', 'Chris');">LinkedIn</a> 
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="team-row">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-12 padding-bottom-25">
                                    <div class="row">
                                        <div class="team-member">
                                            <div class="col-md-offset-1 col-md-2">
                                                <div class="img-holder">
                                                    <div class="team-member-overlay trigger" onClick="sendEvent('Bios', 'Click', 'Chris');">Read Bio</div>
                                                    <img src="assets/img/Sree_Murthi.jpg" class="img-circle img-responsive trigger"> 
                                                </div>
                                                    <p class="name data-holder trigger" data-name="Co-Founder/CTO" data-linkedin="" data-email="" data-excerpt="<p>With more than 16 years of Information Technology and Software development , Sree fits right in with V-sure vision to become Asia's No.1 on-demand insurance provider. His vast knowledge in the Ai, Blockchain and Business intelligent.</p>">Sree Murthi</p>
                                                <div class="triangle"></div>
                                            </div>
                                        </div>
                                        
                                        <div class="team-member">
                                          <div class="col-md-offset-2 col-md-2">
                                            <div class="img-holder">
                                              <div class="team-member-overlay trigger" onClick="sendEvent('Bios', 'Click', 'Greg');">Read Bio</div>
                                              <img src="assets/img/Jerry_Yeoh.jpg" class="img-circle img-responsive trigger"> </div>
                                            <p class="name data-holder trigger" data-name="Co-Founder" data-linkedin="" data-email="" data-excerpt="<p>Esteemed Finance Professional (Regional Financial Controllership) with vast experience in various industries namely Information Services, IT, Media & Advertising, Oil & Gas, Event Management, Trading as well as Auditing. With strong sense of responsibility, hands on management & excellent communication skills of over 20 years.</p>">Jerry Yeoh</p>
                                            <div class="triangle"></div>
                                          </div>
                                        </div>
                                        
                                        <div class="team-member">
                                            <div class="col-md-offset-2 col-md-2">
                                                <div class="img-holder">
                                                    <div class="team-member-overlay trigger" onClick="sendEvent('Bios', 'Click', 'Graham');">Read Bio</div>
                                                    <img src="assets/img/Alston_Yong.jpg" class="img-circle img-responsive trigger"> 
                                                </div>
                                                    <p class="name data-holder trigger" data-name="Co-Founder" data-linkedin="" data-email="" data-excerpt="<p>Alston is a regional pricing actuary in one of the top global reinsurance company. He is currently based in Singapore. He has over 4 years industry experience, specialist in General Insurance. He has also engaging with various insurtech projects, like operation automation, big data in insurance, and market studies on liberalisation of property and motor insurance in Malaysia.</p>">Alston Yong</p>
                                                <div class="triangle"></div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="vsure-teal-bg collapse drop-shadow team-member-detail-holder">
                            <div class="container">
                                <div class="row">
                                    <div class="col-md-offset-1 col-md-10 details-md">
                                        <p class="name"></p>
                                        <div class="description"></div>
                                        <div> 
                                            <a class="btn btn-teal-inverse anchor-email" href="#" target="_blank" onClick="sendEvent('Bios', 'GetInTouch', 'Greg');">get in touch</a> 
                                            <a class="btn btn-teal-inverse anchor-linkedin" href="#" target="_blank" onClick="sendEvent('Bios', 'LinkedIn', 'Greg');">LinkedIn</a> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="team-row">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-12 padding-bottom-25">
                                    <div class="row">
                                        <div class="team-member">
                                          <div class="col-md-offset-1 col-md-2">
                                            <div class="img-holder">
                                              <div class="team-member-overlay trigger" onClick="sendEvent('Bios', 'Click', 'Lachlan');">Read Bio</div>
                                              <img src="assets/img/Shi_Hao.jpg" class="img-circle img-responsive trigger"> </div>
                                            <p class="name data-holder trigger" data-name="Co-Founder" data-linkedin="" data-email="" data-excerpt="<p></p>">Shi Hao</p>
                                            <div class="triangle"></div>
                                          </div>
                                        </div>
                                        <div class="team-member">
                                            <div class="col-md-offset-2 col-md-2">
                                                <div class="img-holder">
                                                    <div class="team-member-overlay trigger" onClick="sendEvent('Bios', 'Click', 'Jeremy');">Read Bio</div>
                                                    <img src="assets/img/Kah_Seong.jpg" class="img-circle img-responsive trigger"> 
                                                </div>
                                                    <p class="name data-holder trigger" data-name="Co-Founder" data-linkedin="" data-email="" data-excerpt="<p></p>">Kah Seong</p>
                                                <div class="triangle"></div>
                                            </div>
                                        </div>
                                        <div class="team-member">
                                            <div class="col-md-offset-2 col-md-2">
                                                <div class="img-holder">
                                                    <div class="team-member-overlay trigger" onClick="sendEvent('Bios', 'Click', 'Lincoln');">Read Bio</div>
                                                    <img src="assets/img/Alex_Chi.jpg" class="img-circle img-responsive trigger"> 
                                                </div>
                                                    <p class="name data-holder trigger" data-name="Co-Founder" data-linkedin="" data-email="" data-excerpt="<p>Worked as Head of Operations for Hitwise, an Australia internet intelligence startup, acquired by Experian on 2007. Rich experience in consumer insights and digital transformation expert with 16 years of diverse experience in Fortune 500 companies namely Citigroup, Royal Dutch Shell and Hewlett Packard.</p>">Alex Chi</p>
                                                <div class="triangle"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="vsure-teal-bg collapse drop-shadow team-member-detail-holder">
                            <div class="container">
                                <div class="row">
                                    <div class="col-md-offset-1 col-md-10 details-md">
                                        <p class="name"></p>
                                        <div class="description"></div>
                                        <div> 
                                            <a class="btn btn-teal-inverse anchor-email" href="#" target="_blank" onClick="sendEvent('Bios', 'GetInTouch', 'Lincoln');">get in touch</a> 
                                            <a class="btn btn-teal-inverse anchor-linkedin" href="#" target="_blank" onClick="sendEvent('Bios', 'LinkedIn', 'Lincoln');">LinkedIn</a> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> -->
    <!--<section class="team padding-top-50 " id="team" style="background-color:#f4f4f4">
                <div class="container">
                    <div class="row">
                        <h2 class="col-xs-12 margin-bottom-20">Advisory Board</h2>
                        <div class="text margin-bottom-70"> We are ordinary people aiming to serve ASEAN communities with <br>Insurance they truly deserve </div>
                    </div>
                </div>
                <div id="accordion-team" class="visible-xs visible-sm">
                    <div class="panel team-member">
                        <div class="container preview clearfix" data-parent="#accordion-team" data-toggle="collapse" data-target="#collapseTeamMember-0" aria-expanded="false" aria-controls="collapseTeamMember-0">
                            <div class="row">
                                <div class="col-xs-12" onClick="sendEvent('Bios', 'Click', 'Andrew');">
                                    <div>
                                        <img src="img/team.jpg" class="img-circle pull-left">
                                        <p class="name pull-left">KS Tan</p>
                                    </div>
                                    <div class="pull-right arrow-toggle"> <i class="fa fa-chevron-down"></i> </div>
                                </div>
                            </div>
                        </div>
                        <div class="vsure-teal-bg collapse drop-shadow" id="collapseTeamMember-0">
                            <div class="container">
                                <div class="row">
                                    <div class="col-xs-12 details">
                                        <p class="name">Advisory Board</p>
                                        <p> Vast experience in managing global development teams in MRP COBOL/JCL/CICS, Oracle eBiz, OBIEE, Hyperian/Essbase, HPQC, QTP, and Java. Kah Seong is an influential leader with over 20 years of experience in stakeholder management, project management and IT development.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="panel team-member">
                        <div class="container preview clearfix" data-parent="#accordion-team" data-toggle="collapse" data-target="#collapseTeamMember-1" aria-expanded="false" aria-controls="collapseTeamMember-1">
                            <div class="row">
                                <div class="col-xs-12" onClick="sendEvent('Bios', 'Click', 'Ben');">
                                    <div> 
                                        <img src="img/team.jpg" class="img-circle pull-left">
                                        <p class="name pull-left">Alex Chi</p>
                                    </div>
                                    <div class="pull-right arrow-toggle"> <i class="fa fa-chevron-down"></i> </div>
                                </div>
                            </div>
                        </div>
                        <div class="vsure-teal-bg collapse drop-shadow" id="collapseTeamMember-1">
                            <div class="container">
                                <div class="row">
                                    <div class="col-xs-12 details">
                                        <p class="name">Advisory Board</p>
                                        <p>Worked as Head of Operations for Hitwise, an Australia internet intelligence startup, acquired by Experian on 2007. Rich experience in consumer insights and digital transformation expert with 16 years of diverse experience in Fortune 500 companies namely Citigroup, Royal Dutch Shell and Hewlett Packard. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="panel team-member">
                        <div class="container preview clearfix" data-parent="#accordion-team" data-toggle="collapse" data-target="#collapseTeamMember-2" aria-expanded="false" aria-controls="collapseTeamMember-2">
                            <div class="row">
                                <div class="col-xs-12" onClick="sendEvent('Bios', 'Click', 'Chris');">
                                    <div> 
                                        <img src="img/team.jpg" class="img-circle pull-left">
                                        <p class="name pull-left">SH Wong </p>
                                    </div>
                                    <div class="pull-right arrow-toggle"> <i class="fa fa-chevron-down"></i> </div>
                                </div>
                            </div>
                        </div>
                        <div class="vsure-teal-bg collapse drop-shadow" id="collapseTeamMember-2">
                            <div class="container">
                                <div class="row">
                                    <div class="col-xs-12 details">
                                        <p class="name">Advisory Board</p>
                                        <p>Adept problem solver with accomplishments in Enterprise Performance Management, Business Process Improvement, and Business Strategy & Planning. </p>
                                        <p>Started his career just more than 2 years ago as Business Analyst to General Manager at Experian Malaysia. Currently serving in CEO Transformation Office of a premium automotive brand. Scholarship recipient of US Fulbright Program and UCSI University.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="panel team-member">
                        <div class="container preview clearfix" data-parent="#accordion-team" data-toggle="collapse" data-target="#collapseTeamMember-3" aria-expanded="false" aria-controls="collapseTeamMember-3">
                            <div class="row">
                                <div class="col-xs-12" onClick="sendEvent('Bios', 'Click', 'Dana');">
                                    <div> 
                                        <img src="img/team.jpg" class="img-circle pull-left">
                                        <p class="name pull-left">William Kuan</p>
                                    </div>
                                    <div class="pull-right arrow-toggle"> <i class="fa fa-chevron-down"></i> </div>
                                </div>
                            </div>
                        </div>
                        <div class="vsure-teal-bg collapse drop-shadow" id="collapseTeamMember-3">
                            <div class="container">
                                <div class="row">
                                    <div class="col-xs-12 details">
                                        <p class="name">Advisory Board</p>
                                        <p>Chairman for PT Avrist Assurance, Indonesia and Senior Advisor for an international consulting firm. 17 years experience with Prudential having held senior leadership roles across the Asian markets , including Regional CEO for New Markets ( Asia) , President Director and CEO for Indonesia , Chairman for Taiwan , and Chairman for Laos. Qualified CPA from Australia and a Chartered Accountant, MBA from RMIT , Melbourne and completed executive programs from both the Harvard Business School and Oxford University</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="panel team-member">
                        <div class="container preview clearfix" data-parent="#accordion-team" data-toggle="collapse" data-target="#collapseTeamMember-4" aria-expanded="false" aria-controls="collapseTeamMember-4">
                            <div class="row">
                                <div class="col-xs-12" onClick="sendEvent('Bios', 'Click', 'Graham');">
                                    <div> 
                                        <img src="img/team.jpg" class="img-circle pull-left">
                                        <p class="name pull-left">Carlos Medina</p>
                                    </div>
                                    <div class="pull-right arrow-toggle"> <i class="fa fa-chevron-down"></i> </div>
                                </div>
                            </div>
                        </div>
                        <div class="vsure-teal-bg collapse drop-shadow" id="collapseTeamMember-4">
                            <div class="container">
                                <div class="row">
                                    <div class="col-xs-12 details">
                                        <p class="name">Advisory Board</p>
                                        <p>Senior Corporate Executive who drives global and enterprise-wide large scale transformations for improving processes, aligning operational functions, and building organizational clarity creating exceptional stakeholder value. P&L Leadership/Transformation, Global Operations, Operational Excellence/Organizational Leadership, New Business venture. Previously Senior Global SVP of GE, Experian and JCPenny</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="panel team-member">
                        <div class="container preview clearfix" data-parent="#accordion-team" data-toggle="collapse" data-target="#collapseTeamMember-5" aria-expanded="false" aria-controls="collapseTeamMember-5">
                            <div class="row">
                                <div class="col-xs-12" onClick="sendEvent('Bios', 'Click', 'Greg');">
                                    <div> 
                                        <img src="img/team.jpg" class="img-circle pull-left">
                                        <p class="name pull-left">Andrew Tan</p>
                                    </div>
                                    <div class="pull-right arrow-toggle"> <i class="fa fa-chevron-down"></i> </div>
                                </div>
                            </div>
                        </div>
                        <div class="vsure-teal-bg collapse drop-shadow" id="collapseTeamMember-5">
                            <div class="container">
                                <div class="row">
                                    <div class="col-xs-12 details">
                                        <p class="name">Advisory Board</p>
                                        <p>Experienced professional risk consultant with more than 13 years experience, of which the past 8 years have been in financial services and banking. Deep understanding of fintech and how it can be applied in Laos PDR. Management experience includes being the Managing Director of a management consulting firm and country executive director for a global top 5 audit and consulting firm. Resident in Vientiane, Laos PDR since 2013</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="team-members-md" class="visible-md visible-lg">
                    <div class="team-row">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-12 padding-bottom-25">
                                    <div class="row">
                                        <div class="team-member">
                                            <div class="col-md-offset-1 col-md-2">
                                                <div class="img-holder">
                                                    <div class="team-member-overlay trigger" onClick="sendEvent('Bios', 'Click', 'Andrew');">Read Bio</div>
                                                    <img src="img/team.jpg" class="img-circle img-responsive trigger"> 
                                                </div>
                                                    <p class="name data-holder trigger" data-name="Advisory Board" data-linkedin="" data-email="" data-excerpt="<p>Vast experience in managing global development teams in MRP COBOL/JCL/CICS, Oracle eBiz, OBIEE, Hyperian/Essbase, HPQC, QTP, and Java. Kah Seong is an influential leader with over 20 years of experience in stakeholder management, project management and IT development.</p>">KS Tan</p>
                                                <div class="triangle"></div>
                                            </div>
                                        </div>
                                        <div class="team-member">
                                            <div class="col-md-offset-2 col-md-2">
                                                <div class="img-holder">
                                                    <div class="team-member-overlay trigger" onClick="sendEvent('Bios', 'Click', 'Ben');">Read Bio</div>
                                                    <img src="img/team.jpg" class="img-circle img-responsive trigger"> 
                                                </div>
                                                    <p class="name data-holder trigger" data-name="Advisory Board"  data-linkedin="" data-email="" data-excerpt="<p>Worked as Head of Operations for Hitwise, an Australia internet intelligence startup, acquired by Experian on 2007. Rich experience in consumer insights and digital transformation expert with 16 years of diverse experience in Fortune 500 companies namely Citigroup, Royal Dutch Shell and Hewlett Packard.</p>">Alex Chi</p>
                                                <div class="triangle"></div>
                                            </div>
                                        </div>
                                        <div class="team-member">
                                            <div class="col-md-offset-2 col-md-2">
                                                <div class="img-holder">
                                                    <div class="team-member-overlay trigger" onClick="sendEvent('Bios', 'Click', 'Chris');">Read Bio</div>
                                                    <img src="img/team.jpg" class="img-circle img-responsive trigger"> 
                                                </div>
                                                    <p class="name data-holder trigger" data-name="Advisory Board" data-linkedin="" data-email="" data-excerpt="<p>Adept problem solver with accomplishments in Enterprise Performance Management, Business Process Improvement, and Business Strategy & Planning.</p><p>Started his career just more than 2 years ago as Business Analyst to General Manager at Experian Malaysia. Currently serving in CEO Transformation Office of a premium automotive brand. Scholarship recipient of US Fulbright Program and UCSI University.</p>">SH Wong</p>
                                                <div class="triangle"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="vsure-teal-bg collapse drop-shadow team-member-detail-holder">
                            <div class="container">
                                <div class="row">
                                    <div class="col-md-offset-1 col-md-10 details-md">
                                        <p class="name"></p>
                                        <div class="description"></div>
                                        <div> 
                                            <a class="btn btn-teal-inverse anchor-email" href="#" target="_blank" onClick="sendEvent('Bios', 'GetInTouch', 'Chris');">get in touch</a> 
                                            <a class="btn btn-teal-inverse anchor-linkedin" href="#" target="_blank" onClick="sendEvent('Bios', 'LinkedIn', 'Chris');">LinkedIn</a> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="team-row">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-12 padding-bottom-25">
                                    <div class="row">
                                        <div class="team-member">
                                            <div class="col-md-offset-1 col-md-2">
                                                <div class="img-holder">
                                                    <div class="team-member-overlay trigger" onClick="sendEvent('Bios', 'Click', 'Dana');">Read Bio</div>
                                                    <img src="img/team.jpg" class="img-circle img-responsive trigger"> 
                                                </div>
                                                    <p class="name data-holder trigger" data-name="Advisory Board" data-linkedin="" data-email="" data-excerpt="<p>Chairman for PT Avrist Assurance, Indonesia and Senior Advisor for an international consulting firm. 17 years experience with Prudential having held senior leadership roles across the Asian markets , including Regional CEO for New Markets ( Asia) , President Director and CEO for Indonesia , Chairman for Taiwan , and Chairman for Laos. Qualified CPA from Australia and a Chartered Accountant, MBA from RMIT , Melbourne and completed executive programs from both the Harvard Business School and Oxford University</p>">William Kuan</p>
                                                <div class="triangle"></div>
                                            </div>
                                        </div>
                                        <div class="team-member">
                                            <div class="col-md-offset-2 col-md-2">
                                                <div class="img-holder">
                                                    <div class="team-member-overlay trigger" onClick="sendEvent('Bios', 'Click', 'Graham');">Read Bio</div>
                                                    <img src="img/team.jpg" class="img-circle img-responsive trigger"> 
                                                </div>
                                                    <p class="name data-holder trigger" data-name="Advisory Board" data-linkedin="" data-email="" data-excerpt="<p>Senior Corporate Executive who drives global and enterprise-wide large scale transformations for improving processes, aligning operational functions, and building organizational clarity creating exceptional stakeholder value. P&L Leadership/Transformation, Global Operations, Operational Excellence/Organizational Leadership, New Business venture. Previously Senior Global SVP of GE, Experian and JCPenny</p>">Carlos Medina</p>
                                                <div class="triangle"></div>
                                            </div>
                                        </div>
                                        <div class="team-member">
                                            <div class="col-md-offset-2 col-md-2">
                                                <div class="img-holder">
                                                    <div class="team-member-overlay trigger" onClick="sendEvent('Bios', 'Click', 'Greg');">Read Bio</div>
                                                    <img src="img/team.jpg" class="img-circle img-responsive trigger"> 
                                                </div>
                                                    <p class="name data-holder trigger" data-name="Advisory Board" data-linkedin="" data-email="" data-excerpt="<p>Experienced professional risk consultant with more than 13 years experience, of which the past 8 years have been in financial services and banking. Deep understanding of fintech and how it can be applied in Laos PDR. Management experience includes being the Managing Director of a management consulting firm and country executive director for a global top 5 audit and consulting firm. Resident in Vientiane, Laos PDR since 2013</p>">Andrew Tan</p>
                                                <div class="triangle"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="vsure-teal-bg collapse drop-shadow team-member-detail-holder">
                            <div class="container">
                                <div class="row">
                                    <div class="col-md-offset-1 col-md-10 details-md">
                                        <p class="name"></p>
                                        <div class="description"></div>
                                        <div> 
                                            <a class="btn btn-teal-inverse anchor-email" href="#" target="_blank" onClick="sendEvent('Bios', 'GetInTouch', 'Greg');">get in touch</a> 
                                            <a class="btn btn-teal-inverse anchor-linkedin" href="#" target="_blank" onClick="sendEvent('Bios', 'LinkedIn', 'Greg');">LinkedIn</a> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>-->
    <section class="bg-alternate next padding-bottom-0" id="join">
      <div class="container">
        <div class="row">
          <div class="col-md-offset-2 col-md-8">
            <h2>Get Notified</h2>
            <div class="text">
              <p>
                Sign up and join us to usher in South East Asia's personalized
                on-demand lifestyle protecter!
              </p>
            </div>
          </div>
        </div>
      </div>
      <div id="accordion-next">
        <div class="panel">
          <div class="container">
            <div class="row">
              <div class="col-xs-12">
                <button class="btn btn-lg btn-teal-inverse checkable smooth-scroll cta" data-parent="#accordion-next"
                  data-toggle="collapse" data-target="#collapseForm-guineapig" aria-expanded="false"
                  aria-controls="collapseForm-guineapig"
                  onClick="sendEvent('WhatIsNext', 'Click', 'Get early access');">
                  I want early access! <i class="fa fa-check"></i>
                </button>
              </div>
            </div>
            <div class="row padding-bottom-15">
              <div class="col-xs-offset-2 col-xs-8 padding-bottom-40">
                <p></p>
              </div>
            </div>
          </div>
          <div class="vsure-teal-bg collapse drop-shadow form-holder" id="collapseForm-guineapig">
            <div class="container">
              <div class="row" *ngIf="!isEmailSend">
                <div class="col-sm-offset-1 col-sm-10 text-left">
                  <!-- <form name="msendmail" id="msendmail" method="post" enctype="multipart/form-data"
                                        action="assets/sendmail.php">
                                        <input type="hidden" name="guess" id="guess" value="siva" />
                                        
                                        <div class="form-group">
                                            <label for="email">Email</label>
                                            <label for="email-message" class="pull-right control-label">Success</label>
                                            <input type="email" name="send_email_id" id="send_email_id" class="form-control focusfield"
                                                placeholder="Enter your email address" autocomplete="true" required="true" />
                                        </div>
                                        <div class="form-group">
                                            <label for="full_name">Name &amp; Surname</label>
                                            <label for="full_name-message" class="pull-right control-label">Success</label>
                                            <input type="text" name="user_name" id="user_name" class="form-control focusfield"
                                                placeholder="Enter your name & Surname" autocomplete="true" required="true" />
                                        </div>
                                        <input type="submit" name="msendmail_submit" id="msendmail_submit" class="btn btn-teal-inverse"
                                            value="Submit" />
                                    </form> -->

                  <!-- 
                    Working
                    <form>
                    <input type="hidden" name="guess" id="guess" />

                    <div class="form-group">
                      <label for="email">Email</label>
                      <label for="email-message" class="pull-right control-label">Success</label>
                      <input type="email" name="send_email_id" id="send_email_id" class="form-control focusfield"
                        placeholder="Enter your email address" autocomplete="true" [(ngModel)]="email" />
                    </div>
                    <div class="form-group">
                      <label for="full_name">Name &amp; Surname</label>
                      <label for="full_name-message" class="pull-right control-label">Success</label>
                      <input type="text" name="user_name" id="user_name" class="form-control focusfield" placeholder="Enter your name & Surname"
                        autocomplete="true" [(ngModel)]="name" />
                    </div>
                    <input type="submit" name="msendmail_submit" id="msendmail_submit" class="btn btn-teal-inverse"
                      (click)="onSubmit()" />
                  </form> -->

                  <form>
                    <input type="hidden" name="guess" id="guess" />

                    <div class="form-group">
                      <label for="email">Email</label>
                      <label for="email-message" class="pull-right control-label">Success</label>
                      <input type="email" name="send_email_id" id="send_email_id" class="form-control focusfield"
                        placeholder="Enter your email address" required autocomplete="true" [(ngModel)]="email" />

                      <label class="label_required" *ngIf="isEmailEnter">Please Enter your Email</label>
                    </div>
                    <div class="form-group">
                      <!-- <label for="full_name">Name &amp; Surname</label> -->
                      <label for="full_name-message" class="pull-right control-label">Success</label>
                      <input required type="text" name="name" id="user_name" class="form-control focusfield"
                        placeholder="Enter your name" autocomplete="true" [(ngModel)]="name" />
                      <label class="label_required" *ngIf="isNameEnter">Please Enter your name</label>
                    </div>

                    <input type="submit" name="msendmail_submit" id="msendmail_submit" class="btn btn-teal-inverse"
                      (click)="onSubmit()" />
                  </form>
                </div>
              </div>
              <div class="row" *ngIf="isEmailSend">
                <h4>
                  Thank you for your interest, We will be in touch with you.
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <app-footer chatBot="true"> </app-footer>
</body>

</html>