<!-- Latest compiled and minified Bootstrap CSS -->
<link
  rel="stylesheet"
  href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.4/css/bootstrap.min.css"
/>
<div class="container-fluid" style="background-color: #cb3a79; ">
  <h4 style="color:#fff;font-family: Raleway, sans-serif;">
    <p>FAQ</p>
  </h4>
</div>
<div class="container" style="margin-top:5px;">
  <div class="panel-group" id="accordion">
    <div class="panel panel-default">
      <div class="panel-heading">
        <h4 class="panel-title">
          <a
            class="accordion-toggle"
            data-toggle="collapse"
            data-parent="#accordion"
            href="#collapseOne">
            I forgot my login password. What should I do?
          </a>
        </h4>
      </div>
      <div id="collapseOne" class="panel-collapse collapse in">
        <div class="panel-body">
          <p>
            At the login page, click on “Forgot Password” and key in the email
            ID that you have registered the account with. We will send you an
            email with the instruction to reset your password. Then login with
            the new password after that.
          </p>
        </div>
      </div>
    </div>
    <div class="panel panel-default">
      <div class="panel-heading">
        <h4 class="panel-title">
          <a
            class="accordion-toggle"
            data-toggle="collapse"
            data-parent="#accordion"
            href="#collapseTwo"
          >
            After I had downloaded and installed the app,<br />
            what do I do?
          </a>
        </h4>
      </div>
      <div id="collapseTwo" class="panel-collapse collapse">
        <div class="panel-body">
          <p>
            You need to sign-up by registering your valid email ID and password.
            Or, you can register with your existing Facebook or Google+ account.
            Once that is done, check your email for a welcome message from
            VSure. This is to ensure that you have properly registered yourself
            with an account with us.
          </p>
          <p>
            Next key in your  <b>Display Name</b> (this will be the name we use to
            address you in our emails) and <b>Date of Birth.</b> If your friendor
            relative had given you their referral code, key it in here. Please
            make sure you have read the Terms of Use and Privacy Policy before
            submitting.
          </p>
          <p>
            Up to this point we do not need any of your personal data until you
            are ready to make a purchase of any of the insurance products.
            During the purchase our app will prompt you for the information.
          </p>
        </div>
      </div>
    </div>
    <div class="panel panel-default">
      <div class="panel-heading">
        <h4 class="panel-title">
          <a
            class="accordion-toggle"
            data-toggle="collapse"
            data-parent="#accordion"
            href="#collapseThree">
            How can I update my profile?
          </a>
        </h4>
      </div>
      <div id="collapseThree" class="panel-collapse collapse">
        <div class="panel-body">
         <p>Certain fields in the profile page cannot be changed after you have made the submission during the registration stage or after you have made a purchase as they are related to your identity, example your Name (base on NRIC), IC number, date of birth and gender. If you have made a mistake while keying it in, please call our hotline number and we will help you makethe change.</p>
         <p>Other fields such as address, contact number, including the beneficiary information can be changed by yourself.</p>
        </div>
      </div>
    </div>
    <div class="panel panel-default">
      <div class="panel-heading">
        <h4 class="panel-title">
          <a
            class="accordion-toggle"
            data-toggle="collapse"
            data-parent="#accordion"
            href="#collapseFour"
          >
            What should I do if I discovered/realised that I had submitted the
            wrong personal info after I had bought the insurance product?
          </a>
        </h4>
      </div>
      <div id="collapseFour" class="panel-collapse collapse">
        <div class="panel-body">
          <p>
            Please call our hotline number immediately to notify us of the
            mistake so that we can make the necessary correction.
          </p>
        </div>
      </div>
    </div>
    <div class="panel panel-default">
      <div class="panel-heading">
        <h4 class="panel-title">
          <a
            class="accordion-toggle"
            data-toggle="collapse"
            data-parent="#accordion"
            href="#collapseFive"
          >
            How can I benefit from the referral code?
          </a>
        </h4>
      </div>
      <div id="collapseFive" class="panel-collapse collapse">
        <div class="panel-body">
          <p>
            We like to be able to reward you when you refer your friends and
            relatives to download and make purchases of the insurance products
            available in our mobile app. So once you have made your first
            insurance product purchase, you will be given a referral code in
            your app. Go to the menu and look for REFERRAL. Your code is in
            there.
          </p>
          <p>
            Ask your friends and relatives to key in your referral code when
            they are registering themselves in the app.
          </p>
        </div>
      </div>
    </div>
    <div class="panel panel-default">
      <div class="panel-heading">
        <h4 class="panel-title">
          <a
            class="accordion-toggle"
            data-toggle="collapse"
            data-parent="#accordion"
            href="#collapseSix">
            My app is not working. Who should I contact?
          </a>
        </h4>
      </div>
      <div id="collapseSix" class="panel-collapse collapse">
        <div class="panel-body">
          <p>
            Please contact VSure hotline if the app is not working properly. The
            hotline number is +603 2727-7227 or write to us at
            <a href="mailto: app.support@vsure.life."
              >app.support@vsure.life.</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<script src="https://ajax.googleapis.com/ajax/libs/jquery/2.1.3/jquery.min.js"></script>
<script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.4/js/bootstrap.min.js"></script>
