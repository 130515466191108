import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { InsuranceComponent } from "./insurance/insurance.component";
import { RouterModule, Routes } from "@angular/router";
import { HomeComponent } from "./home/home.component";
import { MedicalComponent } from "./medical/medical.component";
import { LifeComponent } from "./life/life.component";
import { TeamComponent } from "./team/team.component";
import { CancerComponent } from "./cancer/cancer.component";
import { NewsComponent } from "./news/news.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { RecaptchaModule } from "angular-google-recaptcha";
import { MotorComponent } from "./motor/motor.component";
import { RoadsideComponent } from "./roadside/roadside.component";
import { HomeinsuranceComponent } from "./homeinsurance/homeinsurance.component";
import { ResetpasswordComponent } from "./resetpassword/resetpassword.component";
import { AngularFireModule } from "@angular/fire";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireStorageModule } from "@angular/fire/storage";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { environment } from "../environments/environment";
import { FaqComponent } from "./faq/faq.component";
import { EMedicComponent } from "./e-medic/e-medic.component";
import { CancercareComponent } from "./cancercare/cancercare.component";
import { ELifeComponent } from "./e-life/e-life.component";
import { FooterComponent } from "./footer/footer.component";
import { TravelComponent } from "./travel/travel.component";
import { RegisterComponent } from "./register/register.component";
import { DownloadComponent } from "./download/download.component";
import { ToastrModule, ToastrService } from "ngx-toastr";
import { GraphQLModule } from "./graphql.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
  OWL_DATE_TIME_FORMATS
} from "ng-pick-datetime";
import { UserService } from "./common/services/user.service";
import { OwlMomentDateTimeModule } from "ng-pick-datetime-moment";

export const MY_MOMENT_FORMATS = {
  parseInput: "DD/MM/YYYY",
  fullPickerInput: "l LT",
  datePickerInput: "DD/MM/YYYY",
  timePickerInput: "LT",
  monthYearLabel: "MMM YYYY",
  dateA11yLabel: "LL",
  monthYearA11yLabel: "MMMM YYYY"
};

const appRoutes: Routes = [
  { path: "insurance", component: InsuranceComponent },
  { path: "", component: HomeComponent },
  { path: "medical", component: MedicalComponent },
  { path: "life", component: LifeComponent },
  { path: "team", component: TeamComponent },
  { path: "cancer", component: CancerComponent },
  { path: "news", component: NewsComponent },
  { path: "motor", component: MotorComponent },
  { path: "roadside", component: RoadsideComponent },
  { path: "homeinsurance", component: HomeinsuranceComponent },
  { path: "resetpassword", component: ResetpasswordComponent },
  { path: "faq", component: FaqComponent },
  { path: "e-medic", component: EMedicComponent },
  { path: "cancercare", component: CancercareComponent },
  { path: "e-life", component: ELifeComponent },
  { path: "travel", component: TravelComponent },
  { path: "register", component: RegisterComponent },
  { path: "download", component: DownloadComponent }
];

@NgModule({
  declarations: [
    AppComponent,
    InsuranceComponent,
    HomeComponent,
    MedicalComponent,
    LifeComponent,
    TeamComponent,
    CancerComponent,
    NewsComponent,
    MotorComponent,
    RoadsideComponent,
    HomeinsuranceComponent,
    ResetpasswordComponent,
    FaqComponent,
    EMedicComponent,
    CancercareComponent,
    ELifeComponent,
    FooterComponent,
    TravelComponent,
    RegisterComponent,
    DownloadComponent
  ],

  imports: [
    RouterModule.forRoot(
      appRoutes
      // { enableTracing: true } // <-- debugging purposes only
    ),
    RecaptchaModule.forRoot({
      siteKey: "6LfWLXsUAAAAAOkqHp0uoldAQVbtfap_x9eY8LUD"
    }),
    FormsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features,
    AngularFireStorageModule, // imports firebase/storage only needed for storage features
    ToastrModule.forRoot(),
    GraphQLModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    OwlMomentDateTimeModule
  ],
  providers: [
    ToastrService,
    UserService,
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
