<header class="vsure-header">
  <div class="container">
    <div class="row">
      <div class="col-xs-2 col-sm-4 hidden-md hidden-lg">
        <div class="burger-menu">
          <a
            href="javascript:void(0)"
            data-toggle="collapse"
            data-target="#vsure-navbar"
            onClick="sendEvent('Menu', 'Click', 'Burger menu');"
          >
            <i class="fa fa-bars" aria-hidden="true"></i>
          </a>
        </div>
      </div>
      <div class="col-md-5 hidden-xs hidden-sm">
        <ul class="top-left-nav top-nav">
          <li>
            <a
              class="smooth-scroll"
              href="#about"
              onClick="sendEvent('Menu', 'Click', 'About Vsure');"
            >
              About
            </a>
          </li>
          <li>
            <a
              class="smooth-scroll"
              href="#works"
              onClick="sendEvent('Menu', 'Click', 'works');"
            >
              How it Works
            </a>
          </li>
          <li>
            <a
              class="smooth-scroll"
              href="#offer"
              onClick="sendEvent('Burger-Menu', 'Click', 'Blog');"
              target="_blank"
            >
              What We Offer
            </a>
          </li>
          <li>
            <a
              class="smooth-scroll"
              href="#partners"
              onClick="sendEvent('Menu', 'Click', 'Team');"
            >
              Partners
            </a>
          </li>
          <!-- <li>
                      <a
                          class="smooth-scroll"
                          href="#buynow"
                          onClick="sendEvent('Menu', 'Click', 'Team');"
                      >
                          Buy Now
                      </a>
                  </li> -->
          <li>
            <a
              class="smooth-scroll"
              href="/team"
              onClick="sendEvent('Menu', 'Click', 'Team');"
            >
              Team
            </a>
          </li>
          <li>
            <a
              class="smooth-scroll"
              href="#contact"
              onClick="sendEvent('Menu', 'Click', 'Team');"
              >Contact Us</a
            >
          </li>
        </ul>
      </div>
      <div
        class="col-xs-8 col-sm-4 col-md-2 text-center logo"
        style="padding-top:20px;"
      >
        <a
          href="index.html"
          onClick="ga('send', 'event', 'landing page | home', 'mainmenu', 'logo');"
        >
          <img
            src="assets/img/vsure_logo.svg"
            width="auto"
            height="64"
            class="black_logo"
          />
          <img
            src="assets/img/vsure_white.svg"
            width="auto"
            height="64"
            class="white_logo"
          />
        </a>
      </div>
      <div class="col-sm-4 col-md-5 hidden-xs">
        <ul class="top-nav">
          <li class="col-xs-1 pull-right">
            <a href="https://www.facebook.com/vsure.life/" target="_blank">
              <i class="fa fa-facebook"></i>
              <span class="sr-only">Facebook</span>
            </a>
          </li>
          <!-- <li class="col-xs-1 pull-right">
          <a href="#" target="_blank"><i class="fa fa-twitter"></i><span class="sr-only">Twitter</span></a>
        </li> -->
          <li class="col-xs-1 pull-right">
            <a
              href="https://www.linkedin.com/company/vsuredotcom"
              target="_blank"
            >
              <i class="fa fa-linkedin"></i>
              <span class="sr-only">LinkedIn</span>
            </a>
          </li>
          <!-- <li class="col-xs-1 pull-right">
          <a href="https://www.instagram.com/vsure.life/" target="_blank"><i class="fa fa-instagram"></i><span class="sr-only">Instagram</span></a>
        </li> -->
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 hidden-md hidden-lg">
        <div class="collapsing vsure-navbar" id="vsure-navbar">
          <div class="container">
            <ul class="col-xs-12">
              <li>
                <a
                  class="smooth-scroll"
                  href="#about"
                  onClick="sendEvent('Burger-Menu', 'Click', 'About Vsure');"
                >
                  About
                </a>
              </li>
              <li>
                <a
                  class="smooth-scroll"
                  href="#works"
                  onClick="sendEvent('Menu', 'Click', 'works');"
                >
                  How it Works
                </a>
              </li>

              <li>
                <a
                  class="smooth-scroll"
                  href="#offer"
                  onClick="sendEvent('Burger-Menu', 'Click', 'Blog');"
                  target="_blank"
                >
                  What We Offer
                </a>
              </li>

              <li>
                <a
                  class="smooth-scroll"
                  href="#partners"
                  onClick="sendEvent('Menu', 'Click', 'Team');"
                >
                  Partners
                </a>
              </li>
              <li>
                <a
                  class="smooth-scroll"
                  href="#buynow"
                  onClick="sendEvent('Menu', 'Click', 'Team');"
                >
                  Buy Now
                </a>
              </li>
              <li>
                <a
                  class="smooth-scroll"
                  href="/team"
                  onClick="sendEvent('Menu', 'Click', 'Team');"
                >
                  Team
                </a>
              </li>
              <li>
                <a
                  class="smooth-scroll"
                  href="/news"
                  onClick="sendEvent('Menu', 'Click', 'Team');"
                >
                  News & Events
                </a>
              </li>
            </ul>
            <ul class="col-xs-12 hidden-sm sub-nav">
              <li>
                <a
                  class="smooth-scroll"
                  target="”_blank”"
                  href="/assets/pdf/privacy-policy.pdf"
                  onClick="sendEvent('Footer', 'Click', 'Privacy Policy');"
                >
                  Privacy Policy
                </a>
              </li>
            </ul>
            <div class="social col-xs-12 hidden-sm">
              <h6>Follow us on Social Media</h6>
              <ul class>
                <li class="col-xs-1 pull-right">
                  <a
                    href="https://www.facebook.com/vsure.life/"
                    target="_blank"
                  >
                    <i class="fa fa-facebook"></i>
                    <span class="sr-only">Facebook</span>
                  </a>
                </li>
                <!-- <li class="col-xs-1 pull-right">
                <a href="#" target="_blank"><i class="fa fa-twitter"></i><span class="sr-only">Twitter</span></a>
              </li> -->
                <li class="col-xs-1 pull-right">
                  <a
                    href="https://www.linkedin.com/company/vsuredotcom"
                    target="_blank"
                  >
                    <i class="fa fa-linkedin"></i>
                    <span class="sr-only">LinkedIn</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
<style>
  .full-width-image-2 {
    background: url(assets/img/cancer.jpg) no-repeat center center;
    background-size: cover;
    text-align: center;
    height: 300px;
  }
</style>

<body>
  <div class="full-width-image-2"></div>
  <section class="bg-alternate ways" id="partners">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <h2 style="color:#ef5193">200 Cancer Care</h2>
        </div>
      </div>
      <div class="row way-holder ">
        <div class="col-md-4 col-xs-12 way">
          <div style="border: 1px solid #8c8b8b; padding-top: 10px;">
            <img
              src="assets/img/190.jpg"
              class="rounded float-left"
              width="190"
              height="190"
              alt="Clear"
              onClick="sendEvent('ABetterWay', 'Click', 'Clear');"
            />
            <h3
              class="title margin-top-15"
              onClick="sendEvent('ABetterWay', 'Click', 'Clear');"
            >
              AXA 200 CancerCare
            </h3>
            <div class="content-holder">
              <div class="details content margin-top-20">
                <h3
                  style="text-align: left;    line-height: 23px; font-size: 14px;"
                >
                  1 out of 4 Malaysians will develop CANCER in their lifetime.
                  Cancer has the highest claims for participating life insurance
                  companies in Malaysia. The cost of cancer treatment is
                  EXPENSIVE , 50% of cancer patients will be financially broke a
                  year after Diagnosis. Annual Increase of Medical Cost ,
                  medical Inflation in Malaysia Averages About 12% Per Annum
                </h3>
              </div>
              <div style="margin-top: 35px; "></div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-xs-12 way">
          <div style="border: 1px solid #8c8b8b;">
            <h3
              class="title margin-top-15"
              onClick="sendEvent('ABetterWay', 'Click', 'Fair');"
            >
              Key Benefits
            </h3>
            <div class="content-holder">
              <div class="details content margin-top-20">
                <ul style="text-align: left">
                  <p>
                    Financial support up to 200% sum insured
                  </p>
                  <p>Premium fixed for 20 years</p>
                  <p>
                    Claim amount transfer directly to bank account
                  </p>
                  <p>Concierge service 24/7</p>
                  <p>Second medical opinion upon request</p>
                  <p>
                    Support and information via Learn My Protection online
                    portal
                  </p>
                  <br />
                  <br />
                  <br />
                  <br />
                </ul>
              </div>
              <div style="margin-top: 85px;"></div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-xs-12 way">
          <div style="border: 1px solid #8c8b8b; padding: 10px;">
            <h3
              class="title margin-top-15"
              onClick="sendEvent('ABetterWay', 'Click', 'Fair');"
            >
              Buy Now
            </h3>
            <br />
            <form
              [formGroup]="formGroup"
              (ngSubmit)="onSubmit(formGroup.value)"
              method="POST"
            >
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  formControlName="name"
                  placeholder="*Full Name"
                  required
                />
              </div>
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  id="email"
                  formControlName="email"
                  placeholder=" *Email"
                  required
                />
              </div>
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  id="referral"
                  formControlName="referral"
                  placeholder="Referral Code - Optional"
                  [readonly]="readonly"
                />
              </div>
              <recaptcha formControlName="myRecaptcha"></recaptcha>

              <div class="form-check" style="text-align: left">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="exampleCheck1"
                  [checked]="checkBoxStatus"
                  (change)="checkBoxStatus = !checkBoxStatus"
                  required
                />
                <label class="form-check-label" for="exampleCheck1">
                  &nbsp; I agree to the
                  <a href="/assets/pdf/terms-of-use.pdf">Terms</a>
                  &
                  <a href="/assets/pdf/privacy-policy.pdf">Privacy policies.</a>
                </label>
              </div>

              <input
                type="submit"
                [disabled]="formGroup.invalid || !checkBoxStatus"
                class="btn"
                onclick="window.open('https://www.axa.com.my/buy/cancer-insurance-malaysia/purchase?id=b3K9wfUWQd')"
                value="Submit"
              />
              <br />
              <small style="margin-top: 15px;"
                >This product is underwritten by</small
              >
              <br />
              <img style="margin-top: 10px;" src="assets/img/axa1.jpg" />
            </form>
            <div style="margin-top: 25px; float: left"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="bg-alternate ways">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <!-- <h2 style="color:#ef5193; text-align: left">200 Cancer Care</h2> -->
        </div>
      </div>

      <div class="contant" style="text-align: left">
        <h2>Product</h2>
        <span
          >Cancer is a continuous journey, a burden that will take money and
          time to cure. It is also an emotional worry, not only for those
          diagnosed but also for the people around them. The good news is you
          need not face it alone. By being prepared mentally, financially and
          emotionally, you can live a quality life even when the unfortunate
          happens. How can we manage the risk and be well prepared against
          Cancer? With 200 Cancer Care, we care for you even before anything
          happens. You will have access to information to lead a healthy
          lifestyle and gain tips on Cancer prevention. If the unthinkable
          happens, our all-rounded protection will ensure that you have the
          means to seek early treatment for better chance of recovery. Our
          support does not just end there, we provide assistance in dealing with
          your recovery all the way towards your new normal life, worry-free.
          For more product info, click
          <a
            href="https://axa-prod.s3.amazonaws.com/axa-com-my/adc7acc7-83c9-4b65-bab9-bf7fa30c1da7_200cancercare-pds.pdf"
            target="_blank"
            style="color:#ef5193"
            >here.</a
          >
        </span>
        <br />
        <br />
        <h2>Benefit / Coverage</h2>

        <ol type="I">
          <li>Therapy Care Benefit: One-off payout</li>
          <li>
            Get Well Benefit: Additional payout annually for 5 installments
          </li>
          <li>Arrange a Second Medical Opinion upon your request</li>
          <li>Concierge service arrangement</li>
          <li>Bereavement Benefit</li>
          <li>Guaranteed level premium</li>
          <li>
            Understand more benefits at
            <a
              target="_blank"
              href="https://axa-prod.s3.amazonaws.com/axa-com-my/adc7acc7-83c9-4b65-bab9-bf7fa30c1da7_200cancercare-pds.pdf"
              style="color:#ef5193"
              >here</a
            >
          </li>
        </ol>

        <br />
        <h2>Claim</h2>
        <ol>
          <li>
            Download and submit the completed claim form with all listed
            supporting documents to AXA.
          </li>
          <li>
            For 200 Cancer Care :<a
              style="color:#ef5193"
              target="_blank"
              href="https://axa-prod.s3.amazonaws.com/axa-com-my/adc7acc7-83c9-4b65-bab9-bf7fa30c1da7_200cancercare-pds.pdf"
            >
              200 Cancer Care Claim Form</a
            >
          </li>
          <li>
            Upon receipt of full documents, AXA will revert with claim decision
            within 7 working days.
          </li>
          <li>
            E-claim payment within 7 working days from your claim approval date.
          </li>
        </ol>

        <br />
        <!-- <span
          >Note:<br />
          To purchase CancerCare now, please follow the link below:-
        </span> -->
        <!-- <span><a href="/cancer" style="color:#ef5193;">CancerCare</a></span> -->
      </div>
    </div>
  </section>
  <app-footer></app-footer>
</body>
