import { Component, OnInit, Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AngularFireAuth } from "@angular/fire/auth";
import * as CryptoJS from "crypto-js";
import { Validators, FormControl, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";

@Component({
  selector: "app-resetpassword",
  templateUrl: "./resetpassword.component.html",
  styleUrls: ["./resetpassword.component.css"]
})
@Injectable()
export class ResetpasswordComponent implements OnInit {
  oobCode: string;
  password: FormControl;
  confirmPassword: FormControl;
  isSubmitted = false;
  success = false;
  formdata: FormGroup;
  public response: any = {
    success: false
  };

  constructor(
    public afAuth: AngularFireAuth,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.activatedRoute.queryParams.subscribe(async params => {
      this.success = params["success"] || false;
      this.oobCode = params["oobCode"];
      if (!this.oobCode && !this.success) {
        this.redirectHome();
      }
      this.verifyCode(this.oobCode);
    });
  }

  redirectHome() {
    this.router.navigate(["/"]);
  }

  async verifyCode(code: any) {
    if (!code) {
      return;
    }
    this.response = await this.afAuth.auth
      .verifyPasswordResetCode(code)
      .then(email => {
        return {
          success: true,
          email: email
        };
      })
      .catch(function(error: Error) {
        return {
          success: false,
          error: error
        };
      });
  }

  async resetPassword() {
    if (!this.formdata.valid || !this.response.email) {
      return;
    }

    this.response = await this.afAuth.auth
      .confirmPasswordReset(
        this.oobCode,
        this.encryptPassword(this.response.email, this.password.value)
      )
      .then(function(Success) {
        return {
          success: true
        };
      })
      .catch(function(error) {
        return {
          success: false,
          error: error
        };
      });

    if (this.response.success) {
      this.router.navigate(["/resetpassword"], {
        queryParams: { success: true }
      });
    }
  }

  encryptPassword(email, password): string {
    const key = "v$ure" + email;
    const hash = CryptoJS.HmacSHA256(password, key).toString();
    // console.log('email ' + email);
    // console.log('key ' + key);
    // console.log('password ' + password);
    // console.log('hash ' + hash);
    return hash;
  }

  onSubmit(data) {
    this.isSubmitted = true;
    this.resetPassword();
  }

  ngOnInit() {
    this.password = new FormControl({ value: "" }, Validators.required);
    this.confirmPassword = new FormControl({ value: "" }, Validators.required);
    this.formGroups();
    this.formdata.reset();
  }

  formGroups() {
    this.formdata = new FormGroup({
      password: this.password,
      confirmPassword: this.confirmPassword
    });
  }

  matchPassword() {
    return this.password.value === this.confirmPassword.value;
  }
}
