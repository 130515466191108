<header class="vsure-header">

  <div class="container">
    <div class="row">
      <div class="col-xs-2 col-sm-4 hidden-md hidden-lg">
        <div class="burger-menu">
          <a href="javascript:void(0)" data-toggle="collapse" data-target="#vsure-navbar"
            onClick="sendEvent('Menu', 'Click', 'Burger menu');">
            <i class="fa fa-bars" aria-hidden="true"></i>
          </a>
        </div>
      </div>
      <div class="col-md-5 hidden-xs hidden-sm">
        <ul class="top-left-nav top-nav">
          <li>
            <a class="smooth-scroll" href="#about" onClick="sendEvent('Menu', 'Click', 'About Vsure');">
              About
            </a>
          </li>
          <li>
            <a class="smooth-scroll" href="#works" onClick="sendEvent('Menu', 'Click', 'works');">
              How it
              Works
            </a>
          </li>
          <li>
            <a class="smooth-scroll" href="#offer" onClick="sendEvent('Burger-Menu', 'Click', 'Blog');" target="_blank">
              What
              We Offer
            </a>
          </li>
          <li>
            <a class="smooth-scroll" href="#partners" onClick="sendEvent('Menu', 'Click', 'Team');">
              Partners
            </a>
          </li>
          <!-- <li>
                        <a
                            class="smooth-scroll"
                            href="#buynow"
                            onClick="sendEvent('Menu', 'Click', 'Team');"
                        >
                            Buy Now
                        </a>
                    </li> -->
          <li>
            <a class="smooth-scroll" href="/team" onClick="sendEvent('Menu', 'Click', 'Team');">
              Team
            </a>
          </li>
          <li>
            <a class="smooth-scroll" href="#contact" onClick="sendEvent('Menu', 'Click', 'Team');">Contact Us</a>
          </li>
        </ul>
      </div>
      <div class="col-xs-8 col-sm-4 col-md-2 text-center logo" style="padding-top:20px;">
        <a href="index.html" onClick="ga('send', 'event', 'landing page | home', 'mainmenu', 'logo');">
          <img src="assets/img/vsure_logo.svg" width="auto" height="64" class="black_logo">
          <img src="assets/img/vsure_white.svg" width="auto" height="64" class="white_logo">
        </a>
      </div>
      <div class="col-sm-4 col-md-5 hidden-xs">
        <ul class="top-nav">
          <li class="col-xs-1 pull-right">
            <a href="https://www.facebook.com/vsure.life/" target="_blank">
              <i class="fa fa-facebook"></i>
              <span class="sr-only">Facebook</span>
            </a>
          </li>
          <!-- <li class="col-xs-1 pull-right">
            <a href="#" target="_blank"><i class="fa fa-twitter"></i><span class="sr-only">Twitter</span></a>
          </li> -->
          <li class="col-xs-1 pull-right">
            <a href="https://www.linkedin.com/company/vsuredotcom" target="_blank">
              <i class="fa fa-linkedin"></i>
              <span class="sr-only">LinkedIn</span>
            </a>
          </li>
          <!-- <li class="col-xs-1 pull-right">
            <a href="https://www.instagram.com/vsure.life/" target="_blank"><i class="fa fa-instagram"></i><span class="sr-only">Instagram</span></a>
          </li> -->
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 hidden-md hidden-lg">
        <div class="collapsing vsure-navbar" id="vsure-navbar">
          <div class="container">
            <ul class="col-xs-12">
              <li>
                <a class="smooth-scroll" href="#about" onClick="sendEvent('Burger-Menu', 'Click', 'About Vsure');">
                  About
                </a>
              </li>
              <li>
                <a class="smooth-scroll" href="#works" onClick="sendEvent('Menu', 'Click', 'works');">
                  How
                  it Works
                </a>
              </li>

              <li>
                <a class="smooth-scroll" href="#offer" onClick="sendEvent('Burger-Menu', 'Click', 'Blog');"
                  target="_blank">
                  What
                  We Offer
                </a>
              </li>

              <li>
                <a class="smooth-scroll" href="#partners" onClick="sendEvent('Menu', 'Click', 'Team');">
                  Partners
                </a>
              </li>
              <li>
                <a class="smooth-scroll" href="#buynow" onClick="sendEvent('Menu', 'Click', 'Team');">
                  Buy
                  Now
                </a>
              </li>
              <li>
                <a class="smooth-scroll" href="/team" onClick="sendEvent('Menu', 'Click', 'Team');">
                  Team
                </a>
              </li>
              <li>
                <a class="smooth-scroll" href="/news" onClick="sendEvent('Menu', 'Click', 'Team');">
                  News & Events
                </a>
              </li>
            </ul>
            <ul class="col-xs-12 hidden-sm sub-nav">
              <li>
                <a class="smooth-scroll" target=”_blank” href="/assets/pdf/privacy-policy.pdf"
                  onClick="sendEvent('Footer', 'Click', 'Privacy Policy');">
                  Privacy
                  Policy
                </a>
              </li>
            </ul>
            <div class="social col-xs-12 hidden-sm">
              <h6>Follow us on Social Media</h6>
              <ul class>
                <li class="col-xs-1 pull-right">
                  <a href="https://www.facebook.com/vsure.life/" target="_blank">
                    <i class="fa fa-facebook"></i>
                    <span class="sr-only">Facebook</span>
                  </a>
                </li>
                <!-- <li class="col-xs-1 pull-right">
                  <a href="#" target="_blank"><i class="fa fa-twitter"></i><span class="sr-only">Twitter</span></a>
                </li> -->
                <li class="col-xs-1 pull-right">
                  <a href="https://www.linkedin.com/company/vsuredotcom" target="_blank">
                    <i class="fa fa-linkedin"></i>
                    <span class="sr-only">LinkedIn</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
<style>
  .full-width-image-2 {
    background: url(assets/img/medical.jpg) no-repeat center center;
    background-size: cover;
    text-align: center;
    height: 300px;
  }

</style>

<body>
  <!-- <div class="full-width-image-2"></div> -->

  <div class="container-fluid" style="background-color: #ef5193">
    <div class="container">
      <div class="row" style="margin-top:100px;">
        <div class="col-lg-12" style="text-align: center">
          <h2 class="item-title" style="color:#fff">
            Insurance @ VSure Online
          </h2>
          <h2 class="item-title" style="color:#fff; font-size: 24px;">
            Get protected! BUY NOW!
          </h2>
        </div>
      </div>
      <div class="col-md-12 col-xs-12" style="text-align: center">
        <br />
        <h3 class="title margin-top-15" style="font-size:26px; color:#fff">
          <span style="font-weight: bold">VSure.Life</span> –
          <span style="font-size:26px; ">
            On-Demand Lifestyle Insurance<br />
          </span>
        </h3>

        <h3 style="font-size:20px;color:#fff; margin-top: 20px; ">
          If you are interested to purchase an insurance now, you can
          also purchase through VSure online
        </h3>
        <h3 style="font-size:20px;color:#fff;">
          Don’t wait to get protected. Start to get yourself covered
          now!
        </h3>

        <br>
      </div>

    </div>
  </div>
  <section class="bg-alternate ways">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <h2 style="color:#ef5193">MEDICAL INSURANCE</h2>
        </div>
      </div>
      <div class="row way-holder ">
        <div class="col-md-4 col-lg-4 col-xs-12 way">
          <div style="border: 1px solid #8c8b8b;">
            <br>
            <img src="assets/img/190.jpg" class="rounded float-left" width="190" height="190" alt="Clear"
              onClick="sendEvent('ABetterWay', 'Click', 'Clear');">
            <h2 class="title margin-top-15" onClick="sendEvent('ABetterWay', 'Click', 'Clear');">
              AXA eMedic
            </h2>
            <div class="content-holder">
              <div class="details content margin-top-20">

                <div style="text-align: left;    line-height: 23px;">
                  You are at the prime of your health, but are you fully prepared for this adventure
                  called Life? Don't worry - we have your back! Introducing AXA eMedic, a standalone
                  online medical card that ensures you have affordable and easily accessible
                  protection
                  against rising costs of medical fees.
                </div>
              </div>
              <div style="margin-top:90px;"></div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-lg-4 col-xs-12 way">
          <div style="border: 1px solid #8c8b8b; ">
            <h2 class="title margin-top-15" onClick="sendEvent('ABetterWay', 'Click', 'Fair');">
              Key
              Benefits
            </h2>
            <div class="content-holder">
              <div class="details content margin-top-20">

                <ul style="text-align: left;">
                  <p>Simple online application</p>
                  <p>No medical check-ups required.</p>
                  <p>
                    Unlimited days on room and board, limit up to RM250 per day
                  </p>
                  <p>
                    Affordable premium with premiums as low as RM37 a month
                  </p>
                  <p>
                    Savings & Lower Premium with premium paid annually or selecting deductible
                    option.
                  </p>
                  <p>
                    Cashless admission at panel hospitals by just presenting your online medical
                    card
                    for
                    admission.
                  </p>
                  <p>No lifetime limit up until age 80</p>

                </ul>
                <br>
                <br>
                <div style="margin-top: 70px;"></div>

              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-lg-4 col-xs-12 way">
          <div style=" border: 1px solid #8c8b8b; padding: 20px; ">
            <h2 class="title margin-top-15" onClick="sendEvent('ABetterWay', 'Click', 'Fair');">
              Buy Now
            </h2>
            <br>
            <form (ngSubmit)="onSubmit()" method="POST">

              <div class="form-group">
                <input type="text" class="form-control" id="name" [formControl]="name" placeholder="*Full Name"
                  required>
              </div>
              <div class="form-group">
                <input type="text" class="form-control" id="email" [formControl]="email" placeholder=" *Email" required>
              </div>
              <div class="form-group">
                <input type="text" class="form-control" id="referral" [formControl]="referral"
                  placeholder="Referral Code - Optional">
              </div>

              <recaptcha [formControl]="myRecaptcha"></recaptcha>

              <div class="form-check" style="text-align: left">
                <input type="checkbox" class="form-check-input" id="exampleCheck1" [checked]="checkBoxStatus"
                  (change)="checkBoxStatus = !checkBoxStatus" required>
                <label class="form-check-label" for="exampleCheck1">
                  &nbsp; I agree to the
                  <a href="/assets/pdf/terms-of-use.pdf">Terms</a>
                  &
                  <a href="/assets/pdf/privacy-policy.pdf">Privacy policies.</a>
                </label>
              </div>

              <input type="submit" class="btn submit"
                [disabled]="!name || !email || !checkBoxStatus || !myRecaptcha.value" value="Submit"
                onclick="window.open('https://www.axa.com.my/buy/online-medical-card-malaysia/purchase?id=8fINojHcM0')">

              <br>
              <small>This product is underwritten by</small>
              <br>
              <img style="margin-top: 10px;" src="assets/img/axa1.jpg">
            </form>
            <div style="margin-top: 24px; float: left"></div>
          </div>
        </div>
      </div>

    </div>
  </section>
  <app-footer></app-footer>

</body>
