<!DOCTYPE html>
<html>
  <header>
    <title>V-Login</title>
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <!-- <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous"> -->
    <link
      href="//maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css"
      rel="stylesheet"
      id="bootstrap-css"
    />
    <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.min.js"></script>
    <!-- <script src="//cdnjs.cloudflare.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script> -->
    <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js"></script>
    <script src="https://cdn.jsdelivr.net/jquery.validation/1.15.1/jquery.validate.min.js"></script>
    <link
      href="https://fonts.googleapis.com/css?family=Kaushan+Script"
      rel="stylesheet"
    />
    <link
      href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
      rel="stylesheet"
      integrity="sha384-wvfXpqpZZVQGK6TAh5PVlGOfQNHSoD2xbE+QkPxCAFlNEevoEH3Sl0sibVcOQVnN"
      crossorigin="anonymous"
    />
    <link
      rel="stylesheet"
      href="https://use.fontawesome.com/releases/v5.7.0/css/all.css"
      integrity="sha384-lZN37f5QGtY3VHgisS14W3ExzMWZxybE1SJSEsQp9S+oqd12jhcu+A56Ebc1zFSJ"
      crossorigin="anonymous"
    />
    <!-- <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous"> -->
    <link
      href="https://fonts.googleapis.com/icon?family=Material+Icons"
      rel="stylesheet"
    />
    <style>
      a {
        text-decoration: none !important;
      }

      h1,
      h2,
      h3 {
        font-family: "Kaushan Script", cursive;
      }

      .myform {
        position: relative;
        display: -ms-flexbox;
        display: flex;
        padding: 20px;
        -ms-flex-direction: column;
        flex-direction: column;
        width: 100%;
        pointer-events: auto;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 1.1rem;
        outline: 0;
        max-width: 500px;
      }

      .tx-tfm {
        text-transform: uppercase;
      }

      .mybtn {
        border-radius: 50px;
      }

      .login-or {
        position: relative;
        color: #aaa;
        margin-top: 10px;
        margin-bottom: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
      }

      .span-or {
        display: block;
        position: absolute;
        left: 50%;
        top: -2px;
        margin-left: -74px;
        background-color: #fff;
        width: 161px;
        text-align: center;
      }

      .hr-or {
        height: 1px;
        margin-top: 0px !important;
        margin-bottom: 0px !important;
      }

      .google {
        color: #fff;
        padding: 13px;
        width: 100%;
        height: 50px;
        text-align: center;
        outline: none;
        border: 1px solid lightgrey;
        background-color: #dd4b39;
      }

      .facebook {
        color: #fff;
        padding: 13px;
        width: 100%;
        height: 50px;
        text-align: center;
        outline: none;
        border: 1px solid lightgrey;
        background-color: #3b5998;
      }

      form .error {
        color: #ff0000;
      }

      #second {
        display: none;
      }

      .btn-primary {
        color: #fff;
        background-color: #c02d64;
        border-color: #c02d64;
      }
    </style>
  </header>

  <body>
    <div class="container-fluid" style="background-color: #c02d64;">
      <div class="container" style="    text-align: center;">
        <img src="assets/img/logo2.svg" width="150" />
      </div>
    </div>

    <div class="container">
      <div class="col-sm-12 col-md-12" style="text-align: center">
        <br /><br /><br /><br /><br /><br />
        <div class="col-sm-12 col-md-12" *ngIf="success">
          <p>
            <i
              style="font-size:70px;color: #32CD32;"
              class="far fa-check-circle"
            ></i>
          </p>
          <p style="font-size:30px; font-weight: bold">Success!</p>
          <p style="font-size: 20px;">
            Your password has been updated successfully!
          </p>
          <p>
            <button
              _ngcontent-c0=""
              style="font-size:16px;"
              class="btn btn-lg btn-video smooth-scroll cta"
              (click)="redirectHome()"
            >
              Home
            </button>
          </p>
        </div>
        <div class="col-sm-3 col-md-3"></div>
        <div
          class="col-sm-6 col-md-6"
          *ngIf="!response?.success && response?.error?.message"
        >
          <p>
            <i
              style="font-size:70px;color: rgb(205, 50, 50);"
              class="fa fa-exclamation-triangle"
              aria-hidden="true"
            ></i>
          </p>
          <p style="font-size:30px; font-weight: bold">Oops!</p>
          <p style="font-size: 20px;">{{ response?.error?.message }}</p>
        </div>
        <div class="col-sm-2 col-md-2"></div>
        <div></div>
      </div>
    </div>

    <div class="container" *ngIf="response.success && !success">
      <br />
      <div class="row">
        <div class="col-md-4 mx-auto">
          <div id="first">
            <div class="myform form ">
              <div class="logo mb-3">
                <div class="col-md-12 text-center">
                  <h4>Reset Password</h4>
                </div>
              </div>
              <!-- <form action="" method="post" name="login"> -->
              <form
                novalidate
                [formGroup]="formdata"
                (ngSubmit)="onSubmit(formdata.value)"
              >
                <div class="form-group">
                  <br />
                  <!-- <label for="exampleInputEmail1">Email address</label> -->
                  <!-- <i class="fa fa-user" aria-hidden="true"></i>  -->
                  <input
                    style="font-size: 13px;"
                    type="password"
                    name="password"
                    class="form-control"
                    id="password"
                    aria-describedby="emailHelp"
                    placeholder="New Password"
                    formControlName="password"
                  />
                  <div
                    *ngIf="
                      password.invalid &&
                      (password.dirty || password.touched || isSubmitted)
                    "
                    class="error-message"
                  >
                    Password is required.
                  </div>
                </div>
                <div class="form-group" style="padding-top: 10px; ">
                  <!-- <label for="exampleInputEmail1">Password</label> -->
                  <input
                    style="font-size: 13px;"
                    type="password"
                    name="confirmPassword"
                    id="confirmPassword"
                    class="form-control"
                    aria-describedby="emailHelp"
                    placeholder="Confirm Password"
                    formControlName="confirmPassword"
                  />
                  <div
                    *ngIf="
                      confirmPassword.invalid &&
                      (confirmPassword.dirty ||
                        confirmPassword.touched ||
                        isSubmitted)
                    "
                    class="error-message"
                  >
                    Confirm Password is required.
                  </div>
                  <div
                    *ngIf="!matchPassword() && confirmPassword.touched"
                    class="error-message"
                  >
                    Password did not match.
                  </div>
                </div>
                <!-- <div class="form-group">
                <p class="text-right"> <a href="#">Forgot Password?</a></p>
              </div> -->
                <div class="col-md-12 text-center ">
                  <button
                    type="submit"
                    style="padding: 13px; margin-top:30px; font-size: 14px;"
                    class=" btn btn-block mybtn btn-primary tx-tfm"
                  >
                    Submit
                  </button>
                  <br />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </body>
</html>
