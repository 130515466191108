import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-motor",
  templateUrl: "./motor.component.html",
  styleUrls: ["./motor.component.css"]
})
export class MotorComponent implements OnInit {
  formGroup: FormGroup;
  checkBoxStatus = false;
  readonly = false;

  constructor(public http: HttpClient, private route: ActivatedRoute) {}

  ngOnInit() {
    this.formGroup = new FormGroup({
      name: new FormControl(null, [
        Validators.required,
        Validators.pattern("[a-z/A-Z][a-z/A-Z ]+")
      ]),
      email: new FormControl(null, [
        Validators.required,
        Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$")
      ]),
      referral: new FormControl(""),
      myRecaptcha: new FormControl(false)
    });
    this.route.queryParams.subscribe(data => {
      if (data.referral_code) {
        this.formGroup.get("referral").setValue(data.referral_code);
        this.readonly = true;
      }
    });
  }

  onSubmit(data) {
    // TODO: Create a Service
    const formData: FormData = new FormData();
    formData.append("Name", data.name);
    formData.append("Email", data.email);
    formData.append("Referral", data.referral);
    formData.append("Type", "Motor Comprehensive Cover");

    this.http
      .post(
        "https://script.google.com/macros/s/AKfycbyej8YM5pKrfFJcam2O_tmXtOpuE-R8VJp8ZrGHbzw4ASjMsNI/exec",
        formData
      )
      .subscribe(
        data => {
          console.log("POST Request is successful ", data);
        },
        error => {
          console.log("Error", error);
        }
      );
  }
}
