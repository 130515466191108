import { Component, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { FormBuilder, FormGroup } from "@angular/forms";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"]
})
export class HomeComponent implements OnInit {
  userForm: any;
  public isEmailSend;
  public isNameEnter;
  public isEmailEnter;
  name: string;
  email;
  public form: FormGroup;
  constructor(private formBuilder: FormBuilder, public http: HttpClient) {}

  ngOnInit() {}

  onSubmit() {
    console.log("onSubmit name=" + this.name + " this.email=" + this.email);
    /*  if((!this.email || this.email.length==0)&& (!this.name || this.name.length==0)){
       console.log("1");
      this.isEmailEnter=true;
      this.isNameEnter=true;
     }
    else*/ if (
      !this.email ||
      this.email.length == 0
    ) {
      console.log("2");
      this.isEmailEnter = true;
    } else if (!this.name || this.name.length == 0) {
      console.log("3");
      this.isNameEnter = true;
      this.isEmailEnter = false;
    } else {
      // TODO: Create a Service
      const formData: FormData = new FormData();
      formData.append("Name", this.name);
      formData.append("Email", this.email);

      this.http
        .post(
          "https://script.google.com/macros/s/AKfycbxaYt2xurE8gQuVKwX6A5kJGBPWMxXGmd_7Jy54ad4Kylrq0DU/exec",
          formData
        )
        .subscribe(
          data => {
            var jsonObject: any = data;
            console.log("POST Request is successful ", data);
            if (jsonObject.result === "success") {
              this.isEmailSend = true;
              this.isEmailEnter = false;
              this.isNameEnter = false;
            } else {
              this.isEmailSend = false;
            }
          },
          error => {
            console.log("Error", error);
          }
        );
    }
  }
}
