import { Component, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { FormControl } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-cancer",
  templateUrl: "./cancer.component.html",
  styleUrls: ["./cancer.component.css"]
})
export class CancerComponent implements OnInit {
  checkBoxStatus: Boolean = false;
  name = new FormControl("");
  email = new FormControl("");
  referral = new FormControl("");
  myRecaptcha = new FormControl(false);

  constructor(public http: HttpClient, private route: ActivatedRoute) {
    this.route.queryParams.subscribe(data => {
      if (data.referral_code) {
        this.referral.setValue(data.referral_code);
      }
    });
  }

  ngOnInit() {}
  onSubmit() {
    // TODO: Create a Service
    const formData: FormData = new FormData();
    formData.append("Name", this.name.value);
    formData.append("Email", this.email.value);
    formData.append("Referral", this.referral.value);
    formData.append("Type", "Cancer Insurance");

    this.http
      .post(
        "https://script.google.com/macros/s/AKfycbyej8YM5pKrfFJcam2O_tmXtOpuE-R8VJp8ZrGHbzw4ASjMsNI/exec",
        formData
      )
      .subscribe(
        data => {
          console.log("POST Request is successful ", data);
        },
        error => {
          console.log("Error", error);
        }
      );
  }
}
