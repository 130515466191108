<header class="vsure-header">
  <div class="container">
    <div class="row">
      <div class="col-xs-2 col-sm-4 hidden-md hidden-lg">
        <div class="burger-menu">
          <a href="javascript:void(0)" data-toggle="collapse" data-target="#vsure-navbar"
            onClick="sendEvent('Menu', 'Click', 'Burger menu');">
            <i class="fa fa-bars" aria-hidden="true"></i>
          </a>
        </div>
      </div>
      <div class="col-md-5 hidden-xs hidden-sm">
        <ul class="top-left-nav top-nav">
          <li>
            <a class="smooth-scroll" href="#about" onClick="sendEvent('Menu', 'Click', 'About Vsure');">
              About
            </a>
          </li>
          <li>
            <a class="smooth-scroll" href="#works" onClick="sendEvent('Menu', 'Click', 'works');">
              How it Works
            </a>
          </li>
          <li>
            <a class="smooth-scroll" href="#offer" onClick="sendEvent('Burger-Menu', 'Click', 'Blog');" target="_blank">
              What We Offer
            </a>
          </li>
          <li>
            <a class="smooth-scroll" href="#partners" onClick="sendEvent('Menu', 'Click', 'Team');">
              Partners
            </a>
          </li>
          <!-- <li>
                        <a
                            class="smooth-scroll"
                            href="#buynow"
                            onClick="sendEvent('Menu', 'Click', 'Team');"
                        >
                            Buy Now
                        </a>
                    </li> -->
          <li>
            <a class="smooth-scroll" href="/team" onClick="sendEvent('Menu', 'Click', 'Team');">
              Team
            </a>
          </li>
          <li>
            <a class="smooth-scroll" href="#contact" onClick="sendEvent('Menu', 'Click', 'Team');">Contact Us</a>
          </li>
        </ul>
      </div>
      <div class="col-xs-8 col-sm-4 col-md-2 text-center logo" style="padding-top:20px;">
        <a href="index.html" onClick="ga('send', 'event', 'landing page | home', 'mainmenu', 'logo');">
          <img src="assets/img/vsure_logo.svg" width="auto" height="64" class="black_logo" />
          <img src="assets/img/vsure_white.svg" width="auto" height="64" class="white_logo" />
        </a>
      </div>
      <div class="col-sm-4 col-md-5 hidden-xs">
        <ul class="top-nav">
          <li class="col-xs-1 pull-right">
            <a href="https://www.facebook.com/vsure.life/" target="_blank">
              <i class="fa fa-facebook"></i>
              <span class="sr-only">Facebook</span>
            </a>
          </li>
          <!-- <li class="col-xs-1 pull-right">
            <a href="#" target="_blank"><i class="fa fa-twitter"></i><span class="sr-only">Twitter</span></a>
          </li> -->
          <li class="col-xs-1 pull-right">
            <a href="https://www.linkedin.com/company/vsuredotcom" target="_blank">
              <i class="fa fa-linkedin"></i>
              <span class="sr-only">LinkedIn</span>
            </a>
          </li>
          <!-- <li class="col-xs-1 pull-right">
            <a href="https://www.instagram.com/vsure.life/" target="_blank"><i class="fa fa-instagram"></i><span class="sr-only">Instagram</span></a>
          </li> -->
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 hidden-md hidden-lg">
        <div class="collapsing vsure-navbar" id="vsure-navbar">
          <div class="container">
            <ul class="col-xs-12">
              <li>
                <a class="smooth-scroll" href="#about" onClick="sendEvent('Burger-Menu', 'Click', 'About Vsure');">
                  About
                </a>
              </li>
              <li>
                <a class="smooth-scroll" href="#works" onClick="sendEvent('Menu', 'Click', 'works');">
                  How it Works
                </a>
              </li>

              <li>
                <a class="smooth-scroll" href="#offer" onClick="sendEvent('Burger-Menu', 'Click', 'Blog');"
                  target="_blank">
                  What We Offer
                </a>
              </li>

              <li>
                <a class="smooth-scroll" href="#partners" onClick="sendEvent('Menu', 'Click', 'Team');">
                  Partners
                </a>
              </li>
              <li>
                <a class="smooth-scroll" href="#buynow" onClick="sendEvent('Menu', 'Click', 'Team');">
                  Buy Now
                </a>
              </li>
              <li>
                <a class="smooth-scroll" href="/team" onClick="sendEvent('Menu', 'Click', 'Team');">
                  Team
                </a>
              </li>
              <li>
                <a class="smooth-scroll" href="/news" onClick="sendEvent('Menu', 'Click', 'Team');">
                  News & Events
                </a>
              </li>
            </ul>
            <ul class="col-xs-12 hidden-sm sub-nav">
              <li>
                <a class="smooth-scroll" target="”_blank”" href="/assets/pdf/privacy-policy.pdf"
                  onClick="sendEvent('Footer', 'Click', 'Privacy Policy');">
                  Privacy Policy
                </a>
              </li>
            </ul>
            <div class="social col-xs-12 hidden-sm">
              <h6>Follow us on Social Media</h6>
              <ul class>
                <li class="col-xs-1 pull-right">
                  <a href="https://www.facebook.com/vsure.life/" target="_blank">
                    <i class="fa fa-facebook"></i>
                    <span class="sr-only">Facebook</span>
                  </a>
                </li>
                <!-- <li class="col-xs-1 pull-right">
                  <a href="#" target="_blank"><i class="fa fa-twitter"></i><span class="sr-only">Twitter</span></a>
                </li> -->
                <li class="col-xs-1 pull-right">
                  <a href="https://www.linkedin.com/company/vsuredotcom" target="_blank">
                    <i class="fa fa-linkedin"></i>
                    <span class="sr-only">LinkedIn</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
<style>
  .full-width-image-2 {
    background: url(assets/img/cancer.jpg) no-repeat center center;
    background-size: cover;
    text-align: center;
    height: 300px;
  }
</style>

<body>
  <div class="full-width-image-2"></div>
  <section class="bg-alternate ways">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <h2 style="color:#ef5193">eMedic</h2>
        </div>
        <!-- <h3 style="color: #ef5193;">
            Download VSure.life App Now to Purchase eMedic
          </h3> -->
        <!-- <br /> -->
        <!--   
          <div>
            <div class="banner-watch">
              &nbsp;&nbsp;
              <a
                href="https://play.google.com/store/apps/details?id=com.vsure.live"
                target="_blank"
              >
                <img src="assets/img/googleplay.png"/></a
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <a
                href="https://itunes.apple.com/my/app/vsure-life-on-demand-insurance/id1460213328?mt=8"
                target="_blank"
              >
                <img src="assets/img/app.png"
              /></a>
            </div>
<br>
<h5>Note:</h5> 
            <ol style="text-decoration: none">
              
              <li>Purchase eMedic using VSure.life app is applicable to users from 16 to 39 years old.</li>
              <li>If your age is between 40 to 49 or you need to purchase for your family members (age starts from 15 days to 39 years old), please scroll below to buy through our web channel</li>
            </ol>
          </div> -->
      </div>
      <div class="row way-holder ">
        <div class="col-md-4 col-lg-4 col-xs-12 way">
          <div style="border: 1px solid #8c8b8b;">
            <br />
            <img src="assets/img/190.jpg" class="rounded float-left" width="190" height="190" alt="Clear"
              onClick="sendEvent('ABetterWay', 'Click', 'Clear');" />
            <h2 class="title margin-top-15" onClick="sendEvent('ABetterWay', 'Click', 'Clear');">
              AXA eMedic
            </h2>
            <div class="content-holder">
              <div class="details content margin-top-20">
                <div style="text-align: left;    line-height: 23px;">
                  You are at the prime of your health, but are you fully
                  prepared for this adventure called Life? Don't worry - we have
                  your back! Introducing AXA eMedic, a standalone online medical
                  card that ensures you have affordable and easily accessible
                  protection against rising costs of medical fees.
                </div>
              </div>
              <div style="margin-top:90px;"></div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-lg-4 col-xs-12 way">
          <div style="border: 1px solid #8c8b8b; ">
            <h2 class="title margin-top-15" onClick="sendEvent('ABetterWay', 'Click', 'Fair');">
              Key Benefits
            </h2>
            <div class="content-holder">
              <div class="details content margin-top-20">
                <ul style="text-align: left;">
                  <p>Simple online application</p>
                  <p>No medical check-ups required.</p>
                  <p>
                    Unlimited days on room and board, limit up to RM250 per day
                  </p>
                  <p>
                    Affordable premium with premiums as low as RM37 a month
                  </p>
                  <p>
                    Savings & Lower Premium with premium paid annually or
                    selecting deductible option.
                  </p>
                  <p>
                    Cashless admission at panel hospitals by just presenting
                    your online medical card for admission.
                  </p>
                  <p>No lifetime limit up until age 80</p>
                </ul>
                <br />
                <br />
                <div style="margin-top: 70px;"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-lg-4 col-xs-12 way">
          <div style=" border: 1px solid #8c8b8b; padding: 20px; ">
            <h2 class="title margin-top-15" onClick="sendEvent('ABetterWay', 'Click', 'Fair');">
              Buy Now
            </h2>
            <br />
            <form [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)" method="POST">
              <div class="form-group">
                <input type="text" class="form-control" id="name" formControlName="name" placeholder="*Full Name"
                  required />
              </div>
              <div class="form-group">
                <input type="text" class="form-control" id="email" formControlName="email" placeholder=" *Email"
                  required />
              </div>
              <div class="form-group">
                <input type="text" class="form-control" id="referral" formControlName="referral"
                  placeholder="Referral Code - Optional" [readonly]="readonly" />
              </div>

              <recaptcha formControlName="myRecaptcha"></recaptcha>

              <div class="form-check" style="text-align: left">
                <input type="checkbox" class="form-check-input" id="exampleCheck1" [checked]="checkBoxStatus"
                  (change)="checkBoxStatus = !checkBoxStatus" required />
                <label class="form-check-label" for="exampleCheck1">
                  &nbsp; I agree to the
                  <a href="/assets/pdf/terms-of-use.pdf">Terms</a>
                  &
                  <a href="/assets/pdf/privacy-policy.pdf">Privacy policies.</a>
                </label>
              </div>

              <input type="submit" class="btn submit" [disabled]="formGroup.invalid || !checkBoxStatus" value="Submit"
                onclick="window.open('https://www.axa.com.my/buy/online-medical-card-malaysia/purchase?id=8fINojHcM0')" />

              <br />
              <small>This product is underwritten by</small>
              <br />
              <img style="margin-top: 10px;" src="assets/img/axa1.jpg" />
            </form>
            <div style="margin-top: 24px; float: left"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="bg-alternate ways e-mid">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">

          <div class="col-lg-7" style="text-align: left">
            <h2 style="text-align: left">Product</h2>
            <span>Introducing eMedic, a medical insurance that can be bought by VSure
              with affordable price and protection against expensive medical
              cost. eMedic is available for those age below 49 years old and provide
              annual coverage up to RM100,000 and is renewable up to age 80. If you
              are searching for a medical protection for your family, eMedic is your
              ideal choice. You can purchase eMedic with VSure.
            </span>
            <br><br><br>
            <div class="hidden-xs hidden-sm hidden-md">

              <h2>Benefit / Coverage</h2>

              <ol type="I">
                <li>Guaranteed renewal up until the age of 80 years</li>
                <li>No Lifetime Limit</li>
                <li>Starting from as low as RM 37 per month</li>
                <li>Room & Board allowance of RM 250</li>
                <li>
                  Cashless Admission to over 100 medical centers and hospitals in
                  Malaysia
                </li>
                <li>
                  Out Patient Coverage (Pre & Post Hospitalisation) - 31 days (max. 3
                  times) before hospital confinement and 60 days from hospital
                  discharge
                </li>
                <li>
                  Understand more benefit at
                  <a target="_blank"
                    href="https://axa-com-my.cdn.axa-contento-118412.eu/axa-com-my%2F1ae7652f-1fab-4813-9acc-e57f0b3d8cbf_axa+emedic_faq+age49.pdf"
                    style="color:#ef5193">here</a>
                </li>
              </ol>
            </div>
          </div>

          <div class="col-lg-5">
            <iframe style="border: 3px solid #ffffff;" width="100%" height="250"
              src="https://www.youtube.com/embed/cJ1t9P5BIRI" frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </div>
      </div>

      <div class="contant" style="text-align: left">

        <div class=" hidden-lg ">
          <br>
          <h2 style="margin-left:20px !important; ">Benefit / Coverage</h2>

          <ol type="I">
            <li>Guaranteed renewal up until the age of 80 years</li>
            <li>No Lifetime Limit</li>
            <li>Starting from as low as RM 37 per month</li>
            <li>Room & Board allowance of RM 250</li>
            <li>
              Cashless Admission to over 100 medical centers and hospitals in
              Malaysia
            </li>
            <li>
              Out Patient Coverage (Pre & Post Hospitalisation) - 31 days (max. 3
              times) before hospital confinement and 60 days from hospital
              discharge
            </li>
            <li>
              Understand more benefit at
              <a target="_blank"
                href="https://axa-com-my.cdn.axa-contento-118412.eu/axa-com-my%2F1ae7652f-1fab-4813-9acc-e57f0b3d8cbf_axa+emedic_faq+age49.pdf"
                style="color:#ef5193">here</a>
            </li>
          </ol>
        </div>
        <br />
        <h2>Claim</h2>

        <span>If you choose to visit a non-panel hospital or want to claim for
          outpatient treatment, you will need to pay for the medical expenses
          first, and then submit the claim to AXA AFFIN Life Insurance Berhad
          for reimbursement via
        </span>
        <br />

        <ol>
          <li>email (claims@axa-life.com.my) ,</li>
          <li>
            walk-in to any of our branch (refer to
            <a target="_blank" href="https://www.axa.com.my/contact-us"
              style="color:#ef5193">https://www.axa.com.my/contact-us</a>)
          </li>
        </ol>

        <br />
        <span>
          For the reimbursement, you will need to submit the following:
        </span>
        <ol>
          <li>
            Claim form (by you) – Download Claim Form at
            <a target="_blank" style="color:#ef5193"
              href="https://axa-com-my.cdn.axa-contento-118412.eu/axa-com-my/bb70575a-6302-4fc1-800c-ab4e6d51da4c_5+axa+emedic_fact+sheet+20180528_clean.pdf">here</a>
          </li>
          <li>
            Medical claim form (by doctor) – Not applicable for follow up visit
          </li>
          <li>
            Itemized Medical Bill – this is a detailed medical expenses issued
            by the hospital
          </li>
          <li>Payment Receipts issued by the hospital</li>
          <li>Copy of NRIC</li>
        </ol>

        <br />
        <span style="font-style: italic;">AXA may request for additional copies of report for e.g. test result
          in certain situations.
        </span>
        <br />


        <br />

        <!-- <span
          >Note:<br />
          To purchase for your family member (age starts from 15 days to 39
          years old), please follow the link below:-
        </span> -->
        <br />

        <!-- <p><a href="/medical" style="color:#ef5193;">eMedic</a></p> -->
      </div>
    </div>
  </section>
  <app-footer></app-footer>
</body>