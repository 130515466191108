import * as CryptoJS from "crypto-js";
import { Apollo } from "apollo-angular";
import gql from "graphql-tag";
import { Injectable } from "@angular/core";

@Injectable()
export class UserService {
  constructor(private apollo: Apollo) {}

  registerOnline(params) {
    return this.apollo.mutate({
      mutation: gql`
        mutation registerOnline($input: RegisterOnlineInput!) {
          registerOnline(input: $input) {
            profile {
              id
              email
            }
          }
        }
      `,
      variables: params,
      fetchPolicy: "no-cache"
    });
  }

  encryptPassword(email, password): string {
    const key = "v$ure" + email;
    const hash = CryptoJS.HmacSHA256(password, key).toString();
    return hash;
  }
}
