<!-- <p>REGISTRATION PAGE</p>
<form [formGroup]="registerForm" method="post">
  <input type="text" formControlName="name" placeholder="Name" /> <br />
  <p *ngIf="isValid('Name', 'name')" style="color: red">{{ error.name }}</p>
  <input
    [owlDateTime]="dt1"
    [owlDateTimeTrigger]="dt1"
    formControlName="date_of_birth"
    placeholder="Date of Birth"
    readonly
  />

  <owl-date-time #dt1 [pickerType]="'calendar'"></owl-date-time>
  <br />
  <p *ngIf="isValid('Date of Birth', 'date_of_birth')" style="color: red">
    {{ error.date_of_birth }}
  </p>
  <input type="text" formControlName="email" placeholder="Email" /><br />
  <p *ngIf="isValid('Email', 'email')" style="color: red">{{ error.email }}</p>
  <input
    type="password"
    formControlName="password"
    placeholder="Password"
  /><br />
  <p *ngIf="isValid('Password', 'password')" style="color: red">
    {{ error.password }}
  </p>
  <input
    type="password"
    formControlName="confirm_password"
    placeholder="Confirm Password"
  /><br />
  <p
    *ngIf="isValid('Confirm Password', 'confirm_password', true)"
    style="color: red"
  >
    {{ error.confirm_password }}
  </p>
  <input
    type="text"
    formControlName="referrer_code"
    placeholder="Referral Code"
  /><br />
  <button type="submit" (click)="registerOnline(registerForm.value)">
    Submit
  </button>
</form> -->

<!DOCTYPE html>
<html lang="en">
  <head>
    <title>Vsure Registration</title>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <!--===============================================================================================-->
    <link rel="icon" type="image/png" href="images/icons/favicon.ico" />
    <!--===============================================================================================-->
    <link
      rel="stylesheet"
      href="../../assets/css/bootstrap/css/bootstrap-grid.min.css"
    />
    <link
      rel="stylesheet"
      href="../../assets/css/bootstrap/css/bootstrap.min.css"
    />
    <!--===============================================================================================-->
    <link
      rel="stylesheet"
      type="text/css"
      href="../../assets/fonts/font-awesome-4.7.0/css/font-awesome.min.css"
    />
    <!--===============================================================================================-->
    <link
      rel="stylesheet"
      type="text/css"
      href="../../assets/fonts/Linearicons-Free-v1.0.0/icon-font.min.css"
    />
    <!--===============================================================================================-->
    <!-- 
         <link rel="stylesheet" type="text/css" href="../../assets/css/util.css" />
         <link rel="stylesheet" type="text/css" href="../../assets/css/main.css" /> -->
    <!--===============================================================================================-->
    <style>
      body {
        padding-bottom: 1px !important;
      }
      .err {
        color: red !important;
        padding-left: 5px !important;
      }
      hr {
        border-color: #fff !important;
      }
    </style>
  </head>
  <body>
    <div class="limiter">
      <div class="container-login100 ">
        <div class="wrap-login101 p-l-85 p-r-85 p-t-55 p-b-55">
          <p class="mob-tex" style="color: #fff; font-size: 40px;">
            Making sure you are protected <br />
            for the things that matter MOST.
          </p>
          <br />
          <p
            class="mob-tex1"
            style="color: #fff; font-size: 60px; font-weight: bold;"
          >
            We’ve got your back
          </p>

          <hr style="color: #fff; " />
          <div
            class="d-none"
            style="text-align: center; text-align: center; color: #fff; font-size: 12px;"
          >
            Don't have Vsure app installed on your phone?
          </div>

          <p class="d-none">
            <a
              href="https://itunes.apple.com/my/app/vsure-life-on-demand-insurance/id1460213328?mt=8"
              target="_blank"
              ><img src="../../assets/img/ios.svg" width="200"
            /></a>
            <a
              href="https://play.google.com/store/apps/details?id=com.vsure.live"
              target="_blank"
              ><img src="../../assets/img/ard.svg" width="200"
            /></a>
          </p>

          <div
            class="d-none d-md-none d-xl-block"
            style="text-align: center; text-align: center; color: #fff; font-size: 12px;"
          >
            Don't have Vsure app installed on your phone?
          </div>

          <p class="d-none d-md-none d-xl-block">
            <a
              href="https://itunes.apple.com/my/app/vsure-life-on-demand-insurance/id1460213328?mt=8"
              target="_blank"
              ><img src="../../assets/img/ios.svg" width="200"
            /></a>
            <a
              href="https://play.google.com/store/apps/details?id=com.vsure.live"
              target="_blank"
              ><img src="../../assets/img/ard.svg" width="200"
            /></a>
          </p>
        </div>
        <div class="wrap-login100 p-l-85 p-r-85 p-t-55 p-b-55">
          <p style="text-align: center">
            <img src="../../assets/img/logo.svg" width="50" />
          </p>
          <div class="container" *ngIf="!showMessage">
            <br />
            <div class="row">
              <div class="col-sm-4 col-xs-4">
                <!-- <hr class="d-none d-sm-block" /> -->
              </div>
              <div
                class="col-sm-4 col-xs-4"
                style="color: #C02D64; font-weight:bold; text-align: center; font-size: 14px;"
              >
                REGISTRATION
              </div>
              <div class="col-sm-4 col-xs-4">
                <!-- <hr class="d-none d-sm-block" /> -->
              </div>
            </div>
            <br />
          </div>
          <div *ngIf="showMessage" style="margin-top:30px">
            <h1
              class="mob-tex1 text-center"
              style="font-weight: bold; font-size:40px;"
            >
              Thank you for registration.
            </h1>
            <br /><br />
            <p>
              <a routerLink=""
                ><button
                  type="button"
                  class="btn btn-secondary"
                  style="width: 50%; padding: 10px; background-color:#C02D64 !important; font-size: 14px; border: none;"
                >
                  Home
                </button></a
              >
            </p>
            <br /><br />
            <br /><br />
          </div>
          <form
            *ngIf="!showMessage"
            [formGroup]="registerForm"
            class="login100-form validate-form flex-sb flex-w"
            method="post"
          >
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                formControlName="name"
                placeholder="Name"
              />
              <span style="color: red" *ngIf="isValid('Name', 'name')">{{
                error.name
              }}</span>
            </div>
            <div class="form-group">
              <input
                type="text"
                [owlDateTime]="dt1"
                [owlDateTimeTrigger]="dt1"
                [max]="maxDate"
                formControlName="date_of_birth"
                class="form-control"
                placeholder="Date of Birth"
                readonly
              />
              <owl-date-time #dt1 [pickerType]="'calendar'"></owl-date-time>
              <span
                style="color: red"
                *ngIf="isValid('Date of Birth', 'date_of_birth')"
                >{{ error.date_of_birth }}</span
              >
            </div>
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                formControlName="email"
                placeholder="Email"
              />
              <span style="color: red" *ngIf="isValid('Email', 'email')">{{
                error.email
              }}</span>
            </div>
            <div class="form-group">
              <input
                type="password"
                class="form-control"
                formControlName="password"
                placeholder="Password"
              />
              <span
                style="color: red"
                *ngIf="isValid('Password', 'password')"
                >{{ error.password }}</span
              >
            </div>
            <div class="form-group">
              <input
                type="password"
                class="form-control"
                formControlName="confirm_password"
                placeholder="Confirm Password"
              />
              <span
                style="color: red"
                *ngIf="isValid('Confirm Password', 'confirm_password', true)"
                >{{ error.confirm_password }}</span
              >
            </div>
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                placeholder="Referral Code - Optional"
                formControlName="referrer_code"
                [readonly]="readonly"
              />
              <!-- <span style="color: red">error mess</span> -->
            </div>

            <!-- 
            <div
              class="wrap-input100 validate-input m-b-36"
              data-validate="Username is required"
            >
              <input
                class="input100"
                type="text"
                formControlName="name"
                placeholder="Name"
                required
              />
              <span *ngIf="isValid('Name', 'name')" class="focus-input100 err">{{
                error.name
              }}</span>
              
            </div> -->

            <!-- <div
              class="wrap-input100 validate-input m-b-36"
              data-validate="Username is required"
            >
              <input
                class="input100"
                type="text"
                [owlDateTime]="dt1"
                [owlDateTimeTrigger]="dt1"
                formControlName="date_of_birth"
                placeholder="Date Of Birth"
              />
              <owl-date-time #dt1 [pickerType]="'calendar'"></owl-date-time>
              <span
                class="focus-input100"
                *ngIf="isValid('Date of Birth', 'date_of_birth')"
                >{{ error.date_of_birth }}</span
              >
            </div> -->
            <!-- <div
              class="wrap-input100 validate-input m-b-36"
              data-validate="Username is required"
            >
              <input
                class="input100"
                type="text"
                formControlName="email"
                placeholder="Email"
              />
              <span class="focus-input100 err" *ngIf="isValid('Email', 'email')">{{
                error.email
              }}</span>
            </div> -->
            <!-- <div
              class="wrap-input100 validate-input m-b-36"
              data-validate="Username is required"
            >
              <input
                class="input100"
                type="password"
                formControlName="password"
                placeholder="Password"
              />
              <span
                class="focus-input100 err"
                *ngIf="isValid('Password', 'password')"
                >{{ error.password }}</span
              >
            </div> -->

            <!-- <div
              class="wrap-input100 validate-input m-b-36"
              data-validate="Username is required"
            >
              <input
                class="input100"
                type="text"
                formControlName="confirm_password"
                placeholder="Confirm Password
                  "
              />
              <span
                class="focus-input100 err"
                *ngIf="isValid('Confirm Password', 'confirm_password', true)"
                >{{ error.confirm_password }}</span
              >
            </div> -->
            <!-- 
            <div
              class="wrap-input100 validate-input m-b-36"
              data-validate="Username is required"
            >
              <input
                class="input100"
                type="text"
                formControlName="referrer_code"
                placeholder="Referral Code"
              />
              <span class="focus-input100"></span>
            </div> -->
            <div class="">
              <recaptcha formControlName="myRecaptcha"></recaptcha>
              <span
                style="color: red"
                *ngIf="isValid('Captcha', 'myRecaptcha')"
                >{{ error.myRecaptcha }}</span
              >
            </div>

            <div class="container-login100-form-btn">
              <br />
              <button
                class="login100-form-btn"
                style="margin-top: 20px;"
                (click)="registerOnline(registerForm.value)"
              >
                REGISTER
              </button>
            </div>
          </form>
        </div>
        <div class="wrap-login101 p-l-85 p-r-85 p-t-55 p-b-55">
          <div
            class="d-none d-sm-block d-xl-none"
            style="text-align: center; text-align: center; color: #fff; font-size: 12px;"
          >
            Don't have Vsure app installed on your phone?
          </div>

          <p class="d-none d-sm-block d-xl-none">
            <a
              href="https://itunes.apple.com/my/app/vsure-life-on-demand-insurance/id1460213328?mt=8"
              target="_blank"
              ><img src="../../assets/img/ios.svg" width="200"
            /></a>
            <a
              href="https://play.google.com/store/apps/details?id=com.vsure.live"
              target="_blank"
              ><img src="../../assets/img/ard.svg" width="200"
            /></a>
          </p>
        </div>
        <div
          class="d-block d-sm-none"
          style=" font-size: 12px; margin-top: 10px; text-align: center; text-align: center; color: #fff; font-size: 12px;"
        >
          Don't have Vsure app installed on your phone?
        </div>

        <p class="d-block d-sm-none">
          <a
            href="https://itunes.apple.com/my/app/vsure-life-on-demand-insurance/id1460213328?mt=8"
            target="_blank"
            ><img src="../../assets/img/ios.svg" width="200"
          /></a>
          <a
            href="https://play.google.com/store/apps/details?id=com.vsure.live"
            target="_blank"
            ><img src="../../assets/img/ard.svg" width="200"
          /></a>
        </p>
      </div>
    </div>

    <script src="../../assets/js/main.js"></script>
    <!-- <script src='https://www.google.com/recaptcha/api.js'></script> -->
  </body>
</html>
