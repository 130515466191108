<header class="vsure-header">
  <div class="container">
    <div class="row">
      <div class="col-xs-2 col-sm-4 hidden-md hidden-lg">
        <div class="burger-menu">
          <a
            href="javascript:void(0)"
            data-toggle="collapse"
            data-target="#vsure-navbar"
            onClick="sendEvent('Menu', 'Click', 'Burger menu');"
          >
            <i class="fa fa-bars" aria-hidden="true"></i>
          </a>
        </div>
      </div>
      <div class="col-md-5 hidden-xs hidden-sm">
        <ul class="top-left-nav top-nav">
          <li>
            <a
              class="smooth-scroll"
              href="#about"
              onClick="sendEvent('Menu', 'Click', 'About Vsure');"
            >
              About
            </a>
          </li>
          <li>
            <a
              class="smooth-scroll"
              href="#works"
              onClick="sendEvent('Menu', 'Click', 'works');"
            >
              How it Works
            </a>
          </li>
          <li>
            <a
              class="smooth-scroll"
              href="#offer"
              onClick="sendEvent('Burger-Menu', 'Click', 'Blog');"
              target="_blank"
            >
              What We Offer
            </a>
          </li>
          <li>
            <a
              class="smooth-scroll"
              href="#partners"
              onClick="sendEvent('Menu', 'Click', 'Team');"
            >
              Partners
            </a>
          </li>
          <!-- <li>
                        <a
                            class="smooth-scroll"
                            href="#buynow"
                            onClick="sendEvent('Menu', 'Click', 'Team');"
                        >
                            Buy Now
                        </a>
                    </li> -->
          <li>
            <a
              class="smooth-scroll"
              href="/team"
              onClick="sendEvent('Menu', 'Click', 'Team');"
            >
              Team
            </a>
          </li>
          <li>
            <a
              class="smooth-scroll"
              href="#contact"
              onClick="sendEvent('Menu', 'Click', 'Team');"
              >Contact Us</a
            >
          </li>
        </ul>
      </div>
      <div
        class="col-xs-8 col-sm-4 col-md-2 text-center logo"
        style="padding-top:20px;"
      >
        <a
          href="index.html"
          onClick="ga('send', 'event', 'landing page | home', 'mainmenu', 'logo');"
        >
          <img
            src="assets/img/vsure_logo.svg"
            width="auto"
            height="64"
            class="black_logo"
          />
          <img
            src="assets/img/vsure_white.svg"
            width="auto"
            height="64"
            class="white_logo"
          />
        </a>
      </div>
      <div class="col-sm-4 col-md-5 hidden-xs">
        <ul class="top-nav">
          <li class="col-xs-1 pull-right">
            <a href="https://www.facebook.com/vsure.life/" target="_blank">
              <i class="fa fa-facebook"></i>
              <span class="sr-only">Facebook</span>
            </a>
          </li>
          <!-- <li class="col-xs-1 pull-right">
            <a href="#" target="_blank"><i class="fa fa-twitter"></i><span class="sr-only">Twitter</span></a>
          </li> -->
          <li class="col-xs-1 pull-right">
            <a
              href="https://www.linkedin.com/company/vsuredotcom"
              target="_blank"
            >
              <i class="fa fa-linkedin"></i>
              <span class="sr-only">LinkedIn</span>
            </a>
          </li>
          <!-- <li class="col-xs-1 pull-right">
            <a href="https://www.instagram.com/vsure.life/" target="_blank"><i class="fa fa-instagram"></i><span class="sr-only">Instagram</span></a>
          </li> -->
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 hidden-md hidden-lg">
        <div class="collapsing vsure-navbar" id="vsure-navbar">
          <div class="container">
            <ul class="col-xs-12">
              <li>
                <a
                  class="smooth-scroll"
                  href="#about"
                  onClick="sendEvent('Burger-Menu', 'Click', 'About Vsure');"
                >
                  About
                </a>
              </li>
              <li>
                <a
                  class="smooth-scroll"
                  href="#works"
                  onClick="sendEvent('Menu', 'Click', 'works');"
                >
                  How it Works
                </a>
              </li>

              <li>
                <a
                  class="smooth-scroll"
                  href="#offer"
                  onClick="sendEvent('Burger-Menu', 'Click', 'Blog');"
                  target="_blank"
                >
                  What We Offer
                </a>
              </li>

              <li>
                <a
                  class="smooth-scroll"
                  href="#partners"
                  onClick="sendEvent('Menu', 'Click', 'Team');"
                >
                  Partners
                </a>
              </li>
              <li>
                <a
                  class="smooth-scroll"
                  href="#buynow"
                  onClick="sendEvent('Menu', 'Click', 'Team');"
                >
                  Buy Now
                </a>
              </li>
              <li>
                <a
                  class="smooth-scroll"
                  href="/team"
                  onClick="sendEvent('Menu', 'Click', 'Team');"
                >
                  Team
                </a>
              </li>
              <li>
                <a
                  class="smooth-scroll"
                  href="/news"
                  onClick="sendEvent('Menu', 'Click', 'Team');"
                >
                  News & Events
                </a>
              </li>
            </ul>
            <ul class="col-xs-12 hidden-sm sub-nav">
              <li>
                <a
                  class="smooth-scroll"
                  target="”_blank”"
                  href="/assets/pdf/privacy-policy.pdf"
                  onClick="sendEvent('Footer', 'Click', 'Privacy Policy');"
                >
                  Privacy Policy
                </a>
              </li>
            </ul>
            <div class="social col-xs-12 hidden-sm">
              <h6>Follow us on Social Media</h6>
              <ul class>
                <li class="col-xs-1 pull-right">
                  <a
                    href="https://www.facebook.com/vsure.life/"
                    target="_blank"
                  >
                    <i class="fa fa-facebook"></i>
                    <span class="sr-only">Facebook</span>
                  </a>
                </li>
                <!-- <li class="col-xs-1 pull-right">
                  <a href="#" target="_blank"><i class="fa fa-twitter"></i><span class="sr-only">Twitter</span></a>
                </li> -->
                <li class="col-xs-1 pull-right">
                  <a
                    href="https://www.linkedin.com/company/vsuredotcom"
                    target="_blank"
                  >
                    <i class="fa fa-linkedin"></i>
                    <span class="sr-only">LinkedIn</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
<style>
  .full-width-image-2 {
    background: url(assets/img/medical2.jpg) no-repeat center center;
    background-size: cover;
    text-align: center;
    height: 300px;
  }
</style>

<body>
  <div class="full-width-image-2"></div>

  <section class="bg-alternate ways" id="partners">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <h2 style="color:#ef5193">Travel Care</h2>
        </div>
      </div>
      <div class="row way-holder ">
        <div class="col-md-4 col-xs-12 way">
          <div style="border: 1px solid #8c8b8b; padding-top: 10px;">
            <img
              src="assets/img/alliaza.png"
              class="rounded float-left"
              alt="Clear"
              onClick="sendEvent('ABetterWay', 'Click', 'Clear');"
            />
            <h3
              class="title margin-top-15"
              onClick="sendEvent('ABetterWay', 'Click', 'Clear');"
            >
              Travel Care
            </h3>
            <div class="content-holder">
              <div class="details content margin-top-20">
                <h3
                  style="text-align: left;    line-height: 23px; font-size: 14px;"
                >
                  A travel insurance that will protect you and your family
                  during your holidays, overseas or domestic which includes:
                </h3>
              </div>
              <div style="margin-top: 290px; "></div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-xs-12 way">
          <div style="border: 1px solid #8c8b8b;">
            <h3
              class="title margin-top-15"
              onClick="sendEvent('ABetterWay', 'Click', 'Fair');"
            >
              Key Benefits
            </h3>
            <div class="content-holder">
              <div class="details content margin-top-20">
                <ul style="text-align: left">
                  <p>Accidental death and permanent disablement</p>
                  <p>Towing Distance and Car Assistance</p>
                  <p>
                    Medical expenses
                  </p>
                  <p>
                    Travel or luggage delay
                  </p>
                  <p>
                    Emergency Medical Evacuation and Repatriation Programme
                  </p>
                </ul>
              </div>
              <div style="margin-top: 225px;"></div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-xs-12 way">
          <div style="border: 1px solid #8c8b8b; padding: 10px;">
            <h3
              class="title margin-top-15"
              onClick="sendEvent('ABetterWay', 'Click', 'Fair');"
            >
              Buy Now
            </h3>
            <br />
            <form
              [formGroup]="formGroup"
              (ngSubmit)="onSubmit(formGroup.value)"
              method="POST"
            >
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  formControlName="name"
                  placeholder="*Full Name"
                  required
                />
              </div>
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  id="email"
                  formControlName="email"
                  placeholder=" *Email"
                  required
                />
              </div>
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  id="referral"
                  formControlName="referral"
                  placeholder="Referral Code - Optional"
                  [readonly]="readonly"
                />
              </div>
              <recaptcha formControlName="myRecaptcha"></recaptcha>

              <div class="form-check" style="text-align: left">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="exampleCheck1"
                  [checked]="checkBoxStatus"
                  (change)="checkBoxStatus = !checkBoxStatus"
                  required
                />
                <label class="form-check-label" for="exampleCheck1">
                  &nbsp; I agree to the
                  <a href="/assets/pdf/terms-of-use.pdf">Terms</a>
                  &
                  <a href="/assets/pdf/privacy-policy.pdf">Privacy policies.</a>
                </label>
              </div>

              <input
                type="submit"
                [disabled]="formGroup.invalid || !checkBoxStatus"
                class="btn"
                onclick="window.open('https://getquote.allianz.com.my/travel-care-plus/get-info?utm_source=VSLIFE')"
                value="Submit"
              />
              <br />
              <small style="margin-top: 15px;"
                >This product is underwritten by</small
              >
              <br />
              <img style="margin-top: 10px;" src="assets/img/alliaza.png" />
            </form>
            <div style="margin-top: 25px; float: left"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="bg-alternate ways">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <!-- <h2 style="color:#ef5193; text-align: left">eLife Protector+
            </h2> -->
        </div>
      </div>

      <div class="contant" style="text-align: left">
        <h2>Product</h2>
        <span
          >Traveling should be a stress free and enjoyable experience. You
          should not worry about flight delays, cancellation, losing your
          passport or your luggage getting lost. Keep your mind at ease with
          Allianz Travel Care, the perfect travel companion to accompany you
          throughout your journey. Allianz Travel Care provides international
          medical coverage 24 hours a day! Don’t take off without it. </span
        ><br /><br />
        <span
          >For twenty-four (24) Worldwide Travel Emergency Assistance, you may
          contact Our Authorized Representative’s twenty-four emergency
          telephone number at +603-7628 3919 or +603-7965 3919. For more product
          info, click
          <a
            target="_blank"
            href="https://www.allianz.com.my/documents/144671/493140/PRO_065+Allianz+Travel+Care+Brochure+ENG+AZ0918+101018+R4+FA+LowRes.pdf/391693e5-3bc4-4ad4-ae0a-60221361fb47"
            style="color:#ef5193"
          >
            here.</a
          >
        </span>
        <br /><br />
        <h2>Benefit / Coverage</h2>

        <ol type="I">
          <li>Overseas and domestic travels for you and your family</li>
          <li>Accidental death and permanent disablement</li>
          <li>Medical expenses</li>
          <li>Travel or luggage delay</li>
          <li>Emergency Medical Evacuation and Repatriation Programme</li>
          <li>
            Understand more benefits at
            <a
              target="_blank"
              href="https://www.allianz.com.my/documents/144671/514262/PDS_Allianz+Travel+Care_BI_180901.pdf/33be1765-52aa-40e2-82c6-e71c3487a334"
              style="color:#ef5193"
              >here</a
            >
          </li>
        </ol>

        <br />
        <h2>Claim</h2>
        <ol>
          <li>Call 1300-22-5542 for assistance</li>
          <li>
            Download the
            <a href="https://www.allianz.com.my/general-insurance-claims-form"
              >Claim Forms.
            </a>

            <ol>
              <li>
                For Allianz Travel Care:
                <a
                  style="color:#ef5193"
                  target="_blank"
                  href="https://www.allianz.com.my/documents/144671/163291/Allianz+Travel+Care+Claim+Form.pdf/ce43b148-bcad-455b-8f1d-133fad3da384"
                >
                  Allianz Travel Care Claim Form
                </a>
              </li>
            </ol>
          </li>

          <li>Report to Allianz.</li>
          <li>Submit required document.</li>
        </ol>

        <br />

        <br />

        <span
          >Need more information? Call 1300-22-5542 or email to
          customer.service@allianz.com.my.
        </span>
        <!-- <p><a href="/life" style="color:#ef5193;">eLife Protector+ </a></p> -->
      </div>
    </div>
  </section>
  <app-footer></app-footer>
</body>
