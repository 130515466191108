<header class="vsure-header">
  <div class="container">
    <div class="row">
      <div class="col-xs-2 col-sm-4 hidden-md hidden-lg">
        <div class="burger-menu">
          <a href="javascript:void(0)" data-toggle="collapse" data-target="#vsure-navbar"
            onClick="sendEvent('Menu', 'Click', 'Burger menu');">
            <i class="fa fa-bars" aria-hidden="true"></i>
          </a>
        </div>
      </div>
      <div class="col-md-5 hidden-xs hidden-sm">
        <ul class="top-left-nav top-nav">
          <li>
            <a class="smooth-scroll" href="#about" onClick="sendEvent('Menu', 'Click', 'About Vsure');">
              About
            </a>
          </li>
          <li>
            <a class="smooth-scroll" href="#works" onClick="sendEvent('Menu', 'Click', 'works');">
              How it Works
            </a>
          </li>
          <li>
            <a class="smooth-scroll" href="#offer" onClick="sendEvent('Burger-Menu', 'Click', 'Blog');" target="_blank">
              What
              We Offer
            </a>
          </li>

          <li>
            <a class="smooth-scroll" href="#partners" onClick="sendEvent('Menu', 'Click', 'Team');">
              Partners
            </a>
          </li>
          <!-- <li>
                        <a
                            class="smooth-scroll"
                            href="#buynow"
                            onClick="sendEvent('Menu', 'Click', 'Team');"
                        >
                            Buy Now
                        </a>
                    </li> -->
          <li>
            <a class="smooth-scroll" href="/team" onClick="sendEvent('Menu', 'Click', 'Team');">
              Team
            </a>
          </li>
          <li>
            <a class="smooth-scroll" href="#contact" onClick="sendEvent('Menu', 'Click', 'Team');">
              Contact Us
            </a>
          </li>
        </ul>
      </div>
      <div class="col-xs-8 col-sm-4 col-md-2 text-center logo" style="padding-top:20px;">
        <a href="index.html" onClick="ga('send', 'event', 'landing page | home', 'mainmenu', 'logo');">
          <img src="assets/img/vsure_logo.svg" width="auto" height="64" class="black_logo">
          <img src="assets/img/vsure_white.svg" width="auto" height="64" class="white_logo">
        </a>
      </div>
      <div class="col-sm-4 col-md-5 hidden-xs">
        <ul class="top-nav">
          <li class="col-xs-1 pull-right">
            <a href="https://www.facebook.com/vsure.life/" target="_blank">
              <i class="fa fa-facebook"></i>
              <span class="sr-only">Facebook</span>
            </a>
          </li>
          <!-- <li class="col-xs-1 pull-right">
            <a href="#" target="_blank"><i class="fa fa-twitter"></i><span class="sr-only">Twitter</span></a>
          </li> -->
          <li class="col-xs-1 pull-right">
            <a href="https://www.linkedin.com/company/vsuredotcom" target="_blank">
              <i class="fa fa-linkedin"></i>
              <span class="sr-only">LinkedIn</span>
            </a>
          </li>
          <!-- <li class="col-xs-1 pull-right">
            <a href="https://www.instagram.com/vsure.life/" target="_blank"><i class="fa fa-instagram"></i><span class="sr-only">Instagram</span></a>
          </li> -->
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 hidden-md hidden-lg">
        <div class="collapsing vsure-navbar" id="vsure-navbar">
          <div class="container">
            <ul class="col-xs-12">
              <li>
                <a class="smooth-scroll" href="#about" onClick="sendEvent('Burger-Menu', 'Click', 'About Vsure');">
                  About
                </a>
              </li>
              <li>
                <a class="smooth-scroll" href="#works" onClick="sendEvent('Menu', 'Click', 'works');">
                  How it Works
                </a>
              </li>
              <li>
                <a class="smooth-scroll" href="#team" onClick="sendEvent('Burger-Menu', 'Click', 'Team');">
                  Team
                </a>
              </li>
              <li>
                <a class="smooth-scroll" href="#offer" onClick="sendEvent('Burger-Menu', 'Click', 'Blog');"
                  target="_blank">
                  What
                  We Offer
                </a>
              </li>
              <li>
                <a class="smooth-scroll" href="#partners" onClick="sendEvent('Menu', 'Click', 'Team');">
                  Partners
                </a>
              </li>
              <li>
                <a class="smooth-scroll" href="#buynow" onClick="sendEvent('Menu', 'Click', 'Team');">
                  Buy Now
                </a>
              </li>
              <li>
                <a class="smooth-scroll" href="/team" onClick="sendEvent('Menu', 'Click', 'Team');">
                  Team
                </a>
              </li>
            </ul>
            <ul class="col-xs-12 hidden-sm sub-nav">
              <li>
                <a class="smooth-scroll" target=”_blank” href="/assets/pdf/privacy-policy.pdf"
                  onClick="sendEvent('Footer', 'Click', 'Privacy Policy');">
                  Privacy
                  Policy
                </a>
              </li>
            </ul>
            <div class="social col-xs-12 hidden-sm">
              <h6>Follow us on Social Media</h6>
              <ul class>
                <li class="col-xs-1 pull-right">
                  <a href="https://www.facebook.com/vsure.life/" target="_blank">
                    <i class="fa fa-facebook"></i>
                    <span class="sr-only">Facebook</span>
                  </a>
                </li>
                <!-- <li class="col-xs-1 pull-right">
                  <a href="#" target="_blank"><i class="fa fa-twitter"></i><span class="sr-only">Twitter</span></a>
                </li> -->
                <li class="col-xs-1 pull-right">
                  <a href="https://www.linkedin.com/company/vsuredotcom" target="_blank">
                    <i class="fa fa-linkedin"></i>
                    <span class="sr-only">LinkedIn</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

<style>
  /*********************** Demo - 1 *******************/
  .box1 img,
  .box1:after,
  .box1:before {
    width: 100%;
    transition: all .3s ease 0s
  }

  .box1 .icon,
  .box2,
  .box3,
  .box4,
  .box5 .icon li a {
    text-align: center
  }

  .box10:after,
  .box10:before,
  .box1:after,
  .box1:before,
  .box2 .inner-content:after,
  .box3:after,
  .box3:before,
  .box4:before,
  .box5:after,
  .box5:before,
  .box6:after,
  .box7:after,
  .box7:before {
    content: ""
  }

  .box1,
  .box11,
  .box12,
  .box13,
  .box14,
  .box16,
  .box17,
  .box18,
  .box2,
  .box20,
  .box21,
  .box3,
  .box4,
  .box5,
  .box5 .icon li a,
  .box6,
  .box7,
  .box8 {
    overflow: hidden
  }

  .box1 .title,
  .box10 .title,
  .box4 .title,
  .box7 .title {
    letter-spacing: 1px
  }

  .box3 .post,
  .box4 .post,
  .box5 .post,
  .box7 .post {
    font-style: italic
  }

  body {
    background-color: #f1f1f2
  }

  .mt-30 {
    margin-top: 30px
  }

  .mt-40 {
    margin-top: 40px
  }

  .mb-30 {
    margin-bottom: 30px
  }

  .box1 .icon,
  .box1 .title {
    margin: 0;
    position: absolute
  }

  .box1 {
    box-shadow: 0 0 3px rgba(0, 0, 0, .3);
    position: relative
  }

  .box1:after,
  .box1:before {
    height: 50%;
    background: rgba(0, 0, 0, .5);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    transform-origin: 100% 0;
    transform: rotateZ(90deg)
  }

  .box1:after {
    top: auto;
    bottom: 0;
    transform-origin: 0 100%
  }

  .box1:hover:after,
  .box1:hover:before {
    transform: rotateZ(0)
  }

  .box1 img {
    height: auto;
    transform: scale(1) rotate(0)
  }

  .box1:hover img {
    filter: sepia(80%);
    transform: scale(1.3) rotate(10deg)
  }

  .box1 .title {
    font-size: 19px;
    font-weight: 600;
    color: #fff;
    text-transform: uppercase;
    text-shadow: 0 0 1px #004cbf;
    bottom: 10px;
    left: 10px;
    opacity: 0;
    z-index: 2;
    transform: scale(0);
    transition: all .5s ease .2s
  }

  .box1:hover .title {
    opacity: 1;
    transform: scale(1)
  }

  .box1 .icon {
    padding: 7px 5px;
    list-style: none;
    background: #004cbf;
    border-radius: 0 0 0 10px;
    top: -100%;
    right: 0;
    z-index: 2;
    transition: all .3s ease .2s
  }

  .box1:hover .icon {
    top: 0
  }

  .box1 .icon li {
    display: block;
    margin: 10px 0
  }

  .box1 .icon li a {
    display: block;
    width: 35px;
    height: 35px;
    line-height: 35px;
    border-radius: 10px;
    font-size: 18px;
    color: #fff;
    transition: all .3s ease 0s
  }

  .box2 .icon li a,
  .box3 .icon a:hover,
  .box4 .icon li a:hover,
  .box5 .icon li a,
  .box6 .icon li a {
    border-radius: 50%
  }

  .box1 .icon li a:hover {
    color: #fff;
    box-shadow: 0 0 10px #000 inset, 0 0 0 3px #fff
  }

  @media only screen and (max-width:990px) {
    .box1 {
      margin-bottom: 30px
    }
  }

  /*********************** Demo - 2 *******************/
  .box2 {
    position: relative
  }

  .box2 img {
    width: 100%;
    height: auto
  }

  .box2 .box-content {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    transform: translate(-50%, -50%)
  }

  .box2 .box-content:after,
  .box2 .box-content:before,
  .box2:after,
  .box2:before {
    content: "";
    width: 80%;
    height: 80%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    transition: all .5s ease 0s
  }

  .box2:before {
    background: linear-gradient(45deg, rgba(0, 0, 0, .2) 49%, transparent 50%);
    left: -100%
  }

  .box2:after {
    background: linear-gradient(45deg, transparent 49%, rgba(0, 0, 0, .2) 50%);
    left: 160%
  }

  .box2 .box-content:after,
  .box2 .box-content:before {
    width: 65%;
    height: 65%;
    background: linear-gradient(45deg, rgba(0, 0, 0, .3) 49%, transparent 50%);
    left: -100%;
    transition-delay: .1s
  }

  .box1 .box-content:after {
    background: linear-gradient(45deg, transparent 49%, rgba(0, 0, 0, .3) 50%);
    left: 160%
  }

  .box2:hover .box-content:after,
  .box2:hover .box-content:before,
  .box2:hover:after,
  .box2:hover:before {
    left: 50%
  }

  .box2 .inner-content {
    width: 50%;
    height: 50%;
    color: #fff;
    padding: 40px 0;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    transform: translate(-50%, -50%) scale(0);
    transition: all .3s ease .2s
  }

  .box2 .inner-content:after {
    width: 100%;
    height: 100%;
    background: rgba(255, 79, 79, .8);
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -1;
    transform: translate(-50%, -50%) rotate(45deg)
  }

  .box2:hover .inner-content {
    transform: translate(-50%, -50%) scale(1);
    transition: all .3s ease 0s
  }

  .box2 .title {
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 5px
  }

  .box2 .post {
    display: block;
    font-size: 14px;
    text-transform: capitalize;
    margin-bottom: 7px
  }

  .box10 .title,
  .box11 .title,
  .box4 .title,
  .box5 .title,
  .box6 .box-content,
  .box7 .title {
    text-transform: uppercase
  }

  .box2 .icon {
    padding: 0;
    margin: 0;
    list-style: none;
    transform: rotateY(360deg) scale(0);
    transition: all .3s ease 0s
  }

  .box2:hover .icon {
    transform: rotateY(0) scale(1)
  }

  .box2 .icon li {
    display: inline-block;
    margin: 0 3px
  }

  .box2 .icon li a {
    display: block;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: #fff;
    font-size: 16px;
    color: #505050;
    transition: all .3s ease 0s
  }

  .box2 .icon li a:hover {
    box-shadow: 0 0 0 5px rgba(0, 0, 0, .5);
    background: #fff;
    color: #ff4f4f
  }

  @media only screen and (max-width:990px) {
    .box2 {
      margin-bottom: 30px
    }
  }

  @media only screen and (max-width:320px) {
    .box2 .inner-content {
      padding: 25px 0
    }

    .box2 .title {
      font-size: 16px
    }
  }

  /*********************** Demo - 3 *******************/
  .box3 {
    box-shadow: 0 0 3px rgba(0, 0, 0, .3);
    position: relative
  }

  .box3 .box-content,
  .box3:after,
  .box3:before {
    position: absolute;
    left: 7%;
    right: 7%;
    transition: all .3s
  }

  .box3:after,
  .box3:before {
    display: block;
    background: rgba(0, 0, 0, .3);
    top: 10%;
    bottom: 10%;
    z-index: 1;
    transform: scale(0, 1)
  }

  .box3:after {
    top: 10.8%;
    bottom: 10.8%;
    transform: scale(1, 0)
  }

  .box3:hover:after,
  .box3:hover:before {
    transform: scale(1);
    animation: animate 1.5s
  }

  .box3:hover:before {
    border-top: 3px solid #fff;
    border-bottom: 3px solid #fff
  }

  .box:hover:after {
    border-left: 3px solid #fff;
    border-right: 3px solid #fff
  }

  .box img {
    width: 100%;
    height: auto;
    transition: all .3s
  }

  .box3:hover img {
    transform: scale(1.2);
    filter: blur(5px);
    -moz-filter: blur(5px);
    -webkit-filter: blur(5px)
  }

  .box3 .box-content {
    padding: 30px 10px;
    top: 10%;
    bottom: 10%;
    opacity: 0;
    z-index: 2
  }

  .box3:hover .box-content {
    box-shadow: 0 0 0 35px rgba(255, 255, 255, .3);
    opacity: 1;
    transition: all .3s
  }

  .box3 .title {
    font-size: 24px;
    font-weight: 600;
    color: #88c425;
    margin: 0 0 5px
  }

  .box3 .post {
    display: block;
    margin: 0 0 5px;
    font-size: 14px;
    color: rgba(255, 255, 255, .8)
  }

  .box3 .description {
    font-size: 14px;
    color: #fff;
    margin: 0 0 20px
  }

  .box3 .icon {
    padding: 0;
    margin: 0;
    list-style: none
  }

  .box3 .icon li {
    display: inline-block;
    margin: 0 10px 0 0
  }

  .box3 .icon li a {
    display: block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    color: #fff;
    background: #88c425;
    transition: all .5s
  }

  .box3 .icon a:hover {
    text-decoration: none;
    animation: animate-hover .5s;
    transition: all .3s
  }

  @keyframes animate {

    0%,
    100% {
      opacity: 1
    }
  }

  @keyframes animate-hover {
    0% {
      box-shadow: 0 0 0 10px rgba(255, 255, 255, .3)
    }

    50% {
      box-shadow: 0 0 0 5px rgba(255, 255, 255, .3)
    }

    100% {
      box-shadow: 0 0 0 0 rgba(255, 255, 255, .3)
    }
  }

  .box10,
  .box11,
  .box12,
  .box14,
  .box4,
  .box5,
  .box6,
  .box7,
  .box9 {
    box-shadow: 0 0 3px rgba(0, 0, 0, .3)
  }

  @media only screen and (max-width:990px) {
    .box3 {
      margin-bottom: 30px
    }

    .box3 .box-content {
      padding: 10px
    }

    .box3 .description {
      margin-bottom: 10px
    }
  }

  @media only screen and (max-width:479px) {
    .box3 .title {
      margin: 0
    }
  }

  /*********************** Demo - 4 *******************/
  .box4 {
    position: relative
  }

  .box4:before {
    width: 0;
    height: 200%;
    background: rgba(0, 0, 0, .5);
    position: absolute;
    top: 0;
    left: -250px;
    bottom: 0;
    transform: skewX(-36deg);
    transition: all .5s ease 0s
  }

  .box4:hover:before {
    width: 200%
  }

  .box4 img {
    width: 100%;
    height: auto
  }

  .box4 .box-content {
    width: 100%;
    height: 100%;
    padding-top: 20%;
    position: absolute;
    top: 0;
    left: 0;
    transform: scale(0);
    transition: all .3s ease 0s
  }

  .box4 .icon,
  .box5 .icon {
    list-style: none;
    padding: 0
  }

  .box4:hover .box-content {
    transform: scale(1)
  }

  .box4 .title {
    font-size: 22px;
    font-weight: 700;
    color: #fff;
    margin: 0 0 10px
  }

  .box4 .post {
    display: block;
    font-size: 15px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 20px
  }

  .box4 .icon {
    margin: 0
  }

  .box4 .icon li {
    display: inline-block
  }

  .box4 .icon li a {
    display: block;
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 20px;
    background: #fff;
    color: #ee4266;
    margin-right: 10px;
    transition: all .3s ease 0s
  }

  .box5 .icon,
  .box5 .icon li {
    display: inline-block
  }

  @media only screen and (max-width:990px) {
    .box4 {
      margin-bottom: 30px
    }
  }

  @media only screen and (max-width:767px) {
    .box4:before {
      left: -400px
    }

    .box4:hover:before {
      width: 300%
    }
  }

  .g-recaptcha {
    width: 268 px !important;
  }

  /*********************** Demo - 5 *******************/
  .box5 {
    background: #444;
    position: relative
  }

  .box5:after,
  .box5:before {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #10a5b8;
    position: absolute;
    top: -80px;
    left: 15px;
    opacity: 0;
    z-index: 1;
    transition: all .35s ease
  }

  .box5:after {
    top: auto;
    left: auto;
    bottom: -80px;
    right: 15px
  }

  .box5:hover:after,
  .box5:hover:before {
    opacity: .75;
    transform: scale(8);
    transition-delay: .15s
  }

  .box5 img {
    width: 100%;
    height: auto;
    transition: all .35s ease-out 0s
  }

  .box5:hover img {
    opacity: .4
  }

  .box5 .icon {
    margin: 0;
    position: absolute;
    bottom: 15px;
    right: 15px;
    z-index: 2;
    transform: scale(0);
    transition: all .35s ease-out
  }

  .box5:hover .icon {
    transform: scale(1);
    transition-delay: .15s
  }

  .box5 .icon li a {
    display: block;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: #fff;
    font-size: 18px;
    color: #444;
    margin-right: 10px;
    position: relative;
    transition: all .5s ease 0s
  }

  .box5 .icon li a:hover {
    background: #444;
    color: #fff
  }

  .box5 .box-content {
    padding: 20px 15px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1
  }

  .box5 .title {
    font-size: 20px;
    font-weight: 800;
    color: #fff;
    margin: 0 0 5px;
    opacity: 0;
    transform: translate(-20px, -20px);
    transition: all .35s ease-out
  }

  .box5:hover .title {
    opacity: 1;
    transform: translate(0, 0);
    transition-delay: .15s
  }

  .box5 .post {
    display: inline-block;
    font-size: 16px;
    color: #fff;
    opacity: 0;
    transform: translate(-20px, -20px);
    transition: all .35s ease-out
  }

  .box5:hover .post {
    opacity: 1;
    transform: translate(0, 0);
    transition-delay: .15s
  }

  .box6 .title,
  .box6 img,
  .box6:after {
    transition: all .35s ease 0s
  }

  @media only screen and (max-width:990px) {
    .box5 {
      margin-bottom: 30px
    }
  }

  /*********************** Demo - 6 *******************/
  .box6 {
    background: #000;
    position: relative
  }

  .box6:after {
    background: rgba(0, 0, 0, .7);
    position: absolute;
    top: 0;
    left: -30%;
    bottom: 0;
    right: 70%;
    transform: skew(20deg) translateX(-75%)
  }

  .box6:hover:after {
    transform: skew(20deg) translateX(0)
  }

  .box6 img {
    width: 100%;
    height: auto
  }

  .box6:hover img {
    opacity: .5
  }

  .box6 .box-content {
    padding: 20px;
    text-align: right;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1
  }

  .box6 .icon,
  .box7 .icon {
    padding: 0;
    list-style: none
  }

  .box10 .icon li a,
  .box11,
  .box12,
  .box13,
  .box14,
  .box6 .icon li a,
  .box7,
  .box7 .icon li a,
  .box8,
  .box8 .icon li a {
    text-align: center
  }

  .box6 .title {
    font-size: 20px;
    font-weight: 900;
    color: #fff;
    margin: 0 0 10px;
    transform: scale(0)
  }

  .box6:hover .title {
    transform: scale(1)
  }

  .box6 .icon li,
  .box6 .post {
    opacity: 0;
    transform: translateX(40px);
    transition: all .35s ease 0s
  }

  .box6 .post {
    display: block;
    font-size: 14px;
    color: #fff;
    margin-bottom: 5px
  }

  .box6 .icon {
    display: inline-block;
    margin: 0
  }

  .box6:hover .icon li,
  .box6:hover .post {
    opacity: 1;
    transform: translateX(0)
  }

  .box6:hover .icon li:first-child {
    transition-delay: .1s
  }

  .box6:hover .icon li:nth-child(2) {
    transition-delay: .2s
  }

  .box6 .icon li a {
    display: block;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: #fff;
    font-size: 18px;
    color: #605f5f;
    margin-bottom: 5px;
    transition: all .35s ease
  }

  .box6 .icon li a:hover {
    background: #605f5f;
    color: #fff
  }

  @media only screen and (max-width:990px) {
    .box6 {
      margin-bottom: 30px
    }
  }

  /*********************** Demo - 7 *******************/
  .box7 {
    position: relative
  }

  .box7:after,
  .box7:before {
    width: 100%;
    height: 100%;
    background: rgba(11, 33, 47, .9);
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: all .5s ease 0s
  }

  .box7:after {
    background: rgba(255, 255, 255, .3);
    border: 2px solid #0dab76;
    top: 0;
    left: 170%;
    opacity: 1;
    z-index: 1;
    transform: skewX(45deg);
    transition: all 1s ease 0s
  }

  .box7:hover:before {
    opacity: 1
  }

  .box7:hover:after {
    left: -170%
  }

  .box7 img {
    width: 100%;
    height: auto
  }

  .box7 .box-content {
    width: 100%;
    position: absolute;
    bottom: -100%;
    left: 0;
    transition: all .5s ease 0s
  }

  .box7:hover .box-content {
    bottom: 30%
  }

  .box7 .title {
    display: block;
    font-size: 22px;
    font-weight: 700;
    color: #fff;
    margin: 0 0 10px
  }

  .box7 .post {
    display: block;
    font-size: 15px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 10px
  }

  .box7 .icon {
    margin: 0
  }

  .box7 .icon li {
    display: inline-block
  }

  .box7 .icon li a {
    display: block;
    width: 35px;
    height: 35px;
    line-height: 35px;
    border-radius: 50%;
    background: #0dab76;
    font-size: 18px;
    color: #fff;
    margin-right: 10px;
    transition: all .5s ease 0s
  }

  .box8 .icon li,
  .box8 .title {
    display: inline-block
  }

  .box7 .icon li a:hover {
    transform: rotate(360deg)
  }

  @media only screen and (max-width:990px) {
    .box {
      margin-bottom: 30px
    }
  }

  /*********************** Demo - 8 *******************/
  .box8 .icon,
  .box8 .title {
    margin: 0;
    position: absolute
  }

  .box8 {
    box-shadow: 0 0 3px rgba(0, 0, 0, .3);
    position: relative
  }

  .box8 img {
    width: 100%;
    height: auto
  }

  .box8 .box-content {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .6);
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    transform: perspective(400px) rotateX(-90deg);
    transform-origin: center top 0;
    transition: all .5s ease 0s
  }

  .box8 .icon li a,
  .box8 .title {
    background: #ef4050;
    font-size: 20px;
    color: #fff
  }

  .box8:hover .box-content {
    opacity: 1;
    transform: perspective(400px) rotateX(0)
  }

  .box8 .title {
    padding: 5px 7px;
    border-radius: 5px;
    font-weight: 600;
    bottom: 20px;
    left: 20px;
    transition: all .9s ease 0s
  }

  .box8 .icon li a,
  .box9 .box-content,
  .box9 .icon li,
  .box9 img {
    transition: all .35s ease 0s
  }

  .box8:hover .title {
    bottom: -40px
  }

  .box8 .icon {
    list-style: none;
    padding: 0;
    top: 42%;
    left: 0;
    right: 0
  }

  .box8 .icon li a {
    display: block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
    margin-right: 7px
  }

  .box9 .icon,
  .box9 .title {
    width: 100%;
    font-size: 22px
  }

  .box8 .icon li a:hover {
    background: #fff;
    color: #000
  }

  @media only screen and (max-width:990px) {
    .box8 {
      margin-bottom: 20px
    }
  }

  /*********************** Demo - 9 *******************/
  .box9 {
    background: #000;
    text-align: center;
    position: relative
  }

  .box9 img {
    width: 100%;
    height: auto
  }

  .box9:hover img {
    opacity: .5
  }

  .box9 .box-content {
    padding: 30px 10px 30px 0;
    background: rgba(0, 0, 0, .65);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0
  }

  .box9:hover .box-content {
    top: 10px;
    left: 10px;
    bottom: 10px;
    right: 10px;
    opacity: 1
  }

  .box9 .title {
    font-weight: 700;
    color: #fff;
    line-height: 17px;
    margin: 5px 0;
    position: absolute;
    bottom: 55%
  }

  .box10 .icon li a,
  .box9 .icon li a {
    line-height: 35px;
    border-radius: 50%
  }

  .box9 .icon {
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 50%
  }

  .box9 .icon li {
    display: inline-block;
    opacity: 0;
    transform: translateY(40px)
  }

  .box9:hover .icon li {
    opacity: 1;
    transform: translateY(0)
  }

  .box9:hover .icon li:first-child {
    transition-delay: .1s
  }

  .box9:hover .icon li:nth-child(2) {
    transition-delay: .2s
  }

  .box9 .icon li a {
    display: block;
    width: 35px;
    height: 35px;
    background: #f39c12;
    font-size: 20px;
    color: #000;
    margin-right: 5px;
    transition: all .35s ease 0s
  }

  .box9 .icon a:hover {
    background: #fff
  }

  @media only screen and (max-width:990px) {
    .box9 {
      margin-bottom: 20px
    }
  }

  /*********************** Demo - 10 *******************/
  .box10 {
    background: #000;
    overflow: hidden;
    position: relative
  }

  .box10:after,
  .box10:before {
    border-width: 0 180px 140px;
    border-style: solid;
    border-color: transparent transparent #fe6cab;
    position: absolute;
    bottom: 0;
    left: 50%;
    z-index: 1;
    transform: translate(-55%, 100%);
    transition: all .5s 0s cubic-bezier(.6, -.28, .735, .045)
  }

  .box10:after {
    border-width: 150px 250px;
    border-color: transparent #cb3a79 #fe6cab #cb3a79;
    top: 25%;
    transform: translate(-60%, 100%)
  }

  .box10:hover:before {
    transition: all .4s 0s cubic-bezier(.175, .885, .32, 1.275);
    transform: translate(-55%, 0);
    transition-delay: .2s
  }

  .box10:hover:after {
    transition: all .4s 0s cubic-bezier(.175, .885, .32, 1.275);
    transform: translate(-60%, 0);
    transition-delay: 0s
  }

  .box10 img {
    width: 100%;
    height: auto;
    opacity: 1;
    transform: scale(1.1) translateY(3%);
    transition: all .5s ease 0s
  }

  .box10:hover img {
    transform: scale(1.1) translateY(-3%);
    opacity: .4
  }

  .box10 .box-content {
    padding: 20px 0 0 20px;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 2;
    transform: translateY(20%);
    transition: all .5s ease 0s
  }

  .box10:hover .box-content {
    opacity: 1;
    transform: translate(0);
    transition-delay: .1s
  }

  .box10 .title {
    font-size: 23px;
    font-weight: 800;
    color: #fff
  }

  .box10 .icon {
    padding: 0;
    margin: 0;
    list-style: none
  }

  .box10 .icon li {
    display: inline-block
  }

  .box10 .icon li a {
    display: block;
    width: 35px;
    height: 35px;
    background: #fe6cab;
    font-size: 18px;
    color: #fff;
    margin-right: 10px;
    transition: all .3s ease 0s
  }

  .box11 .icon li,
  .box11 .icon li a,
  .box11 .title,
  .box12 .icon li,
  .box12 .title {
    display: inline-block
  }

  .box10 .icon li a:hover {
    transform: rotate(360deg)
  }

  @media only screen and (max-width:990px) {
    .box10 {
      margin-bottom: 20px
    }
  }

  @media only screen and (max-width:767px) and (min-width:600px) {
    .box10:before {
      border-width: 0 250px 240px
    }

    .box10:after {
      border-width: 260px 460px
    }
  }

  @media only screen and (max-width:599px) and (min-width:430px) {
    .box:before {
      border-width: 0 220px 120px
    }

    .box10:after {
      border-width: 260px 460px
    }
  }

  /*********************** Demo - 11 *******************/
  .box11 {
    background: #000;
    color: #fff;
    position: relative
  }

  .box11:after,
  .box11:before {
    position: absolute;
    content: ""
  }

  .box11:before {
    width: 100%;
    height: 100%;
    background: #83437d;
    opacity: .5;
    top: 100%;
    left: 0;
    z-index: 1;
    transition: all .35s ease-in .3s
  }

  .box11:hover:before {
    top: 0;
    transition: all .35s ease-out 0s
  }

  .box11:after {
    bottom: 100%;
    left: 50%;
    border-width: 200px 200px 0;
    border-style: solid;
    border-color: #83437d transparent transparent;
    opacity: .9;
    transform: translateX(-50%);
    transition: all .35s ease-out 0s
  }

  .box11:hover .post,
  .box11:hover .title {
    transform: translate(0, -50%)
  }

  .box11:hover:after {
    bottom: 25%;
    transition: all .35s ease-in .2s
  }

  .box11 img {
    width: 100%;
    height: auto
  }

  .box11:hover img {
    opacity: .5
  }

  .box11 .icon,
  .box11 .post,
  .box11 .title {
    width: 100%;
    position: absolute;
    left: 0;
    opacity: 0;
    transition: all .35s ease .5s
  }

  .box11 .box-content {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1
  }

  .box11 .title {
    padding: 0 30px;
    margin: 0;
    font-weight: 300;
    top: 45%
  }

  .box11 .post,
  .box14 .post {
    text-transform: capitalize
  }

  .box11 .post {
    font-size: 15px;
    top: 57%
  }

  .box11 .icon {
    padding: 0;
    margin: 0;
    list-style: none;
    bottom: 5px
  }

  .box11 .icon li a {
    width: 40px;
    height: 40px;
    line-height: 40px;
    padding: 0 5px;
    font-size: 20px;
    color: #fff;
    opacity: .7;
    transition: all .35s ease .5s
  }

  .box11 .icon li a:hover {
    opacity: 1;
    transition: all .35s ease
  }

  .box11:hover .icon,
  .box11:hover .post,
  .box11:hover .title {
    opacity: 1
  }

  @media only screen and (max-width:990px) {
    .box11 {
      margin-bottom: 20px
    }
  }

  @media only screen and (max-width:767px) {
    .box11:after {
      border-width: 500px 500px 0
    }
  }

  /*********************** Demo - 12 *******************/
  .box12 {
    position: relative
  }

  .box12 .box-content,
  .box12:after {
    position: absolute;
    transition: all .3s ease 0s
  }

  .box12:after {
    content: "";
    width: 65%;
    background: rgba(28, 28, 28, .8);
    padding-bottom: 65%;
    opacity: 0;
    top: 50%;
    left: 50%;
    transform: rotate(0) translate(-50%, -50%);
    transform-origin: 0 0 0
  }

  .box12:hover:after {
    transform: rotate(-45deg) translate(-50%, -50%);
    opacity: 1
  }

  .box12 img {
    width: 100%;
    height: auto
  }

  .box12 .box-content {
    width: 100%;
    top: 35%;
    left: 0;
    opacity: 0;
    z-index: 1
  }

  .box12:hover .box-content {
    opacity: 1
  }

  .box12 .title {
    padding: 10px 0;
    color: #fff;
    margin: 0;
    border-top: 2px solid #fff;
    border-bottom: 2px solid #fff
  }

  .box12 .icon {
    padding: 0;
    margin: 12px 0 0;
    list-style: none
  }

  .box12 .icon li a {
    display: block;
    width: 35px;
    height: 35px;
    line-height: 35px;
    border-radius: 50%;
    border: 1px solid #fff;
    font-size: 18px;
    color: #fff;
    margin-right: 10px;
    transition: all .3s ease 0s
  }

  .box12 .icon li a:hover {
    border-radius: 0
  }

  @media only screen and (max-width:990px) {
    .box12 {
      margin-bottom: 20px
    }
  }

  /*********************** Demo - 13 *******************/
  .box13 {
    position: relative;
    transition: all .2s ease-out 0s
  }

  .box13 .box-content,
  .box13:after {
    position: absolute;
    left: 20px;
    right: 20px
  }

  .box13:after {
    content: "";
    display: block;
    background: #463f9f;
    top: 20px;
    bottom: 20px;
    opacity: 0;
    transform: rotate3d(-1, 1, 0, 100deg);
    transition: all .4s ease-in-out 0s
  }

  .box13:hover:after {
    opacity: .9;
    transform: rotate3d(0, 0, 0, 0deg)
  }

  .box13 img {
    width: 100%;
    height: auto
  }

  .box13 .box-content {
    top: 45%;
    opacity: 0;
    z-index: 1;
    -webkit-transform: translate(10%, -30%);
    transform: translate(10%, -30%);
    transition: all .2s ease-out 0s
  }

  .box13:hover .box-content {
    opacity: 1;
    transform: translate(0, -50%);
    transition-delay: .2s
  }

  .box13 .title {
    display: block;
    font-size: 22px;
    font-weight: 700;
    color: #39b54a;
    margin: 0 0 10px
  }

  .box13 .post {
    display: block;
    font-size: 15px;
    color: #f7f7b9;
    margin-bottom: 20px
  }

  .box13 .social {
    padding: 0;
    margin: 0;
    list-style: none
  }

  .box13 .social li {
    display: inline-block
  }

  .box13 .social li a {
    display: block;
    width: 35px;
    height: 35px;
    background: #f7f7b9;
    border-radius: 50%;
    font-size: 17px;
    color: #1b1462;
    line-height: 35px;
    margin-right: 5px;
    transition: all .4s ease-in-out 0s
  }

  .box14 .icon li,
  .box14 .post {
    display: inline-block
  }

  .box13 .social li a:hover {
    color: #f7f7b9;
    background: #39b54a
  }

  @media only screen and (max-width:990px) {
    .box13 {
      margin-bottom: 30px
    }
  }

  /*********************** Demo - 14 *******************/
  .box14 {
    position: relative
  }

  .box15,
  .box17,
  .box18 {
    box-shadow: 0 0 5px #7e7d7d;
    text-align: center
  }

  .box14:before {
    content: "";
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .5);
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: all .35s ease 0s
  }

  .box14:hover:before {
    opacity: 1
  }

  .box14 img {
    width: 100%;
    height: auto
  }

  .box14 .box-content {
    width: 90%;
    height: 90%;
    position: absolute;
    top: 5%;
    left: 5%
  }

  .box14 .box-content:after,
  .box14 .box-content:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    transition: all .7s ease 0s
  }

  .box14 .box-content:before {
    border-bottom: 1px solid rgba(255, 255, 255, .5);
    border-top: 1px solid rgba(255, 255, 255, .5);
    transform: scale(0, 1);
    transform-origin: 0 0 0
  }

  .box14 .box-content:after {
    border-left: 1px solid rgba(255, 255, 255, .5);
    border-right: 1px solid rgba(255, 255, 255, .5);
    transform: scale(1, 0);
    transform-origin: 100% 0 0
  }

  .box14:hover .box-content:after,
  .box14:hover .box-content:before {
    opacity: 1;
    transform: scale(1);
    transition-delay: .15s
  }

  .box14 .title {
    font-size: 21px;
    font-weight: 700;
    color: #fff;
    margin: 15px 0;
    opacity: 0;
    transform: translate3d(0, -50px, 0);
    transition: transform .5s ease 0s
  }

  .box14:hover .title {
    opacity: 1;
    transform: translate3d(0, 0, 0)
  }

  .box14 .post {
    font-size: 14px;
    color: #fff;
    padding: 10px;
    background: #d79719;
    opacity: 0;
    border-radius: 0 19px;
    transform: translate3d(0, -50px, 0);
    transition: all .7s ease 0s
  }

  .box14 .icon,
  .box15 .icon {
    padding: 0;
    list-style: none
  }

  .box14:hover .post {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition-delay: .15s
  }

  .box14 .icon {
    width: 100%;
    margin: 0;
    position: absolute;
    bottom: -10px;
    left: 0;
    opacity: 0;
    z-index: 1;
    transition: all .7s ease 0s
  }

  .box14:hover .icon {
    bottom: 20px;
    opacity: 1;
    transition-delay: .15s
  }

  .box14 .icon li a {
    display: block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border: 1px solid #fff;
    border-radius: 0 16px;
    font-size: 14px;
    color: #fff;
    margin-right: 5px;
    transition: all .4s ease 0s
  }

  .box14 .icon li a:hover {
    background: #d79719;
    border-color: #d79719
  }

  @media only screen and (max-width:990px) {
    .box14 {
      margin-bottom: 30px
    }
  }

  /*********************** Demo - 15 *******************/
  .box15 {
    position: relative
  }

  .box15 img {
    width: 100%;
    height: auto
  }

  .box15 .box-content {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: all .5s ease 0s
  }

  .box15:hover .box-content {
    background-color: rgba(255, 242, 242, .8)
  }

  .box15 .box-content:after,
  .box15 .box-content:before {
    content: "";
    width: 50px;
    height: 50px;
    position: absolute;
    opacity: 0;
    transform: scale(1.5);
    transition: all .6s ease .3s
  }

  .box15 .box-content:before {
    border-left: 1px solid #040404;
    border-top: 1px solid #040404;
    top: 19px;
    left: 19px
  }

  .box15 .box-content:after {
    border-bottom: 1px solid #040404;
    border-right: 1px solid #040404;
    bottom: 19px;
    right: 19px
  }

  .box15:hover .box-content:after,
  .box15:hover .box-content:before {
    opacity: 1;
    transform: scale(1)
  }

  .box15 .title {
    font-size: 22px;
    color: #000;
    margin: 0;
    position: relative;
    top: 0;
    opacity: 0;
    transition: all 1s ease 10ms
  }

  .box15:hover .title {
    top: 39%;
    opacity: 1;
    transition: all .5s cubic-bezier(1, -.53, .405, 1.425) 10ms
  }

  .box15 .title:after {
    content: "";
    width: 0;
    height: 1px;
    background: #040404;
    position: absolute;
    bottom: -8px;
    left: 0;
    right: 0;
    margin: 0 auto;
    transition: all 1s ease 0s
  }

  .box15:hover .title:after {
    width: 80%;
    transition: all 1s ease .8s
  }

  .box15 .icon {
    width: 100%;
    margin: 0 auto;
    position: absolute;
    bottom: 0;
    opacity: 0;
    transition-duration: .6s;
    transition-timing-function: cubic-bezier(1, -.53, .405, 1.425);
    transition-delay: .1s
  }

  .box15:hover .icon {
    bottom: 39%;
    opacity: 1
  }

  .box15 .icon li {
    display: inline-block
  }

  .box15 .icon li a {
    display: block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
    font-size: 18px;
    color: #000;
    border: 1px solid #000;
    margin-right: 5px;
    transition: all .3s ease-in-out 0s
  }

  .box15 .icon li a:hover {
    background: #000;
    color: #fff
  }

  @media only screen and (max-width:990px) {
    .box15 {
      margin-bottom: 30px
    }
  }

  /*********************** Demo - 16 *******************/
  .box16 {
    text-align: center;
    color: #fff;
    position: relative
  }

  .box16 .box-content,
  .box16:after {
    width: 100%;
    position: absolute;
    left: 0
  }

  .box16:after {
    content: "";
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, .08) 69%, rgba(0, 0, 0, .76) 100%);
    top: 0;
    transition: all .5s ease 0s
  }

  .box16 .post,
  .box16 .title {
    transform: translateY(145px);
    transition: all .4s cubic-bezier(.13, .62, .81, .91) 0s
  }

  .box16:hover:after {
    background: linear-gradient(to bottom, rgba(0, 0, 0, .01) 0, rgba(0, 0, 0, .09) 11%, rgba(0, 0, 0, .12) 13%, rgba(0, 0, 0, .19) 20%, rgba(0, 0, 0, .29) 28%, rgba(0, 0, 0, .29) 29%, rgba(0, 0, 0, .42) 38%, rgba(0, 0, 0, .46) 43%, rgba(0, 0, 0, .53) 47%, rgba(0, 0, 0, .75) 69%, rgba(0, 0, 0, .87) 84%, rgba(0, 0, 0, .98) 99%, rgba(0, 0, 0, .94) 100%)
  }

  .box16 img {
    width: 100%;
    height: auto
  }

  .box16 .box-content {
    padding: 20px;
    margin-bottom: 20px;
    bottom: 0;
    z-index: 1
  }

  .box16 .title {
    font-size: 22px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0 0 10px
  }

  .box16 .post {
    display: block;
    padding: 8px 0;
    font-size: 15px
  }

  .box16 .social li a,
  .box17 .icon li a {
    border-radius: 50%;
    font-size: 20px;
    color: #fff
  }

  .box16:hover .post,
  .box16:hover .title {
    transform: translateY(0)
  }

  .box16 .social {
    list-style: none;
    padding: 0 0 5px;
    margin: 40px 0 25px;
    opacity: 0;
    position: relative;
    transform: perspective(500px) rotateX(-90deg) rotateY(0) rotateZ(0);
    transition: all .6s cubic-bezier(0, 0, .58, 1) 0s
  }

  .box16:hover .social {
    opacity: 1;
    transform: perspective(500px) rotateX(0) rotateY(0) rotateZ(0)
  }

  .box16 .social:before {
    content: "";
    width: 50px;
    height: 2px;
    background: #fff;
    margin: 0 auto;
    position: absolute;
    top: -23px;
    left: 0;
    right: 0
  }

  .box16 .social li {
    display: inline-block
  }

  .box16 .social li a {
    display: block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    background: #6d3795;
    margin-right: 10px;
    transition: all .3s ease 0s
  }

  .box17 .icon li,
  .box17 .icon li a {
    display: inline-block
  }

  .box16 .social li a:hover {
    background: #bea041
  }

  .box16 .social li:last-child a {
    margin-right: 0
  }

  @media only screen and (max-width:990px) {
    .box16 {
      margin-bottom: 30px
    }
  }

  /*********************** Demo - 17 *******************/
  .box17 {
    position: relative
  }

  .box17:after {
    content: "";
    width: 100%;
    height: 100%;
    background: rgba(2, 162, 221, .9);
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: all .5s ease 0s
  }

  .box17:hover:after {
    opacity: 1
  }

  .box17 img {
    width: 100%;
    height: auto;
    transition: all 1.5s ease 0s
  }

  .box17 .box-content,
  .box17 .icon li a {
    position: absolute;
    transition: all .6s ease 0s
  }

  .box17:hover img {
    transform: scale(1.2)
  }

  .box17 .icon {
    width: 100%;
    height: 100%;
    list-style: none;
    padding: 0;
    margin: 0 auto;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1
  }

  .box17 .icon li a {
    width: 45px;
    height: 45px;
    line-height: 45px;
    margin: 0 auto;
    top: 50%;
    border: 1px solid #fff;
    opacity: 0
  }

  .box17:hover .icon li a {
    top: 30%;
    opacity: 1
  }

  .box17 .icon li a:hover {
    background: #fff;
    color: #02a2dd
  }

  .box17 .icon li:first-child a {
    left: -90%;
    right: 0
  }

  .box17:hover .icon li:first-child a {
    left: -55px
  }

  .box17 .icon li:last-child a {
    right: -90%;
    left: 0
  }

  .box17:hover .icon li:last-child a {
    right: -55px
  }

  .box17 .box-content {
    width: 100%;
    padding: 20px 10px;
    background: rgba(0, 0, 0, .1);
    bottom: -100px;
    left: 0;
    z-index: 1
  }

  .box17:hover .box-content {
    bottom: 0
  }

  .box17 .title {
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    margin-top: 0
  }

  .box17 .post {
    display: block;
    font-size: 14px;
    color: #fff
  }

  @media only screen and (max-width:990px) {
    .box17 {
      margin-bottom: 30px
    }
  }

  /*********************** Demo - 18 *******************/
  .box18 {
    background: #3c3c3c;
    position: relative;
    -webkit-transition: all .35s ease;
    transition: all .35s ease
  }

  .box18:after,
  .box18:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #3c3c3c;
    border-left: 3px solid #fff;
    border-right: 3px solid #fff;
    opacity: .9;
    z-index: 1;
    -webkit-transition: all .35s ease;
    transition: all .35s ease
  }

  .box18 img,
  .box19 img {
    height: auto;
    width: 100%
  }

  .box18:before {
    -webkit-transform: skew(45deg) translateX(-155%);
    transform: skew(45deg) translateX(-155%)
  }

  .box18:hover:before {
    -webkit-transform: skew(45deg) translateX(-55%);
    transform: skew(45deg) translateX(-55%)
  }

  .box18:after {
    -webkit-transform: skew(45deg) translateX(155%);
    transform: skew(45deg) translateX(155%)
  }

  .box18:hover:after {
    -webkit-transform: skew(45deg) translateX(55%);
    transform: skew(45deg) translateX(55%)
  }

  .box18 img {
    -webkit-transition: all .35s ease;
    transition: all .35s ease
  }

  .box18:hover img {
    opacity: .5
  }

  .box18 .box-content {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-box-shadow: 0 0 10px #3c3c3c;
    box-shadow: 0 0 10px #3c3c3c;
    opacity: 0;
    z-index: 2;
    -webkit-transform: translate(-50%, -50%) scale(.5);
    transform: translate(-50%, -50%) scale(.5);
    -webkit-transition: all .35s ease;
    transition: all .35s ease
  }

  .box19,
  .box20,
  .box21 {
    box-shadow: 0 0 5px #a3a3a3
  }

  .box18:hover .box-content {
    -webkit-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
    opacity: 1
  }

  .box18 .post,
  .box18 .title {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    background: #3c3c3c;
    border: 2px solid #fff;
    color: #fff;
    padding: 15px 20px;
    margin: 0
  }

  .box18 .post,
  .box19 .title,
  .box20 .post,
  .box20 .title {
    text-transform: capitalize
  }

  .box18 .post {
    display: block;
    font-size: 14px;
    font-weight: 400;
    padding: 5px 10px;
    margin-top: 15px
  }

  @media only screen and (max-width:990px) {
    .box18 {
      margin-bottom: 30px
    }
  }

  /*********************** Demo - 19 *******************/
  .box19 {
    text-align: center;
    position: relative
  }

  .box19 .box-content {
    width: 100%;
    height: 100%;
    background: 0 0;
    padding-top: 25%;
    position: absolute;
    top: 0;
    left: 0;
    transition: all .3s ease 0s
  }

  .box19 .icon,
  .box19 .title {
    transition: all .2s ease 0s
  }

  .box19:hover .box-content {
    background: rgba(0, 0, 0, .5)
  }

  .box19 .title {
    font-size: 24px;
    color: #fff;
    transform: scale(0)
  }

  .box19:hover .title {
    transform: scale(1)
  }

  .box19 .icon {
    list-style: none;
    padding: 0;
    margin: 0;
    opacity: 0
  }

  .box19:hover .icon {
    opacity: 1
  }

  .box19 .icon li {
    display: inline-block
  }

  .box19 .icon li:first-child a,
  .box19 .icon li:last-child a {
    display: block;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 24px;
    color: #fff;
    border: 1px solid #fff;
    position: relative
  }

  .box19 .icon li a {
    top: -150px
  }

  .box19:hover .icon li a {
    top: 0
  }

  .box19:hover .icon li a:hover {
    background: #a46789;
    border-color: #a46789
  }

  .box19 .icon li:first-child a {
    transition: all .6s cubic-bezier(.175, .885, .32, 1.275) 0s
  }

  .box19 .icon li:last-child a {
    transition: all .6s cubic-bezier(.175, .885, .32, 1.275) .1s
  }

  @media only screen and (max-width:990px) {
    .box19 {
      margin-bottom: 30px
    }
  }

  @media only screen and (max-width:360px) {
    .box19 .box-content {
      padding-top: 20%
    }
  }

  /*********************** Demo - 20 *******************/
  .box20 {
    position: relative
  }

  .box20:after,
  .box20:before {
    position: absolute;
    content: ""
  }

  .box20:before {
    width: 80%;
    height: 220%;
    background: #ff402a;
    top: -50%;
    left: -100%;
    z-index: 1;
    transform: rotate(25deg);
    transform-origin: center top 0;
    transition: all .5s ease 0s
  }

  .box20:hover:before {
    left: 10%
  }

  .box20:after {
    width: 55%;
    height: 175%;
    background-color: rgba(0, 0, 0, .8);
    bottom: -1000%;
    left: 53%;
    transform: rotate(-33deg);
    transform-origin: center bottom 0;
    transition: all .8s ease 0s
  }

  .box20 .box-content,
  .box20 .icon {
    width: 100%;
    padding: 0 20px;
    position: absolute;
    left: 0;
    z-index: 2;
    transition: all 1.1s ease 0s
  }

  .box20:hover:after {
    bottom: -70%
  }

  .box20 img {
    width: 100%;
    height: auto
  }

  .box20 .box-content {
    top: -100%;
    color: #fff
  }

  .box20:hover .box-content {
    top: 30px
  }

  .box20 .title {
    font-size: 24px;
    margin: 0
  }

  .box20 .icon li a,
  .box20 .post {
    display: inline-block;
    font-size: 14px
  }

  .box20 .post {
    margin-top: 5px
  }

  .box20 .icon {
    list-style: none;
    margin: 0;
    bottom: -100%
  }

  .box20:hover .icon {
    bottom: 25px
  }

  .box20 .icon li {
    display: inline-block
  }

  .box20 .icon li a {
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: #444;
    border-radius: 50%;
    margin: 0 3px;
    color: #fff;
    text-align: center;
    transition: all .5s ease 0s
  }

  .box20 .icon li a:hover {
    background: #fff;
    color: #ff402a
  }

  @media only screen and (max-width:990px) {
    .box20 {
      margin-bottom: 30px
    }
  }

  @media only screen and (max-width:479px) {
    .box20 .title {
      font-size: 20px
    }
  }

  /*********************** Demo - 21 *******************/
  .box21 {
    text-align: center;
    position: relative
  }

  .box21:after,
  .box21:before {
    content: "";
    width: 2px;
    height: 2px;
    border-radius: 50%;
    background: rgba(0, 0, 0, .35);
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0)
  }

  .box21:hover:after,
  .box21:hover:before {
    -webkit-transform: scale(400);
    -moz-transform: scale(400);
    -ms-transform: scale(400);
    -o-transform: scale(400);
    transform: scale(400)
  }

  .box21:before {
    -o-transition: all .5s linear .3s;
    -moz-transition: all .5s linear .3s;
    -ms-transition: all .5s linear .3s;
    -webkit-transition: all .5s linear .3s;
    transition: all .5s linear .3s
  }

  .box21:hover:before {
    -moz-transition-delay: 0s;
    -webkit-transition-delay: 0s;
    -o-transition-delay: 0s;
    -ms-transition-delay: 0s;
    transition-delay: 0s
  }

  .box21:after {
    -o-transition: all .5s linear .6s;
    -moz-transition: all .5s linear .6s;
    -ms-transition: all .5s linear .6s;
    -webkit-transition: all .5s linear .6s;
    transition: all .5s linear .6s
  }

  .box21:hover:after {
    -moz-transition-delay: .2s;
    -webkit-transition-delay: .2s;
    -o-transition-delay: .2s;
    -ms-transition-delay: .2s;
    transition-delay: .2s
  }

  .box21 img {
    width: 100%;
    height: auto
  }

  .box21 .box-content {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: 0 0;
    color: #fff;
    padding-top: 25px;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    -ms-transition: all .3s linear 0s;
    -o-transition: all .3s linear 0s;
    -webkit-transition: all .3s linear 0s;
    -moz-transition: all .3s linear 0s;
    transition: all .3s linear 0s;
    z-index: 1
  }

  .box21:hover .box-content {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    -moz-transition-delay: .4s;
    -webkit-transition-delay: .4s;
    -o-transition-delay: .4s;
    -ms-transition-delay: .4s;
    transition-delay: .4s
  }

  .box21 .title {
    font-size: 21px;
    font-weight: 700;
    text-transform: uppercase;
    border-bottom: 1px solid #fff;
    padding-bottom: 20px;
    margin-top: 20px
  }

  .box21 .description {
    font-size: 14px;
    font-style: italic;
    padding: 0 10px;
    margin: 15px 0
  }

  .box21 .read-more {
    display: block;
    width: 120px;
    background: #178993;
    border-radius: 5px;
    font-size: 14px;
    color: #fff;
    text-transform: capitalize;
    padding: 10px 0;
    margin: 0 auto
  }

  @media only screen and (max-width:990px) {
    .box21 {
      margin-bottom: 30px
    }
  }

  @media only screen and (max-width:479px) {
    .box21 .box-content {
      padding-top: 0
    }
  }

  @media only screen and (max-width:359px) {
    .box21 .title {
      padding-bottom: 10px
    }
  }

  ul {
    list-style: none;
    padding: 0;
  }

  p {
    padding-left: 1.3em;
  }

  p:before {
    content: "\f00c";
    /* FontAwesome Unicode */
    font-family: FontAwesome;
    display: inline-block;
    margin-left: -1.3em;
    /* same as padding-left set on li */
    width: 1.3em;
    /* same as padding-left set on li */
    color: #39b54a;
    font-size: 15px;
  }

  @media (min-width: 300px) and (max-width: 500px) {

    .box10[_ngcontent-c0]:after,
    .box10[_ngcontent-c0]:before {
      border-width: 0 180px 140px;
      border-style: solid;
      border-color: transparent transparent #fff;
      position: absolute;
      bottom: 0;
      left: 50%;
      z-index: 1;
      transform: translate(-55%, 100%);
      transition: all .5s 0s cubic-bezier(.6, -.28, .735, .045);
    }
  }

</style>

<body>
  <!-- <section
        class="bg-alternate ways"
        id="buynow"
        style="background-color: #e24f8f"
    >
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <br>
                    <br>
                    <h2 class="item-title"></h2>
                </div>
            </div>
            <div class="container">
                <div class="row">

                    <div class="container mt-40">
                        <h1
                            class="text-center"
                            style="color:#fff; font-size: 20px;"
                        >
                            Buy Insurance Online
                        </h1>
                        <div class="row mt-30">
                            <div class="col-md-4 col-sm-6">
                                <div class="box10">
                                    <img src="assets/img/pro1.jpg">
                                    <div class="box-content">
                                        <a href="/medical">
                                            <h3 class="title">MEDICAL INSURANCE</h3>
                                        </a>
                                       
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-6">
                                <div class="box10">
                                    <img src="assets/img/pro2.jpg">
                                    <div class="box-content">
                                        <a href="/life">
                                            <h3 class="title">TERM LIFE INSURANCE</h3>
                                        </a>
                                      
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-6">
                                <div class="box10">
                                    <img src="assets/img/pro3.jpg">
                                    <div class="box-content">
                                        <a href="/cancer">
                                            <h3 class="title">CANCER INSURANCE</h3>
                                        </a>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </section> -->

  <section class="bg-alternate ways" id="buynow" style="background-color: #e24f8f">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <br>
          <br>
          <h2 class="item-title"></h2>
        </div>
      </div>
      <div class="container">
        <div class="row">

          <div class="container mt-40">
            <h1 class="text-center" style="color:#fff; font-size: 20px;">
              Buy Insurance Online
            </h1>
            <div class="row mt-30">
              <div class="col-md-4 col-sm-6">
                <div class="box10">
                  <img src="assets/img/e1.jpg">
                  <div class="box-content">
                    <a href="/e-medic">
                      <h3 class="title">eMedic</h3>
                    </a>

                  </div>
                </div>
              </div>
              <div class="col-md-4 col-sm-6">
                <div class="box10">
                  <img src="assets/img/e2.jpg">
                  <div class="box-content">
                    <a href="/cancercare">
                      <h3 class="title">CancerCare</h3>
                    </a>

                  </div>
                </div>
              </div>
              <div class="col-md-4 col-sm-6">
                <div class="box10">
                  <img src="assets/img/e3.jpg">
                  <div class="box-content">
                    <a href="/e-life">
                      <h3 class="title">eLifeProtector</h3>
                    </a>

                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
  </section>
  <br>
  <br>
  <br>
  <br>

  <app-footer></app-footer>

</body>
