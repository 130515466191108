  <div class="container  hidden-xs" id="contact">
    <div class="row">
      <div class="col-sm-4">
        <h3>Sitemap</h3>
        <ul>
          <ul>
            <li><a href="#about">About</a></li>
            <li><a href="#works">How it Works </a></li>
            <li><a href="#offer">What We Offer </a></li>
            <li><a href="#partners">Partners</a></li>
            <!-- <li><a href="/insurance">Buy Now </a></li> -->
            <li><a href="/team">Our Team </a></li>
          </ul>
        </ul>
      </div>
      <div class="col-sm-4">
        <h3>Contact Us</h3>

        <ul>
          <li><a href="#">E: info@vsure.life </a></li>
        </ul>
        <h3>
          <a href="/news">News & Events </a>
        </h3>
        <ul></ul>
      </div>
      <div class="col-sm-4">
        <h3>Connect with Us</h3>
        <ul>
          <ul>
            <li>
              <i class="fa fa-facebook"></i> &nbsp;<a href="https://www.facebook.com/vsure.life/"
                target="_blank">Facebook
              </a>
            </li>
            <li>
              <i class="fa fa-linkedin"></i> &nbsp;<a href="https://www.linkedin.com/company/vsuredotcom"
                target="_blank">LinkedIn</a>
            </li>
            <!-- <li><i class="fa fa-instagram"></i> &nbsp;<a href="#">Instagram
                  </a></li> -->
            <!-- <li> <i class="fa fa-twitter"></i> &nbsp;<a href="#">Twitter</a></li> -->
          </ul>
        </ul>
      </div>
    </div>
    <div style="float: right">
      <div class="banner-watch">
        <a href="https://play.google.com/store/apps/details?id=com.vsure.live" target="_blank">
          <img src="assets/img/googleplay.png" /></a>&nbsp;
        <a href="https://itunes.apple.com/my/app/vsure-life-on-demand-insurance/id1460213328?mt=8" target="_blank">
          <img src="assets/img/app.png" /></a>
          <br><br>   <br><br>
      </div>
    </div>
  
  </div>

  <!-- START CHAT BOT -->
  <div class="container" *ngIf="chatBot">
    <div class="">
      <div class="">
        <div class="contact-form-page">
          <div style="    background: #000000;">
            <div class="form-head">
              <div class="header-btn">
                <a class="top-btn" href="#"><i class="fa fa-times"></i></a>
              </div>
            </div>
          </div>
          <h1 style="font-size:16px;color:#fff;float:left; margin-left: 10px;">
            How can we help you?
          </h1>
          <iframe frameborder="0" class="embed-responsive-item"
            src="https://livechat.superceed.com?code=vsure&token=5710e9b4a0224b07a70df3871ac5c0e3" height="100%"
            width="100%" allowfullscreen></iframe>
        </div>
        <a class="buttom-btn" href="#"><i class="fas fa-comment-alt" aria-hidden="true"></i></a>
      </div>
    </div>
  </div>
  <!-- END CHAT BOT -->

  <footer class="vsure-footer">
    <div class="container">
      <div class="row">
        <div class="col-xs-12 col-sm-6 col-lg-5">
          <div class="copy">
            © 2019 VSure Tech Sdn Bhd. All Rights Reserved
          </div>
        </div>
        <div class="col-sm-6 col-lg-2 pull-right text-right hidden-xs">
          <ul class="bottom-nav">
            <li>
              <a class="smooth-scroll" target="”_blank”" href="/assets/pdf/privacy-policy.pdf"
                onClick="sendEvent('Footer', 'Click', 'Privacy Policy');">Privacy Policy</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
