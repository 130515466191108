<header class="vsure-header">
  <div class="container">
    <div class="row">
      <div class="col-xs-2 col-sm-4 hidden-md hidden-lg">
        <div class="burger-menu">
          <a
            href="javascript:void(0)"
            data-toggle="collapse"
            data-target="#vsure-navbar"
            onClick="sendEvent('Menu', 'Click', 'Burger menu');"
          >
            <i class="fa fa-bars" aria-hidden="true"></i>
          </a>
        </div>
      </div>
      <div class="col-md-5 hidden-xs hidden-sm">
        <ul class="top-left-nav top-nav">
          <li>
            <a
              class="smooth-scroll"
              href="#about"
              onClick="sendEvent('Menu', 'Click', 'About Vsure');"
            >
              About
            </a>
          </li>
          <li>
            <a
              class="smooth-scroll"
              href="#works"
              onClick="sendEvent('Menu', 'Click', 'works');"
            >
              How it Works
            </a>
          </li>
          <li>
            <a
              class="smooth-scroll"
              href="#offer"
              onClick="sendEvent('Burger-Menu', 'Click', 'Blog');"
              target="_blank"
            >
              What We Offer
            </a>
          </li>
          <li>
            <a
              class="smooth-scroll"
              href="#partners"
              onClick="sendEvent('Menu', 'Click', 'Team');"
            >
              Partners
            </a>
          </li>
          <!-- <li>
                      <a
                          class="smooth-scroll"
                          href="#buynow"
                          onClick="sendEvent('Menu', 'Click', 'Team');"
                      >
                          Buy Now
                      </a>
                  </li> -->
          <li>
            <a
              class="smooth-scroll"
              href="/team"
              onClick="sendEvent('Menu', 'Click', 'Team');"
            >
              Team
            </a>
          </li>
          <li>
            <a
              class="smooth-scroll"
              href="#contact"
              onClick="sendEvent('Menu', 'Click', 'Team');"
              >Contact Us</a
            >
          </li>
        </ul>
      </div>
      <div
        class="col-xs-8 col-sm-4 col-md-2 text-center logo"
        style="padding-top:20px;"
      >
        <a
          href="index.html"
          onClick="ga('send', 'event', 'landing page | home', 'mainmenu', 'logo');"
        >
          <img
            src="assets/img/vsure_logo.svg"
            width="auto"
            height="64"
            class="black_logo"
          />
          <img
            src="assets/img/vsure_white.svg"
            width="auto"
            height="64"
            class="white_logo"
          />
        </a>
      </div>
      <div class="col-sm-4 col-md-5 hidden-xs">
        <ul class="top-nav">
          <li class="col-xs-1 pull-right">
            <a href="https://www.facebook.com/vsure.life/" target="_blank">
              <i class="fa fa-facebook"></i>
              <span class="sr-only">Facebook</span>
            </a>
          </li>
          <!-- <li class="col-xs-1 pull-right">
          <a href="#" target="_blank"><i class="fa fa-twitter"></i><span class="sr-only">Twitter</span></a>
        </li> -->
          <li class="col-xs-1 pull-right">
            <a
              href="https://www.linkedin.com/company/vsuredotcom"
              target="_blank"
            >
              <i class="fa fa-linkedin"></i>
              <span class="sr-only">LinkedIn</span>
            </a>
          </li>
          <!-- <li class="col-xs-1 pull-right">
          <a href="https://www.instagram.com/vsure.life/" target="_blank"><i class="fa fa-instagram"></i><span class="sr-only">Instagram</span></a>
        </li> -->
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 hidden-md hidden-lg">
        <div class="collapsing vsure-navbar" id="vsure-navbar">
          <div class="container">
            <ul class="col-xs-12">
              <li>
                <a
                  class="smooth-scroll"
                  href="#about"
                  onClick="sendEvent('Burger-Menu', 'Click', 'About Vsure');"
                >
                  About
                </a>
              </li>
              <li>
                <a
                  class="smooth-scroll"
                  href="#works"
                  onClick="sendEvent('Menu', 'Click', 'works');"
                >
                  How it Works
                </a>
              </li>

              <li>
                <a
                  class="smooth-scroll"
                  href="#offer"
                  onClick="sendEvent('Burger-Menu', 'Click', 'Blog');"
                  target="_blank"
                >
                  What We Offer
                </a>
              </li>

              <li>
                <a
                  class="smooth-scroll"
                  href="#partners"
                  onClick="sendEvent('Menu', 'Click', 'Team');"
                >
                  Partners
                </a>
              </li>
              <li>
                <a
                  class="smooth-scroll"
                  href="#buynow"
                  onClick="sendEvent('Menu', 'Click', 'Team');"
                >
                  Buy Now
                </a>
              </li>
              <li>
                <a
                  class="smooth-scroll"
                  href="/team"
                  onClick="sendEvent('Menu', 'Click', 'Team');"
                >
                  Team
                </a>
              </li>
              <li>
                <a
                  class="smooth-scroll"
                  href="/news"
                  onClick="sendEvent('Menu', 'Click', 'Team');"
                >
                  News & Events
                </a>
              </li>
            </ul>
            <ul class="col-xs-12 hidden-sm sub-nav">
              <li>
                <a
                  class="smooth-scroll"
                  target="”_blank”"
                  href="/assets/pdf/privacy-policy.pdf"
                  onClick="sendEvent('Footer', 'Click', 'Privacy Policy');"
                >
                  Privacy Policy
                </a>
              </li>
            </ul>
            <div class="social col-xs-12 hidden-sm">
              <h6>Follow us on Social Media</h6>
              <ul class>
                <li class="col-xs-1 pull-right">
                  <a
                    href="https://www.facebook.com/vsure.life/"
                    target="_blank"
                  >
                    <i class="fa fa-facebook"></i>
                    <span class="sr-only">Facebook</span>
                  </a>
                </li>
                <!-- <li class="col-xs-1 pull-right">
                <a href="#" target="_blank"><i class="fa fa-twitter"></i><span class="sr-only">Twitter</span></a>
              </li> -->
                <li class="col-xs-1 pull-right">
                  <a
                    href="https://www.linkedin.com/company/vsuredotcom"
                    target="_blank"
                  >
                    <i class="fa fa-linkedin"></i>
                    <span class="sr-only">LinkedIn</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
<style>
  .full-width-image-2 {
    background: url(assets/img/cancer.jpg) no-repeat center center;
    background-size: cover;
    text-align: center;
    height: 300px;
  }
</style>

<body>
  <div class="full-width-image-2"></div>
  <section class="bg-alternate ways" id="partners">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <h2 style="color:#ef5193">eLife Protector+</h2>
        </div>
      </div>
      <div class="row way-holder ">
        <div class="col-md-4 col-xs-12 way">
          <div style="border: 1px solid #8c8b8b; padding: 10px; ">
            <img
              src="assets/img/190.jpg"
              class="rounded float-left"
              width="190"
              height="190"
              alt="Clear"
              onClick="sendEvent('ABetterWay', 'Click', 'Clear');"
            />
            <h2
              class="title margin-top-15"
              onClick="sendEvent('ABetterWay', 'Click', 'Clear');"
            >
              AXA eLife Protector
            </h2>
            <div class="content-holder">
              <div class="details content margin-top-20">
                <div style="text-align: left;    line-height: 23px;">
                  A simple one-year term life plan you can buy online. eLife
                  Protector protects your life today easily, right from the
                  comfort of your seat. Noting compares to the satisfaction of
                  knowing your family would be financially secure and in safe
                  hands once your’e no longer around.
                </div>
                <div style="margin-top:70px; "></div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-xs-12 way">
          <div style="border: 1px solid #8c8b8b; padding: 10px; ">
            <h2
              class="title margin-top-15"
              onClick="sendEvent('ABetterWay', 'Click', 'Fair');"
            >
              Key Benefits
            </h2>
            <div class="content-holder">
              <div class="details content margin-top-20">
                <ul style="text-align: left;">
                  <p>
                    Death benefit with a lump sum payment for your family in the
                    unfortunate event of your demise.
                  </p>
                  <p>Accidental death benefit</p>
                  <p>Yearly coverage and renewable annually.</p>
                  <p>
                    Flexible sum insured with payout amount of between RM25,000
                    to RM500,000
                  </p>
                  <p>Easy and simple sign-up</p>
                  <p>
                    No medical examinations are required for enrolment of this
                    plan.
                  </p>
                </ul>
              </div>
              <div style="margin-top: 66px;"></div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-xs-12 way">
          <div style="border: 1px solid #8c8b8b; padding: 10px; ">
            <h2
              class="title margin-top-15"
              onClick="sendEvent('ABetterWay', 'Click', 'Fair');"
            >
              Buy Now
            </h2>
            <br />
            <form
              [formGroup]="formGroup"
              (ngSubmit)="onSubmit(formGroup.value)"
              method="POST"
            >
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  formControlName="name"
                  placeholder="*Full Name"
                  required
                />
              </div>
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  id="email"
                  formControlName="email"
                  placeholder=" *Email"
                  required
                />
              </div>
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  id="referral"
                  formControlName="referral"
                  placeholder="Referral Code - Optional"
                  [readonly]="readonly"
                />
              </div>
              <recaptcha formControlName="myRecaptcha"></recaptcha>

              <div class="form-check" style="text-align: left">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="exampleCheck1"
                  [checked]="checkBoxStatus"
                  (change)="checkBoxStatus = !checkBoxStatus"
                  required
                />
                <label class="form-check-label" for="exampleCheck1">
                  &nbsp; I agree to the
                  <a href="/assets/pdf/terms-of-use.pdf">Terms</a>
                  &
                  <a href="/assets/pdf/privacy-policy.pdf">Privacy policies.</a>
                </label>
              </div>

              <!-- <button type="submit" class="btn btn-primary">Submit</button> -->
              <input
                type="submit"
                [disabled]="formGroup.invalid || !checkBoxStatus"
                class="btn"
                onclick="window.open('https://www.axa.com.my/buy/term-life-insurance-malaysia/purchase?id=FfF4itGWe8')"
                value="Submit"
              />
              <br />
              <small>This product is underwritten by</small>
              <br />
              <img style="margin-top: 10px;" src="assets/img/axa1.jpg" />
            </form>
            <div style="margin-top: 10px; float: left"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="bg-alternate ways">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <!-- <h2 style="color:#ef5193; text-align: left">eLife Protector+
          </h2> -->
        </div>
      </div>

      <div class="contant" style="text-align: left">
        <h2>Product</h2>
        <span
          >Your family is the most important asset you can protect. Nobody can
          predict what may happen tomorrow. A single unfortunate event can
          change your family’s life forever. You can’t stop it, but you can take
          proactive steps to protect their financial future. </span
        ><br /><br />
        <span
          >eLife Protector+ is an affordable non-participating yearly renewable
          (up to age 75) pure protection plan. This plan provides you a lump sum
          benefit up to RM 500,000 upon your death or Total and Permanent
          Disability (TPD). With eLife Protector+, you shall be rest assured
          that your loved ones are protected from financial insecurity. For more
          product info, click<a target="_blank" href="#" style="color:#ef5193">
            here.</a
          >
        </span>
        <br /><br />
        <h2>Benefit / Coverage</h2>

        <ol type="I">
          <li>Death - 100% of Basic Sum Insured</li>
          <li>
            Accidental Death - Additional 100% of Basic Sum Insured (up to RM 2
            million per life)
          </li>
          <li>
            Total and Permanent Disability (TPD) - 100% of Basic Sum Insured (up
            to RM 4 million per life)
          </li>
          <li>
            Affordable premium as low as RM 2.90 per day with Basic Sum Insured
            of RM 500,000
          </li>
          <li>Easy and simple enrolment process</li>
          <li>
            Understand more benefits at
            <a target="_blank" href="#" style="color:#ef5193">here</a>
          </li>
        </ol>

        <br />
        <h2>Claim</h2>
        <ol>
          <li>Download the Claim Form.</li>
          <ol style="text-decoration: none">
            <li>
              For death:<a
                style="color:#ef5193"
                target="_blank"
                href="https://axa-com-my.cdn.axa-contento-118412.eu/axa-com-my/42f193fe-f188-41d8-90f6-19c7aba3f57f_death+form.pdf"
              >
                Death Claim Form</a
              >
            </li>
            <li>
              For Total Permanent Disability (TPD):<a
                target="_blank"
                style="color:#ef5193"
                href="https://axa-prod.s3.amazonaws.com/axa-com-my/5ecfed75-6dcf-4683-8590-9602ea866b5c_injury+claim+form+li.pdf"
              >
                TPD Claim Form</a
              >
            </li>
          </ol>

          <li>
            Submit the completed claim form and listed supporting documents to
            AXA via email (customer.care@axa-life.com.my) or to the nearest AXA
            branch. Claims should be submitted within 30 days from the date of
            incident.
          </li>
          <li>
            Upon receipt of all documents required, AXA will review and revert
            with claim decision within 14 working days.
          </li>
          <li>
            Upon approval of claims, AXA will make payment within 3 working
            days.
          </li>
        </ol>

        <br />

        <br />

        <!-- <span
          >Note:<br />
          To purchase eLife Protector+ now, please follow the link below:-
        </span> -->
        <!-- <p><a href="/life" style="color:#ef5193;">eLife Protector+ </a></p> -->
      </div>
    </div>
  </section>

  <app-footer></app-footer>
</body>
