import { NgModule } from "@angular/core";
import { ApolloModule, Apollo } from "apollo-angular";
import { HttpLinkModule, HttpLink } from "apollo-angular-link-http";

import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpClientModule } from "@angular/common/http";
import { ApolloLink } from "apollo-link";
// import { AuthService } from "./services/auth.service";
import { environment } from "../environments/environment";
import { onError } from "apollo-link-error";
import _ from "lodash";
import { ToastrService } from "ngx-toastr";

const uri = environment.apiURL;

@NgModule({
  exports: [HttpClientModule, ApolloModule, HttpLinkModule]
})
export class GraphQLModule {
  constructor(
    apollo: Apollo,
    httpLink: HttpLink,
    // public authService: AuthService,
    private toastrService: ToastrService
  ) {
    const http = httpLink.create({ uri });
    const middleware = new ApolloLink((operation, forward) => {
      // Check for token

      operation.setContext(context => ({
        ...context,
        headers: {
          ...context.headers
        }
      }));

      return forward(operation);
    });

    const link = middleware.concat(http);

    const errorLink = onError(
      ({ graphQLErrors, networkError, response, operation, forward }) => {
        // console.log(graphQLErrors);
        // console.log(networkError);

        if (graphQLErrors) {
          for (let err of graphQLErrors) {
            switch (_.get(err, "extensions.code")) {
              case "UNAUTHENTICATED":
                operation.setContext(context => ({
                  ...context,
                  headers: {
                    ...context.headers
                  }
                }));
                // retry the request, returning the new observable
                return forward(operation);
              case "INTERNAL_SERVER_ERROR": {
                this.toastrService.error(
                  _.get(err, "message"),
                  _.get(err, "path")
                );
              }
            }
          }
        }
        if (networkError) {
          let error = JSON.parse(JSON.stringify(networkError));
          if (_.get(error, "error.errors")) {
            for (var i = 0; i < error.error.errors.length; i++) {
              this.toastrService.error(
                "" + error.error.errors[i].message,
                "" + error.error.errors[i].path
              );
            }
          } else {
            this.toastrService.warning("Unable to reach the server", "Warning");
          }
        }
      }
    );

    apollo.create({
      link: ApolloLink.from([errorLink, link]),
      connectToDevTools: true,
      cache: new InMemoryCache()
    });
  }
}
