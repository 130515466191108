import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-download",
  templateUrl: "./download.component.html",
  styleUrls: ["./download.component.css"]
})
export class DownloadComponent implements OnInit {
  constructor() {}

  ngOnInit() {
    if (navigator.userAgent.toLowerCase().indexOf("android") > -1) {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.vsure.live";
    } else if (navigator.userAgent.toLowerCase().indexOf("iphone") > -1) {
      window.location.href =
        "https://apps.apple.com/my/app/vsure-life-on-demand-insurance/id1460213328";
    } else {
      window.location.href = "https://vsure.life/#offer";
    }
  }
}
