import { Component, Injectable, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { UserService } from "../common/services/user.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.css"]
})
@Injectable()
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  isSubmitted = false;
  showMessage = false;
  maxDate = new Date();
  readonly = false;

  error = {
    name: "",
    date_of_birth: "",
    email: "",
    password: "",
    confirm_password: "",
    myRecaptcha: ""
  };

  errorList = {
    required: "is required.",
    pattern: "is invalid.",
    confirm_password: "is not matched with password.",
    minLength: "minimum of ",
    invalidRecaptcha: "is required."
  };

  ngOnInit() {
    this.registerForm = new FormGroup({
      name: new FormControl("", [
        Validators.required,
        Validators.pattern("[a-z/A-Z][a-z/A-Z ]+")
      ]),
      date_of_birth: new FormControl("", [Validators.required]),
      email: new FormControl("", [
        Validators.required,
        Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$")
      ]),
      password: new FormControl("", [
        Validators.required,
        Validators.minLength(8)
      ]),
      confirm_password: new FormControl("", [
        Validators.required,
        Validators.minLength(8)
      ]),
      referrer_code: new FormControl(""),
      myRecaptcha: new FormControl(false, [Validators.required])
    });
    this.route.queryParams.subscribe(data => {
      if (data.referral_code) {
        this.registerForm.get("referrer_code").setValue(data.referral_code);
        this.readonly = true;
      }
    });
  }

  constructor(
    private route: ActivatedRoute,
    private service: UserService,
    private toastrService: ToastrService
  ) {}

  isValid(textName: string, formControlName: string, confirmPassword = false) {
    let control = this.registerForm.get(formControlName);

    if (confirmPassword) {
      this.error[formControlName] = this.displayErrorMessage(
        control,
        textName,
        confirmPassword
      );
    } else {
      this.error[formControlName] = this.displayErrorMessage(control, textName);
    }
    return (
      control.invalid && (control.touched || control.dirty || this.isSubmitted)
    );
  }

  registerOnline(formData) {
    this.isSubmitted = true;
    if (this.registerForm.valid) {
      formData.date_of_birth = formData.date_of_birth.format("YYYY-MM-DD");
      delete formData.confirm_password;
      delete formData.myRecaptcha;
      formData.password = this.service.encryptPassword(
        formData.email,
        formData.password
      );
      this.service.registerOnline({ input: formData }).subscribe(
        data => {
          this.toastrService.success("Successfully registered");
          this.showMessage = true;
        },
        error => {
          this.registerForm.get("password").setValue("");
          this.registerForm.get("password").setErrors({
            incorrect: false
          });

          this.registerForm.get("confirm_password").setValue("");
          this.registerForm.get("confirm_password").setErrors({
            incorrect: false
          });
          this.error.password = null;
          this.error.confirm_password = null;
          this.toastrService.error(error.message);
        }
      );
    }
  }

  displayErrorMessage(
    formControlName: any,
    textName: string,
    confirmPassword = false
  ) {
    if (formControlName.getError("required")) {
      return (this.error[formControlName] = this.transformMessage(
        textName,
        "required"
      ));
    }
    if (formControlName.getError("pattern")) {
      return (this.error[formControlName] = this.transformMessage(
        textName,
        "pattern"
      ));
    }
    if (formControlName.getError("minlength")) {
      return (this.error[formControlName] = this.transformMessage(
        textName,
        "minLength",
        formControlName.getError("minlength").requiredLength
      ));
    }
    if (formControlName.getError("invalidRecaptcha")) {
      return (this.error[formControlName] = this.transformMessage(
        textName,
        "invalidRecaptcha"
      ));
    }
    if (confirmPassword == true) {
      if (this.registerForm.get("password").value != formControlName.value) {
        formControlName.setErrors({
          incorrect: true
        });
        return (this.error[formControlName] = this.transformMessage(
          textName,
          "confirm_password"
        ));
      }
    }
  }

  transformMessage(textName: string, errorType: string, minLength = 0) {
    if (minLength > 0) {
      return (
        textName + " " + this.errorList[errorType] + " " + minLength + " digits"
      );
    }
    return textName + " " + this.errorList[errorType];
  }
}
