<header class="vsure-header">
  <div class="container">
    <div class="row">
      <div class="col-xs-2 col-sm-4 hidden-md hidden-lg">
        <div class="burger-menu">
          <a
            href="javascript:void(0)"
            data-toggle="collapse"
            data-target="#vsure-navbar"
            onClick="sendEvent('Menu', 'Click', 'Burger menu');"
          >
            <i class="fa fa-bars" aria-hidden="true"></i>
          </a>
        </div>
      </div>
      <div class="col-md-5 hidden-xs hidden-sm">
        <ul class="top-left-nav top-nav">
          <li>
            <a
              class="smooth-scroll"
              href="#about"
              onClick="sendEvent('Menu', 'Click', 'About Vsure');"
            >
              About
            </a>
          </li>
          <li>
            <a
              class="smooth-scroll"
              href="#works"
              onClick="sendEvent('Menu', 'Click', 'works');"
            >
              How it Works
            </a>
          </li>
          <li>
            <a
              class="smooth-scroll"
              href="#offer"
              onClick="sendEvent('Burger-Menu', 'Click', 'Blog');"
              target="_blank"
            >
              What We Offer
            </a>
          </li>
          <li>
            <a
              class="smooth-scroll"
              href="#partners"
              onClick="sendEvent('Menu', 'Click', 'Team');"
            >
              Partners
            </a>
          </li>
          <!-- <li>
                        <a
                            class="smooth-scroll"
                            href="#buynow"
                            onClick="sendEvent('Menu', 'Click', 'Team');"
                        >
                            Buy Now
                        </a>
                    </li> -->
          <li>
            <a
              class="smooth-scroll"
              href="/team"
              onClick="sendEvent('Menu', 'Click', 'Team');"
            >
              Team
            </a>
          </li>
          <li>
            <a
              class="smooth-scroll"
              href="#contact"
              onClick="sendEvent('Menu', 'Click', 'Team');"
              >Contact Us</a
            >
          </li>
        </ul>
      </div>
      <div
        class="col-xs-8 col-sm-4 col-md-2 text-center logo"
        style="padding-top:20px;"
      >
        <a
          href="index.html"
          onClick="ga('send', 'event', 'landing page | home', 'mainmenu', 'logo');"
        >
          <img
            src="assets/img/vsure_logo.svg"
            width="auto"
            height="64"
            class="black_logo"
          />
          <img
            src="assets/img/vsure_white.svg"
            width="auto"
            height="64"
            class="white_logo"
          />
        </a>
      </div>
      <div class="col-sm-4 col-md-5 hidden-xs">
        <ul class="top-nav">
          <li class="col-xs-1 pull-right">
            <a href="https://www.facebook.com/vsure.life/" target="_blank">
              <i class="fa fa-facebook"></i>
              <span class="sr-only">Facebook</span>
            </a>
          </li>
          <!-- <li class="col-xs-1 pull-right">
            <a href="#" target="_blank"><i class="fa fa-twitter"></i><span class="sr-only">Twitter</span></a>
          </li> -->
          <li class="col-xs-1 pull-right">
            <a
              href="https://www.linkedin.com/company/vsuredotcom"
              target="_blank"
            >
              <i class="fa fa-linkedin"></i>
              <span class="sr-only">LinkedIn</span>
            </a>
          </li>
          <!-- <li class="col-xs-1 pull-right">
            <a href="https://www.instagram.com/vsure.life/" target="_blank"><i class="fa fa-instagram"></i><span class="sr-only">Instagram</span></a>
          </li> -->
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 hidden-md hidden-lg">
        <div class="collapsing vsure-navbar" id="vsure-navbar">
          <div class="container">
            <ul class="col-xs-12">
              <li>
                <a
                  class="smooth-scroll"
                  href="#about"
                  onClick="sendEvent('Burger-Menu', 'Click', 'About Vsure');"
                >
                  About
                </a>
              </li>
              <li>
                <a
                  class="smooth-scroll"
                  href="#works"
                  onClick="sendEvent('Menu', 'Click', 'works');"
                >
                  How it Works
                </a>
              </li>

              <li>
                <a
                  class="smooth-scroll"
                  href="#offer"
                  onClick="sendEvent('Burger-Menu', 'Click', 'Blog');"
                  target="_blank"
                >
                  What We Offer
                </a>
              </li>

              <li>
                <a
                  class="smooth-scroll"
                  href="#partners"
                  onClick="sendEvent('Menu', 'Click', 'Team');"
                >
                  Partners
                </a>
              </li>
              <li>
                <a
                  class="smooth-scroll"
                  href="#buynow"
                  onClick="sendEvent('Menu', 'Click', 'Team');"
                >
                  Buy Now
                </a>
              </li>
              <li>
                <a
                  class="smooth-scroll"
                  href="/team"
                  onClick="sendEvent('Menu', 'Click', 'Team');"
                >
                  Team
                </a>
              </li>
              <li>
                <a
                  class="smooth-scroll"
                  href="/news"
                  onClick="sendEvent('Menu', 'Click', 'Team');"
                >
                  News & Events
                </a>
              </li>
            </ul>
            <ul class="col-xs-12 hidden-sm sub-nav">
              <li>
                <a
                  class="smooth-scroll"
                  target="”_blank”"
                  href="/assets/pdf/privacy-policy.pdf"
                  onClick="sendEvent('Footer', 'Click', 'Privacy Policy');"
                >
                  Privacy Policy
                </a>
              </li>
            </ul>
            <div class="social col-xs-12 hidden-sm">
              <h6>Follow us on Social Media</h6>
              <ul class>
                <li class="col-xs-1 pull-right">
                  <a
                    href="https://www.facebook.com/vsure.life/"
                    target="_blank"
                  >
                    <i class="fa fa-facebook"></i>
                    <span class="sr-only">Facebook</span>
                  </a>
                </li>
                <!-- <li class="col-xs-1 pull-right">
                  <a href="#" target="_blank"><i class="fa fa-twitter"></i><span class="sr-only">Twitter</span></a>
                </li> -->
                <li class="col-xs-1 pull-right">
                  <a
                    href="https://www.linkedin.com/company/vsuredotcom"
                    target="_blank"
                  >
                    <i class="fa fa-linkedin"></i>
                    <span class="sr-only">LinkedIn</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
<style>
  .full-width-image-2 {
    background: url(assets/img/medical2.jpg) no-repeat center center;
    background-size: cover;
    text-align: center;
    height: 300px;
  }
</style>

<body>
  <div class="full-width-image-2"></div>

  <section class="bg-alternate ways" id="partners">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <h2 style="color:#ef5193">HOME INSURANCE</h2>
        </div>
      </div>
      <div class="row way-holder ">
        <div class="col-md-4 col-xs-12 way">
          <div style="border: 1px solid #8c8b8b; padding-top: 10px;">
            <img
              src="assets/img/alliaza.png"
              class="rounded float-left"
              alt="Clear"
              onClick="sendEvent('ABetterWay', 'Click', 'Clear');"
            />
            <h3
              class="title margin-top-15"
              onClick="sendEvent('ABetterWay', 'Click', 'Clear');"
            >
              Smart Home Cover
            </h3>
            <div class="content-holder">
              <div class="details content margin-top-20">
                <h3
                  style="text-align: left;    line-height: 23px; font-size: 14px;"
                >
                  A new kind of home insurance that offers flexibility for your
                  peace of mind. This plan gives you the freedom to choose and
                  customise based on your needs and wants.
                </h3>
                <h3
                  style="text-align: left;    line-height: 23px; font-size: 14px;"
                >
                  This plan gives you the freedom to choose and customise based
                  on your wants, thus, helping you save more on premiums. Be it
                  your home sweet home or a rental property, you can rest well
                  knowing it is protected with Smart Home Cover.
                </h3>
              </div>
              <div style="margin-top: 158px; "></div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-xs-12 way">
          <div style="border: 1px solid #8c8b8b;">
            <h3
              class="title margin-top-15"
              onClick="sendEvent('ABetterWay', 'Click', 'Fair');"
            >
              Key Benefits
            </h3>
            <div class="content-holder">
              <div class="details content margin-top-20">
                <ul style="text-align: left">
                  <p>
                    HouseOwner (Building) Covers your residential building
                    against damage.
                  </p>
                  <p>
                    HouseHolder (Content) Covers your household contents and
                    personal effects.
                  </p>
                  <p>
                    Mortgage Loan Installment Protection Covers your monthly
                    mortgage loan installment in the event that your house is
                    deemed temporarily uninhabitable due to insured events or
                    the insured suffers accidental death or permanent
                    disablement.
                  </p>
                  <p>
                    HomeFix Covers home repairs and maintenance.
                  </p>
                  <p>
                    Landlord Insurance Covers malicious damage by tenant, tenant
                    runaway and legal fees for letter of demand.
                  </p>
                </ul>
              </div>
              <div style="margin-top: 0px;"></div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-xs-12 way">
          <div style="border: 1px solid #8c8b8b; padding: 10px;">
            <h3
              class="title margin-top-15"
              onClick="sendEvent('ABetterWay', 'Click', 'Fair');"
            >
              Buy Now
            </h3>
            <br />
            <form
              [formGroup]="formGroup"
              (ngSubmit)="onSubmit(formGroup.value)"
              method="POST"
            >
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  formControlName="name"
                  placeholder="*Full Name"
                  required
                />
              </div>
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  id="email"
                  formControlName="email"
                  placeholder=" *Email"
                  required
                />
              </div>
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  id="referral"
                  formControlName="referral"
                  placeholder="Referral Code - Optional"
                  [readonly]="readonly"
                />
              </div>
              <recaptcha formControlName="myRecaptcha"></recaptcha>

              <div class="form-check" style="text-align: left">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="exampleCheck1"
                  [checked]="checkBoxStatus"
                  (change)="checkBoxStatus = !checkBoxStatus"
                  required
                />
                <label class="form-check-label" for="exampleCheck1">
                  &nbsp; I agree to the
                  <a href="/assets/pdf/terms-of-use.pdf">Terms</a>
                  &
                  <a href="/assets/pdf/privacy-policy.pdf">Privacy policies.</a>
                </label>
              </div>

              <input
                type="submit"
                class="btn"
                [disabled]="formGroup.invalid || !checkBoxStatus"
                value="Submit"
                onclick="window.open('https://getquote.allianz.com.my/smart-home-cover/get-info?utm_source=VSLIFE')"
              />
              <br />
              <small style="margin-top: 15px;"
                >This product is underwritten by</small
              >
              <br />
              <img style="margin-top: 10px;" src="assets/img/alliaza.png" />
            </form>
            <div style="margin-top: 60px; "></div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="bg-alternate ways">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <!-- <h2 style="color:#ef5193; text-align: left">eLife Protector+
              </h2> -->
        </div>
      </div>

      <div class="contant" style="text-align: left">
        <h2>Product</h2>
        <span
          >A house is more than just a costly investment. For some, it’s your
          private haven, a place you call home that holds everything that is
          precious to you. And for some, it’s a valuable source of rental
          income. But whatever it is, it makes perfect sense to safeguard your
          house and its contents against damage or loss that might be
          financially devastating to you. </span
        ><br /><br />
        <span
          >Introducing Smart Home Cover, a new kind of home insurance that
          offers flexibility for your peace of mind. Consisting of five
          components namely HouseOwner (Building), HouseHolder (Content),
          Mortgage Loan Installment Protection, HomeFix and Landlord Insurance,
          this plan gives you the freedom to choose and customize based on your
          needs and wants, thus, helping you to save more on premiums. Be it
          your home sweet home or a rental property, you can rest well knowing
          it is protected with Smart Home Cover. For more product info, click
          <a
            target="_blank"
            href="https://www.allianz.com.my/documents/144671/566170/Allianz+SmartHomeCover+290319+R3+Web.pdf/0f92a487-af02-494c-b14c-52ad8e356139"
            style="color:#ef5193"
          >
            here.</a
          >
        </span>
        <br /><br />
        <h2>Benefit / Coverage</h2>

        <ol type="I">
          <li>Covers your residential building against damage.</li>
          <li>Covers your household contents and personal effects.</li>
          <li>
            Covers your monthly mortgage loan instalment in the event that your
            house is deemed temporarily uninhabitable due to insured events or
            the insured suffers accidental death or permanent disablement.
          </li>
          <li>Covers home repairs and maintenance.</li>
          <li>
            Covers malicious damage by tenant, tenant runaway and legal fees for
            letter of demand.
          </li>
          <li>
            Understand more benefits at
            <a
              target="_blank"
              href="https://www.allianz.com.my/documents/144671/554953/PDS_Smart+Home+Cover+%28ENG%29100717.pdf/f15fd71d-cac7-4c7f-8859-1d663f4ea5e0"
              style="color:#ef5193"
              >here</a
            >
          </li>
        </ol>

        <br />
        <h2>Claim</h2>
        <ol>
          <li>Call 1300-22-5542 for assistance.</li>
          <li>
            Download the
            <a href="https://www.allianz.com.my/general-insurance-claims-form"
              >Claim Forms.
            </a>
          </li>

          <li>Report to Allianz.</li>
          <li>Submit required document.</li>
        </ol>

        <br />

        <br />

        <span
          >Need more information? Call 1300-22-5542 or email to
          customer.service@allianz.com.my.</span
        >
        <!-- <p><a href="/life" style="color:#ef5193;">eLife Protector+ </a></p> -->
      </div>
    </div>
  </section>
  <app-footer></app-footer>
</body>
