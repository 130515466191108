<header class="vsure-header">
  <div class="container">
    <div class="row">
      <div class="col-xs-2 col-sm-4 hidden-md hidden-lg">
        <div class="burger-menu">
          <a href="javascript:void(0)" data-toggle="collapse" data-target="#vsure-navbar"
            onClick="sendEvent('Menu', 'Click', 'Burger menu');">
            <i class="fa fa-bars" aria-hidden="true"></i>
          </a>
        </div>
      </div>
      <div class="col-md-5 hidden-xs hidden-sm">
        <ul class="top-left-nav top-nav">
          <li>
            <a class="smooth-scroll" href="#about" onClick="sendEvent('Menu', 'Click', 'About Vsure');">
              About
            </a>
          </li>
          <li>
            <a class="smooth-scroll" href="#works" onClick="sendEvent('Menu', 'Click', 'works');">
              How it
              Works
            </a>
          </li>
          <li>
            <a class="smooth-scroll" href="#offer" onClick="sendEvent('Burger-Menu', 'Click', 'Blog');" target="_blank">
              What
              We Offer
            </a>
          </li>
          <li>
            <a class="smooth-scroll" href="#partners" onClick="sendEvent('Menu', 'Click', 'Team');">
              Partners
            </a>
          </li>
          <!-- <li>
                        <a
                            class="smooth-scroll"
                            href="#buynow"
                            onClick="sendEvent('Menu', 'Click', 'Team');"
                        >
                            Buy Now
                        </a>
                    </li> -->
          <li>
            <a class="smooth-scroll" href="/team" onClick="sendEvent('Menu', 'Click', 'Team');">
              Team
            </a>
          </li>
          <li>
            <a class="smooth-scroll" href="#contact" onClick="sendEvent('Menu', 'Click', 'Team');">Contact Us</a>
          </li>
        </ul>
      </div>
      <div class="col-xs-8 col-sm-4 col-md-2 text-center logo" style="padding-top:20px;">
        <a href="index.html" onClick="ga('send', 'event', 'landing page | home', 'mainmenu', 'logo');">
          <img src="assets/img/vsure_logo.svg" width="auto" height="64" class="black_logo">
          <img src="assets/img/vsure_white.svg" width="auto" height="64" class="white_logo">
        </a>
      </div>
      <div class="col-sm-4 col-md-5 hidden-xs">
        <ul class="top-nav">
          <li class="col-xs-1 pull-right">
            <a href="https://www.facebook.com/vsure.life/" target="_blank">
              <i class="fa fa-facebook"></i>
              <span class="sr-only">Facebook</span>
            </a>
          </li>
          <!-- <li class="col-xs-1 pull-right">
            <a href="#" target="_blank"><i class="fa fa-twitter"></i><span class="sr-only">Twitter</span></a>
          </li> -->
          <li class="col-xs-1 pull-right">
            <a href="https://www.linkedin.com/company/vsuredotcom" target="_blank">
              <i class="fa fa-linkedin"></i>
              <span class="sr-only">LinkedIn</span>
            </a>
          </li>
          <!-- <li class="col-xs-1 pull-right">
            <a href="https://www.instagram.com/vsure.life/" target="_blank"><i class="fa fa-instagram"></i><span class="sr-only">Instagram</span></a>
          </li> -->
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 hidden-md hidden-lg">
        <div class="collapsing vsure-navbar" id="vsure-navbar">
          <div class="container">
            <ul class="col-xs-12">
              <li>
                <a class="smooth-scroll" href="#about" onClick="sendEvent('Burger-Menu', 'Click', 'About Vsure');">
                  About
                </a>
              </li>
              <li>
                <a class="smooth-scroll" href="#works" onClick="sendEvent('Menu', 'Click', 'works');">
                  How
                  it Works
                </a>
              </li>

              <li>
                <a class="smooth-scroll" href="#offer" onClick="sendEvent('Burger-Menu', 'Click', 'Blog');"
                  target="_blank">
                  What
                  We Offer
                </a>
              </li>

              <li>
                <a class="smooth-scroll" href="#partners" onClick="sendEvent('Menu', 'Click', 'Team');">
                  Partners
                </a>
              </li>
              <li>
                <a class="smooth-scroll" href="#buynow" onClick="sendEvent('Menu', 'Click', 'Team');">
                  Buy
                  Now
                </a>
              </li>
              <li>
                <a class="smooth-scroll" href="/team" onClick="sendEvent('Menu', 'Click', 'Team');">
                  Team
                </a>
              </li>
              <li>
                <a class="smooth-scroll" href="/news" onClick="sendEvent('Menu', 'Click', 'Team');">
                  News & Events
                </a>
              </li>
            </ul>
            <ul class="col-xs-12 hidden-sm sub-nav">
              <li>
                <a class="smooth-scroll" target=”_blank” href="/assets/pdf/privacy-policy.pdf"
                  onClick="sendEvent('Footer', 'Click', 'Privacy Policy');">
                  Privacy
                  Policy
                </a>
              </li>
            </ul>
            <div class="social col-xs-12 hidden-sm">
              <h6>Follow us on Social Media</h6>
              <ul class>
                <li class="col-xs-1 pull-right">
                  <a href="https://www.facebook.com/vsure.life/" target="_blank">
                    <i class="fa fa-facebook"></i>
                    <span class="sr-only">Facebook</span>
                  </a>
                </li>
                <!-- <li class="col-xs-1 pull-right">
                  <a href="#" target="_blank"><i class="fa fa-twitter"></i><span class="sr-only">Twitter</span></a>
                </li> -->
                <li class="col-xs-1 pull-right">
                  <a href="https://www.linkedin.com/company/vsuredotcom" target="_blank">
                    <i class="fa fa-linkedin"></i>
                    <span class="sr-only">LinkedIn</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
<style>
  .full-width-image-2 {
    background: url(assets/img/medical.jpg) no-repeat center center;
    background-size: cover;
    text-align: center;
    height: 300px;
  }

  li {
    display: block;
  }

</style>

<body>
  <!-- <section class="bg-alternate ways" id="buynow" style="background-color">

      <div class="full-width-image-2">
         
      </div>


  </section> -->
  <br>
  <br>
  <br>
  <section class="team padding-top-50 " id="team">
    <div class="container">
      <div class="row">
        <h2 class="col-xs-12 margin-bottom-20">The VSure.life Team</h2>
        <div class="text margin-bottom-70">
          We are ordinary people aiming to serve ASEAN communities with
          <br>
          Insurance they truly deserve
        </div>
      </div>
    </div>
    <div id="accordion-team" class="visible-xs visible-sm">

      <div class="panel team-member">
        <div class="container preview clearfix" data-parent="#accordion-team" data-toggle="collapse"
          data-target="#collapseTeamMember-1" aria-expanded="false" aria-controls="collapseTeamMember-1">
          <div class="row">
            <div class="col-xs-12" onClick="sendEvent('Bios', 'Click', 'Ben');">
              <div>
                <img src="assets/img/Eddy_Wong.jpg" class="img-circle pull-left">
                <p class="name pull-left">Eddy Wong</p>
              </div>
              <div class="pull-right arrow-toggle">
                <i class="fa fa-chevron-down"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="vsure-teal-bg collapse drop-shadow" id="collapseTeamMember-1">
          <div class="container">
            <div class="row">
              <div class="col-xs-12 details">
                <p class="name">Co-Founder/CEO/MD</p>
                <p>
                  More than 22 Years of Corporate & Commercial Management & People Leadership experience with 3 MNCs
                  (Prudential Asia / WorleyParsons / Experian). Vast experience in the areas of global software
                  development & delivery centre of excellence(COE)/ shared services/ BPO/ KPO, financial systems,
                  enterprise performance management (EPM), governance & controls, auditing and risk consulting ranging
                  from Information & Data Services, Financial Services (Insurance, Banking, Asset Management),
                  Constructions and Property Development, Manufacturing, Retail and Trading Services, Facilities
                  Management, Oil and Gas, IT Services and Integrations, Corporate Services and Financial
                  Intermediaries.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="panel team-member">
        <div class="container preview clearfix" data-parent="#accordion-team" data-toggle="collapse"
          data-target="#collapseTeamMember-0" aria-expanded="false" aria-controls="collapseTeamMember-0">
          <div class="row">
            <div class="col-xs-12" onClick="sendEvent('Bios', 'Click', 'Andrew');">
              <div>
                <img src="assets/img/Jason_Ho.jpg" class="img-circle pull-left">
                <p class="name pull-left">Jason Ho</p>
              </div>
              <div class="pull-right arrow-toggle">
                <i class="fa fa-chevron-down"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="vsure-teal-bg collapse drop-shadow" id="collapseTeamMember-0">
          <div class="container">
            <div class="row">
              <div class="col-xs-12 details">
                <p class="name">Co-Founder/COO</p>
                <p>
                  More than 25 years of experience in the Information Technology industry (with different MNCs –
                  Siemens, Logica, Experian)
                  covering Global Product Software Development & Delivery Centre of Excellence, Global IT Service
                  Delivery, Project Management,
                  IT Outsourcing, Business Application Implementation and Roll-out covering a range of business domains
                  from Credit Risk Management,
                  Fleet Cards Management, Planning Permission and Building Control, Customer Information and Billing
                  System, Document / Content
                  Management and Workflow System in a number of different industries from Banking and Finance, Public
                  Utilities (Energy and
                  Water), Government agencies – City and Town Councils, Forestry and Environment, Oil and Gas.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="panel team-member">
        <div class="container preview clearfix" data-parent="#accordion-team" data-toggle="collapse"
          data-target="#collapseTeamMember-3" aria-expanded="false" aria-controls="collapseTeamMember-3">
          <div class="row">
            <div class="col-xs-12" onClick="sendEvent('Bios', 'Click', 'Dana');">
              <div>
                <img src="assets/img/Jason_Wong.jpg" class="img-circle pull-left">
                <p class="name pull-left">Jason Wong</p>
              </div>
              <div class="pull-right arrow-toggle">
                <i class="fa fa-chevron-down"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="vsure-teal-bg collapse drop-shadow" id="collapseTeamMember-3">
          <div class="container">
            <div class="row">
              <div class="col-xs-12 details">
                <p class="name">Co-Founder/CMO</p>
                <p>
                  Jason is currently the Biz Development Director of VenturionTech Global S/B. He has over 16 years of
                  experience in IT sales, PMO, software development and delivery with Select-TV, CSA Bhd and Oneworks
                  S/B. His early involvement in the insurance industry was with the motor insurance in regards to motor
                  vehicle digital claims process flow. He was also one of the key driver behind the success of an
                  APICTA award winning startup.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="panel team-member">
        <div class="container preview clearfix" data-parent="#accordion-team" data-toggle="collapse"
          data-target="#collapseTeamMember-2" aria-expanded="false" aria-controls="collapseTeamMember-2">
          <div class="row">
            <div class="col-xs-12" onClick="sendEvent('Bios', 'Click', 'Chris');">
              <div>
                <img src="assets/img/Sree_Murthi.jpg" class="img-circle pull-left">
                <p class="name pull-left">Sree Murthi</p>
              </div>
              <div class="pull-right arrow-toggle">
                <i class="fa fa-chevron-down"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="vsure-teal-bg collapse drop-shadow" id="collapseTeamMember-2">
          <div class="container">
            <div class="row">
              <div class="col-xs-12 details">
                <p class="name">Co-Founder/CTO</p>
                <p>
                  With more than 16 years of Information Technology and Software development , Sree fits right in with
                  V-sure vision to become Asia's No.1 on-demand insurance provider. His vast knowledge in the Ai,
                  Blockchain and Business intelligent.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="panel team-member">
        <div class="container preview clearfix" data-parent="#accordion-team" data-toggle="collapse"
          data-target="#collapseTeamMember-5" aria-expanded="false" aria-controls="collapseTeamMember-5">
          <div class="row">
            <div class="col-xs-12" onClick="sendEvent('Bios', 'Click', 'Greg');">
              <div>
                <img src="assets/img/Jerry_Yeoh.jpg" class="img-circle pull-left">
                <p class="name pull-left">Jerry Yeoh</p>
              </div>
              <div class="pull-right arrow-toggle">
                <i class="fa fa-chevron-down"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="vsure-teal-bg collapse drop-shadow" id="collapseTeamMember-5">
          <div class="container">
            <div class="row">
              <div class="col-xs-12 details">
                <p class="name">Co-Founder</p>
                <p>
                  Esteemed Finance Professional (Regional Financial Controllership) with vast experience in various
                  industries namely Information Services, IT, Media & Advertising, Oil & Gas, Event Management, Trading
                  as well as Auditing. With strong sense of responsibility, hands on management & excellent
                  communication skills of over 20 years.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="panel team-member">
        <div class="container preview clearfix" data-parent="#accordion-team" data-toggle="collapse"
          data-target="#collapseTeamMember-4" aria-expanded="false" aria-controls="collapseTeamMember-4">
          <div class="row">
            <div class="col-xs-12" onClick="sendEvent('Bios', 'Click', 'Graham');">
              <div>
                <img src="assets/img/Alston_Yong.jpg" class="img-circle pull-left">
                <p class="name pull-left">Alston Yong</p>
              </div>
              <div class="pull-right arrow-toggle">
                <i class="fa fa-chevron-down"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="vsure-teal-bg collapse drop-shadow" id="collapseTeamMember-4">
          <div class="container">
            <div class="row">
              <div class="col-xs-12 details">
                <p class="name">Co-Founder</p>
                <p>
                  Alston is a regional pricing actuary in one of the top global reinsurance company. He is currently
                  based in Singapore. He has over 4 years industry experience, specialist in General Insurance. He has
                  also engaging with various insurtech projects, like operation automation, big data in insurance, and
                  market studies on liberalisation of property and motor insurance in Malaysia.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="panel team-member">
        <div class="container preview clearfix" data-parent="#accordion-team" data-toggle="collapse"
          data-target="#collapseTeamMember-7" aria-expanded="false" aria-controls="collapseTeamMember-7">
          <div class="row">
            <div class="col-xs-12" onClick="sendEvent('Bios', 'Click', 'Lachlan');">
              <div>
                <img src="assets/img/Shi_Hao.jpg" class="img-circle pull-left">
                <p class="name pull-left">Shi Hao</p>
              </div>
              <div class="pull-right arrow-toggle">
                <i class="fa fa-chevron-down"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="vsure-teal-bg collapse drop-shadow" id="collapseTeamMember-7">
          <div class="container">
            <div class="row">
              <div class="col-xs-12 details">
                <p class="name">Co-Founder</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="panel team-member">
        <div class="container preview clearfix" data-parent="#accordion-team" data-toggle="collapse"
          data-target="#collapseTeamMember-6" aria-expanded="false" aria-controls="collapseTeamMember-6">
          <div class="row">
            <div class="col-xs-12" onClick="sendEvent('Bios', 'Click', 'Jeremy');">
              <div>
                <img src="assets/img/Kah_Seong.jpg" class="img-circle pull-left">
                <p class="name pull-left">Kah Seong</p>
              </div>
              <div class="pull-right arrow-toggle">
                <i class="fa fa-chevron-down"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="vsure-teal-bg collapse drop-shadow" id="collapseTeamMember-6">
          <div class="container">
            <div class="row">
              <div class="col-xs-12 details">
                <p class="name">Co-Founder</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="panel team-member">
        <div class="container preview clearfix" data-parent="#accordion-team" data-toggle="collapse"
          data-target="#collapseTeamMember-8" aria-expanded="false" aria-controls="collapseTeamMember-8">
          <div class="row">
            <div class="col-xs-12" onClick="sendEvent('Bios', 'Click', 'Lincoln');">
              <div>
                <img src="assets/img/Alex_Chi.jpg" class="img-circle pull-left">
                <p class="name pull-left">Alex Chi</p>
              </div>
              <div class="pull-right arrow-toggle">
                <i class="fa fa-chevron-down"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="vsure-teal-bg collapse drop-shadow" id="collapseTeamMember-8">
          <div class="container">
            <div class="row">
              <div class="col-xs-12 details">
                <p class="name">Co-Founder</p>
                <!-- <p> Worked as Head of Operations for Hitwise, an Australia internet intelligence startup, acquired by
                  Experian on 2007. Rich experience in consumer insights and digital transformation expert with 16
                  years of diverse experience in Fortune 500 companies namely Citigroup, Royal Dutch Shell and Hewlett
                  Packard.</p> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="team-members-md" class="visible-md visible-lg">
      <div class="team-row">
        <div class="container">
          <div class="row">
            <div class="col-md-12 padding-bottom-25">
              <div class="row">
                <div class="team-member">
                  <div class="col-md-offset-1 col-md-2">
                    <div class="img-holder">
                      <div class="team-member-overlay trigger" onClick="sendEvent('Bios', 'Click', 'Ben');">
                        Read Bio
                      </div>
                      <img src="assets/img/Eddy_Wong.jpg" class="img-circle img-responsive trigger">
                    </div>
                    <p class="name data-holder trigger" data-name="Co-Founder/CEO/MD" data-linkedin data-email
                      data-excerpt="<p>More than 22 Years of Corporate & Commercial Management & People Leadership experience with 3 MNCs (Prudential Asia / WorleyParsons / Experian). Vast experience in the areas of global software development & delivery centre of excellence(COE)/ shared services/ BPO/ KPO, financial systems, enterprise performance management (EPM), governance & controls, auditing and risk consulting ranging from Information & Data Services, Financial Services (Insurance, Banking, Asset Management), Constructions and Property Development, Manufacturing, Retail and Trading Services, Facilities Management, Oil and Gas, IT Services and Integrations, Corporate Services and Financial Intermediaries.</p>">
                      Eddy
                      Wong
                    </p>
                    <div class="triangle"></div>
                  </div>
                </div>

                <div class="team-member">
                  <div class="col-md-offset-2 col-md-2">
                    <div class="img-holder">
                      <div class="team-member-overlay trigger" onClick="sendEvent('Bios', 'Click', 'Andrew');">
                        Read Bio
                      </div>
                      <img src="assets/img/Jason_Ho.jpg" class="img-circle img-responsive trigger">
                    </div>
                    <p class="name data-holder trigger" data-name="Co-Founder/COO" data-linkedin data-email
                      data-excerpt="<p>More than 25 years of experience in the Information Technology industry (with different MNCs – Siemens, Logica, Experian)
                                          covering Global Product Software Development & Delivery Centre of Excellence, Global IT Service Delivery, Project Management,
                                          IT Outsourcing, Business Application Implementation and Roll-out covering a range of business domains from Credit Risk Management,
                                          Fleet Cards Management, Planning Permission and Building Control, Customer Information and Billing System, Document / Content
                                          Management and Workflow System in a number of different industries from Banking and Finance, Public Utilities (Energy and
                                          Water), Government agencies – City and Town Councils, Forestry and Environment, Oil and Gas.</p>">
                      Jason
                      Ho
                    </p>
                    <div class="triangle"></div>
                  </div>
                </div>

                <div class="team-member">
                  <div class="col-md-offset-2 col-md-2">
                    <div class="img-holder">
                      <div class="team-member-overlay trigger" onClick="sendEvent('Bios', 'Click', 'Dana');">
                        Read Bio
                      </div>
                      <img src="assets/img/Jason_Wong.jpg" class="img-circle img-responsive trigger">
                    </div>
                    <p class="name data-holder trigger" data-name="Co-Founder/CMO" data-linkedin data-email
                      data-excerpt="<p>Jason is currently the Biz Development Director of VenturionTech Global S/B. He has over 16 years of experience in IT sales, PMO, software development and delivery with Select-TV, CSA Bhd and Oneworks S/B. His early involvement in the insurance industry was with the motor insurance in regards to motor vehicle digital claims process flow. He was also one of the key driver behind the success of an APICTA award winning startup.</p>">
                      Jason
                      Wong
                    </p>
                    <div class="triangle"></div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="vsure-teal-bg collapse drop-shadow team-member-detail-holder">
          <div class="container">
            <div class="row">
              <div class="col-md-offset-1 col-md-10 details-md">
                <p class="name"></p>
                <div class="description"></div>
                <div>
                  <a class="btn btn-teal-inverse anchor-email" href="#" target="_blank"
                    onClick="sendEvent('Bios', 'GetInTouch', 'Chris');">
                    get
                    in touch
                  </a>
                  <a class="btn btn-teal-inverse anchor-linkedin" href="#" target="_blank"
                    onClick="sendEvent('Bios', 'LinkedIn', 'Chris');">
                    LinkedIn
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="team-row">
        <div class="container">
          <div class="row">
            <div class="col-md-12 padding-bottom-25">
              <div class="row">
                <div class="team-member">
                  <div class="col-md-offset-1 col-md-2">
                    <div class="img-holder">
                      <div class="team-member-overlay trigger" onClick="sendEvent('Bios', 'Click', 'Chris');">
                        Read Bio
                      </div>
                      <img src="assets/img/Sree_Murthi.jpg" class="img-circle img-responsive trigger">
                    </div>
                    <p class="name data-holder trigger" data-name="Co-Founder/CTO" data-linkedin data-email
                      data-excerpt="<p>With more than 16 years of Information Technology and Software development , Sree fits right in with V-sure vision to become Asia's No.1 on-demand insurance provider. His vast knowledge in the Ai, Blockchain and Business intelligent.</p>">
                      Sree
                      Murthi
                    </p>
                    <div class="triangle"></div>
                  </div>
                </div>

                <div class="team-member">
                  <div class="col-md-offset-2 col-md-2">
                    <div class="img-holder">
                      <div class="team-member-overlay trigger" onClick="sendEvent('Bios', 'Click', 'Greg');">
                        Read Bio
                      </div>
                      <img src="assets/img/Jerry_Yeoh.jpg" class="img-circle img-responsive trigger">
                    </div>
                    <p class="name data-holder trigger" data-name="Co-Founder" data-linkedin data-email
                      data-excerpt="<p>Esteemed Finance Professional (Regional Financial Controllership) with vast experience in various industries namely Information Services, IT, Media & Advertising, Oil & Gas, Event Management, Trading as well as Auditing. With strong sense of responsibility, hands on management & excellent communication skills of over 20 years.</p>">
                      Jerry
                      Yeoh
                    </p>
                    <div class="triangle"></div>
                  </div>
                </div>

                <div class="team-member">
                  <div class="col-md-offset-2 col-md-2">
                    <div class="img-holder">
                      <div class="team-member-overlay trigger" onClick="sendEvent('Bios', 'Click', 'Graham');">
                        Read Bio
                      </div>
                      <img src="assets/img/Alston_Yong.jpg" class="img-circle img-responsive trigger">
                    </div>
                    <p class="name data-holder trigger" data-name="Co-Founder" data-linkedin data-email
                      data-excerpt="<p>Alston is a regional pricing actuary in one of the top global reinsurance company. He is currently based in Singapore. He has over 4 years industry experience, specialist in General Insurance. He has also engaging with various insurtech projects, like operation automation, big data in insurance, and market studies on liberalisation of property and motor insurance in Malaysia.</p>">
                      Alston
                      Yong
                    </p>
                    <div class="triangle"></div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="vsure-teal-bg collapse drop-shadow team-member-detail-holder">
          <div class="container">
            <div class="row">
              <div class="col-md-offset-1 col-md-10 details-md">
                <p class="name"></p>
                <div class="description"></div>
                <div>
                  <a class="btn btn-teal-inverse anchor-email" href="#" target="_blank"
                    onClick="sendEvent('Bios', 'GetInTouch', 'Greg');">
                    get
                    in touch
                  </a>
                  <a class="btn btn-teal-inverse anchor-linkedin" href="#" target="_blank"
                    onClick="sendEvent('Bios', 'LinkedIn', 'Greg');">
                    LinkedIn
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="team-row">
        <div class="container">
          <div class="row">
            <div class="col-md-12 padding-bottom-25">
              <div class="row">
                <div class="team-member">
                  <div class="col-md-offset-1 col-md-2">
                    <div class="img-holder">
                      <div class="team-member-overlay trigger" onClick="sendEvent('Bios', 'Click', 'Lachlan');">
                        Read
                        Bio
                      </div>
                      <img src="assets/img/Shi_Hao.jpg" class="img-circle img-responsive trigger">
                    </div>
                    <p class="name data-holder trigger" data-name="Co-Founder" data-linkedin data-email
                      data-excerpt="<p></p>">
                      Shi Hao
                    </p>
                    <div class="triangle"></div>
                  </div>
                </div>
                <div class="team-member">
                  <div class="col-md-offset-2 col-md-2">
                    <div class="img-holder">
                      <div class="team-member-overlay trigger" onClick="sendEvent('Bios', 'Click', 'Jeremy');">
                        Read Bio
                      </div>
                      <img src="assets/img/Kah_Seong.jpg" class="img-circle img-responsive trigger">
                    </div>
                    <p class="name data-holder trigger" data-name="Co-Founder" data-linkedin data-email
                      data-excerpt="<p></p>">
                      Kah Seong
                    </p>
                    <div class="triangle"></div>
                  </div>
                </div>
                <div class="team-member">
                  <div class="col-md-offset-2 col-md-2">
                    <div class="img-holder">
                      <div class="team-member-overlay trigger" onClick="sendEvent('Bios', 'Click', 'Lincoln');">
                        Read
                        Bio
                      </div>
                      <img src="assets/img/Alex_Chi.jpg" class="img-circle img-responsive trigger">
                    </div>
                    <p class="name data-holder trigger" data-name="Co-Founder" data-linkedin data-email data-excerpt>
                      Alex
                      Chi
                    </p>
                    <div class="triangle"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="vsure-teal-bg collapse drop-shadow team-member-detail-holder">
          <div class="container">
            <div class="row">
              <div class="col-md-offset-1 col-md-10 details-md">
                <p class="name"></p>
                <div class="description"></div>
                <div>
                  <a class="btn btn-teal-inverse anchor-email" href="#" target="_blank"
                    onClick="sendEvent('Bios', 'GetInTouch', 'Lincoln');">
                    get
                    in touch
                  </a>
                  <a class="btn btn-teal-inverse anchor-linkedin" href="#" target="_blank"
                    onClick="sendEvent('Bios', 'LinkedIn', 'Lincoln');">
                    LinkedIn
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- --Advisory Board -->
  <section class="team padding-top-50 " id="team" style="background-color:#ececec">
    <div class="container">
      <div class="row">
        <h2 class="col-xs-12 margin-bottom-20">Advisory Board</h2>
        <!-- <div class="text margin-bottom-70"> We are ordinary people aiming to serve ASEAN communities with <br> Insurance they truly deserve </div> -->
      </div>
    </div>
    <div id="accordion-team" class="visible-xs visible-sm">

      <div class="panel team-member">
        <div class="container preview clearfix" data-parent="#accordion-team" data-toggle="collapse"
          data-target="#collapseTeamMember-001" aria-expanded="false" aria-controls="collapseTeamMember-1">
          <div class="row">
            <div class="col-xs-12" onClick="sendEvent('Bios', 'Click', 'Ben');">
              <div>
                <img src="assets/img/b1.jpg" class="img-circle pull-left">
                <p class="name pull-left">Wee-Meng, Thoo</p>
              </div>
              <div class="pull-right arrow-toggle">
                <i class="fa fa-chevron-down"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="vsure-teal-bg collapse drop-shadow" id="collapseTeamMember-001">
          <div class="container">
            <div class="row">
              <div class="col-xs-12 details">

                <p>
                  Head of Investments of Leonie Hill Capital
                </p>
                <p>
                  TMT veteran with >20 years of leadership leading fortune 500 companies
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="panel team-member">
        <div class="container preview clearfix" data-parent="#accordion-team" data-toggle="collapse"
          data-target="#collapseTeamMember-002" aria-expanded="false" aria-controls="collapseTeamMember-0">
          <div class="row">
            <div class="col-xs-12" onClick="sendEvent('Bios', 'Click', 'Andrew');">
              <div>
                <img src="assets/img/b2.jpg" class="img-circle pull-left">
                <p class="name pull-left">William Kuan</p>
              </div>
              <div class="pull-right arrow-toggle">
                <i class="fa fa-chevron-down"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="vsure-teal-bg collapse drop-shadow" id="collapseTeamMember-002">
          <div class="container">
            <div class="row">
              <div class="col-xs-12 details">

                <p>
                  Chairman of PT Avirst Insurance Indonesia, & Former CEO, New Markets for Prudential Asia
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="panel team-member">
        <div class="container preview clearfix" data-parent="#accordion-team" data-toggle="collapse"
          data-target="#collapseTeamMember-003" aria-expanded="false" aria-controls="collapseTeamMember-3">
          <div class="row">
            <div class="col-xs-12" onClick="sendEvent('Bios', 'Click', 'Dana');">
              <div>
                <img src="assets/img/b3.jpg" class="img-circle pull-left">
                <p class="name pull-left">Mohd. Atasha</p>
              </div>
              <div class="pull-right arrow-toggle">
                <i class="fa fa-chevron-down"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="vsure-teal-bg collapse drop-shadow" id="collapseTeamMember-003">
          <div class="container">
            <div class="row">
              <div class="col-xs-12 details">

                <p>
                  Business & Startup Adviser, Leading influencer of Digital Economy evolution in Malaysia
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="panel team-member">
        <div class="container preview clearfix" data-parent="#accordion-team" data-toggle="collapse"
          data-target="#collapseTeamMember-004" aria-expanded="false" aria-controls="collapseTeamMember-2">
          <div class="row">
            <div class="col-xs-12" onClick="sendEvent('Bios', 'Click', 'Chris');">
              <div>
                <img src="assets/img/b4.jpg" class="img-circle pull-left">
                <p class="name pull-left">Andrew Tan</p>
              </div>
              <div class="pull-right arrow-toggle">
                <i class="fa fa-chevron-down"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="vsure-teal-bg collapse drop-shadow" id="collapseTeamMember-004">
          <div class="container">
            <div class="row">
              <div class="col-xs-12 details">

                <p>
                  Executive Director, BDO, BOD of Laos Australian Chambers of Commerce
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="panel team-member">
        <div class="container preview clearfix" data-parent="#accordion-team" data-toggle="collapse"
          data-target="#collapseTeamMember-005" aria-expanded="false" aria-controls="collapseTeamMember-5">
          <div class="row">
            <div class="col-xs-12" onClick="sendEvent('Bios', 'Click', 'Greg');">
              <div>
                <img src="assets/img/b5.jpg" class="img-circle pull-left">
                <p class="name pull-left">Lau Sie Liang, FIA, FSAS, CiFI</p>
              </div>
              <div class="pull-right arrow-toggle">
                <i class="fa fa-chevron-down"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="vsure-teal-bg collapse drop-shadow" id="collapseTeamMember-005">
          <div class="container">
            <div class="row">
              <div class="col-xs-12 details">

                <p>
                  Chief Actuary, SCOR UK EMEA, APAC & former senior underwriter, Lloyds
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="panel team-member">
        <div class="container preview clearfix" data-parent="#accordion-team" data-toggle="collapse"
          data-target="#collapseTeamMember-006" aria-expanded="false" aria-controls="collapseTeamMember-4">
          <div class="row">
            <div class="col-xs-12" onClick="sendEvent('Bios', 'Click', 'Graham');">
              <div>
                <img src="assets/img/b6.jpg" class="img-circle pull-left">
                <p class="name pull-left">Nadiah Tan Abdullah</p>
              </div>
              <div class="pull-right arrow-toggle">
                <i class="fa fa-chevron-down"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="vsure-teal-bg collapse drop-shadow" id="collapseTeamMember-006">
          <div class="container">
            <div class="row">
              <div class="col-xs-12 details">

                <p>
                  Chief Human Resource Officer, SP Setia Group
                </p>
                <p>
                  Former HR/People Director Experian Msia & AirAsiaX
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="panel team-member">
        <div class="container preview clearfix" data-parent="#accordion-team" data-toggle="collapse"
          data-target="#collapseTeamMember-0012" aria-expanded="false" aria-controls="collapseTeamMember-4">
          <div class="row">
            <div class="col-xs-12" onClick="sendEvent('Bios', 'Click', 'Graham');">
              <div>
                <img src="assets/img/b7.jpg" class="img-circle pull-left">
                <p class="name pull-left">Azran Osman-Rani</p>
              </div>
              <div class="pull-right arrow-toggle">
                <i class="fa fa-chevron-down"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="vsure-teal-bg collapse drop-shadow" id="collapseTeamMember-0012">
          <div class="container">
            <div class="row">
              <div class="col-xs-12 details">

                <p>
                  Founder and CEO of Naluri Hidup. He was formerly iflix Group COO and CEO of iflix Malaysia. He was
                  also previously the Chief Executive Officer of AirAsia X
                </p>

              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div id="team-members-md" class="visible-md visible-lg">
      <div class="team-row">
        <div class="container">
          <div class="row">
            <div class="col-md-12 padding-bottom-25">
              <div class="row">
                <div class="team-member">
                  <div class="col-md-offset-1 col-md-2">
                    <div class="img-holder">
                      <div class="team-member-overlay trigger" onClick="sendEvent('Bios', 'Click', 'Ben');"></div>
                      <img src="assets/img/b1.jpg" class="img-circle img-responsive trigger">
                    </div>
                    <p class="name data-holder trigger" data-linkedin data-email data-excerpt="<p>Head of Investments of Leonie Hill Capital
                                          </p> <p>TMT veteran with >20 years of leadership leading fortune 500 companies
                                              </>">
                      Wee-Meng,
                      Thoo
                    </p>
                    <div class="triangle"></div>
                  </div>
                </div>

                <div class="team-member">
                  <div class="col-md-offset-2 col-md-2">
                    <div class="img-holder">
                      <div class="team-member-overlay trigger" onClick="sendEvent('Bios', 'Click', 'Andrew');"></div>
                      <img src="assets/img/b2.jpg" class="img-circle img-responsive trigger">
                    </div>
                    <p class="name data-holder trigger" data-linkedin data-email data-excerpt="<p>Chairman of PT Avirst Insurance Indonesia,  & Former CEO, New Markets for Prudential Asia 
                                          </p>">
                      William
                      Kuan
                    </p>
                    <div class="triangle"></div>
                  </div>
                </div>

                <div class="team-member">
                  <div class="col-md-offset-2 col-md-2">
                    <div class="img-holder">
                      <div class="team-member-overlay trigger" onClick="sendEvent('Bios', 'Click', 'Dana');"></div>
                      <img src="assets/img/b3.jpg" class="img-circle img-responsive trigger">
                    </div>
                    <p class="name data-holder trigger" data-linkedin data-email data-excerpt="<p>Business & Startup Adviser, Leading influencer of Digital Economy evolution in Malaysia
                                          </p>">
                      Mohd.
                      Atasha
                    </p>
                    <div class="triangle"></div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="vsure-teal-bg collapse drop-shadow team-member-detail-holder">
          <div class="container">
            <div class="row">
              <div class="col-md-offset-1 col-md-10 details-md">
                <p class="name"></p>
                <div class="description"></div>
                <div>
                  <a class="btn btn-teal-inverse anchor-email" href="#" target="_blank"
                    onClick="sendEvent('Bios', 'GetInTouch', 'Chris');">
                    get
                    in touch
                  </a>
                  <a class="btn btn-teal-inverse anchor-linkedin" href="#" target="_blank"
                    onClick="sendEvent('Bios', 'LinkedIn', 'Chris');">
                    LinkedIn
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="team-row">
        <div class="container">
          <div class="row">
            <div class="col-md-12 padding-bottom-25">
              <div class="row">
                <div class="team-member">
                  <div class="col-md-offset-1 col-md-2">
                    <div class="img-holder">
                      <div class="team-member-overlay trigger" onClick="sendEvent('Bios', 'Click', 'Chris');"></div>
                      <img src="assets/img/b4.jpg" class="img-circle img-responsive trigger">
                    </div>
                    <p class="name data-holder trigger" data-linkedin data-email data-excerpt="<p>Executive Director, BDO, BOD of Laos Australian Chambers of Commerce
                                          </p>">
                      Andrew
                      Tan
                    </p>
                    <div class="triangle"></div>
                  </div>
                </div>

                <div class="team-member">
                  <div class="col-md-offset-2 col-md-2">
                    <div class="img-holder">
                      <div class="team-member-overlay trigger" onClick="sendEvent('Bios', 'Click', 'Greg');"></div>
                      <img src="assets/img/b5.jpg" class="img-circle img-responsive trigger">
                    </div>
                    <p class="name data-holder trigger" data-linkedin data-email
                      data-excerpt="<p>Chief Actuary, SCOR UK EMEA, APAC & former senior underwriter, Lloyds</p>">
                      Lau
                      Sie Liang, FIA, FSAS, CiFI
                    </p>
                    <div class="triangle"></div>
                  </div>
                </div>

                <div class="team-member">
                  <div class="col-md-offset-2 col-md-2">
                    <div class="img-holder">
                      <!-- <div class="team-member-overlay trigger" onClick="sendEvent('Bios', 'Click', 'Graham');">Read Bio</div> -->
                      <img src="assets/img/b6.jpg" class="img-circle img-responsive trigger">
                    </div>
                    <p class="name data-holder trigger" data-linkedin data-email data-excerpt="<p>Chief Human Resource Officer, SP Setia Group
                                          </p><p>Former HR/People Director Experian Msia & AirAsiaX
                                            </p>">
                      Nadiah
                      Tan Abdullah
                    </p>
                    <div class="triangle"></div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="vsure-teal-bg collapse drop-shadow team-member-detail-holder">
          <div class="container">
            <div class="row">
              <div class="col-md-offset-1 col-md-10 details-md">
                <p class="name"></p>
                <div class="description"></div>
                <div>
                  <a class="btn btn-teal-inverse anchor-email" href="#" target="_blank"
                    onClick="sendEvent('Bios', 'GetInTouch', 'Greg');">
                    get
                    in touch
                  </a>
                  <a class="btn btn-teal-inverse anchor-linkedin" href="#" target="_blank"
                    onClick="sendEvent('Bios', 'LinkedIn', 'Greg');">
                    LinkedIn
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="team-row">
        <div class="container">
          <div class="row">
            <div class="col-md-12 padding-bottom-25">
              <div class="row">
                <div class="team-member">
                  <div class="col-md-offset-1 col-md-2">
                    <div class="img-holder">
                      <div class="team-member-overlay trigger" onClick="sendEvent('Bios', 'Click', 'Chris');"></div>
                      <img src="assets/img/b7.jpg" class="img-circle img-responsive trigger">
                    </div>
                    <p class="name data-holder trigger" data-linkedin data-email data-excerpt="<p>Founder and CEO of Naluri Hidup. He was formerly iflix Group COO and CEO of iflix Malaysia. He was also previously the Chief Executive Officer of AirAsia X
                                            </p>">
                      Azran
                      Osman-Rani
                    </p>
                    <div class="triangle"></div>
                  </div>
                </div>

                <div class="team-member">
                  <div class="col-md-offset-2 col-md-2">
                    <div class="img-holder">
                      <!-- <div class="team-member-overlay trigger" onClick="sendEvent('Bios', 'Click', 'Greg');"></div> -->
                      <!-- <img src="assets/img/b5.jpg" class="img-circle img-responsive trigger"> -->
                    </div>
                    <!-- <p class="name data-holder trigger" data-linkedin="" data-email="" data-excerpt="<p>Chief Actuary, SCOR UK EMEA, APAC & former senior underwriter, Lloyds</p>">Lau
                        Sie Liang, FIA, FSAS, CiFI
                      </p> -->
                    <div class="triangle"></div>
                  </div>
                </div>

                <div class="team-member">
                  <div class="col-md-offset-2 col-md-2">
                    <div class="img-holder">
                      <!-- <div class="team-member-overlay trigger" onClick="sendEvent('Bios', 'Click', 'Graham');">Read Bio</div> -->
                      <!-- <img src="assets/img/b6.jpg" class="img-circle img-responsive trigger"> -->
                    </div>
                    <!-- <p class="name data-holder trigger" data-linkedin="" data-email="" data-excerpt="<p>Chief Human Resource Officer, SP Setia Group
                                            </p><p>Former HR/People Director Experian Msia & AirAsiaX
                                              </p>">Nadiah
                        Tan Abdullah
                      </p> -->
                    <div class="triangle"></div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="vsure-teal-bg collapse drop-shadow team-member-detail-holder">
          <div class="container">
            <div class="row">
              <div class="col-md-offset-1 col-md-10 details-md">
                <p class="name"></p>
                <div class="description"></div>
                <!-- <div>
                    <a class="btn btn-teal-inverse anchor-email" href="#" target="_blank" onClick="sendEvent('Bios', 'GetInTouch', 'Greg');">get
                      in touch</a>
                    <a class="btn btn-teal-inverse anchor-linkedin" href="#" target="_blank" onClick="sendEvent('Bios', 'LinkedIn', 'Greg');">LinkedIn</a>
                  </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </section>
  <app-footer></app-footer>

</body>
