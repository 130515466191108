<header class="vsure-header">
  <div class="container">
    <div class="row">
      <div class="col-xs-2 col-sm-4 hidden-md hidden-lg">
        <div class="burger-menu">
          <a href="javascript:void(0)" data-toggle="collapse" data-target="#vsure-navbar"
            onClick="sendEvent('Menu', 'Click', 'Burger menu');">
            <i class="fa fa-bars" aria-hidden="true"></i>
          </a>
        </div>
      </div>
      <div class="col-md-5 hidden-xs hidden-sm">
        <ul class="top-left-nav top-nav">
          <li>
            <a class="smooth-scroll" href="#about" onClick="sendEvent('Menu', 'Click', 'About Vsure');">
              About
            </a>
          </li>
          <li>
            <a class="smooth-scroll" href="#works" onClick="sendEvent('Menu', 'Click', 'works');">
              How it
              Works
            </a>
          </li>
          <li>
            <a class="smooth-scroll" href="#offer" onClick="sendEvent('Burger-Menu', 'Click', 'Blog');" target="_blank">
              What We Offer
            </a>
          </li>
          <li>
            <a class="smooth-scroll" href="#partners" onClick="sendEvent('Menu', 'Click', 'Team');">
              Partners
            </a>
          </li>
          <!-- <li>
                        <a
                            class="smooth-scroll"
                            href="#buynow"
                            onClick="sendEvent('Menu', 'Click', 'Team');"
                        >
                            Buy Now
                        </a>
                    </li> -->
          <li>
            <a class="smooth-scroll" href="/team" onClick="sendEvent('Menu', 'Click', 'Team');">
              Team
            </a>
          </li>
          <li>
            <a class="smooth-scroll" href="#contact" onClick="sendEvent('Menu', 'Click', 'Team');">Contact Us</a>
          </li>
        </ul>
      </div>
      <div class="col-xs-8 col-sm-4 col-md-2 text-center logo" style="padding-top:20px;">
        <a href="index.html" onClick="ga('send', 'event', 'landing page | home', 'mainmenu', 'logo');">
          <img src="assets/img/vsure_logo.svg" width="auto" height="64" class="black_logo">
          <img src="assets/img/vsure_white.svg" width="auto" height="64" class="white_logo">
        </a>
      </div>
      <div class="col-sm-4 col-md-5 hidden-xs">
        <ul class="top-nav">
          <li class="col-xs-1 pull-right">
            <a href="https://www.facebook.com/vsure.life/" target="_blank">
              <i class="fa fa-facebook"></i>
              <span class="sr-only">Facebook</span>
            </a>
          </li>
          <!-- <li class="col-xs-1 pull-right">
                        <a href="#" target="_blank"><i class="fa fa-twitter"></i><span class="sr-only">Twitter</span></a>
                    </li> -->
          <li class="col-xs-1 pull-right">
            <a href="https://www.linkedin.com/company/vsuredotcom" target="_blank">
              <i class="fa fa-linkedin"></i>
              <span class="sr-only">LinkedIn</span>
            </a>
          </li>
          <!-- <li class="col-xs-1 pull-right">
                        <a href="https://www.instagram.com/vsure.life/" target="_blank"><i class="fa fa-instagram"></i><span
                                class="sr-only">Instagram</span></a>
                    </li> -->
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 hidden-md hidden-lg">
        <div class="collapsing vsure-navbar" id="vsure-navbar">
          <div class="container">
            <ul class="col-xs-12">
              <li>
                <a class="smooth-scroll" href="#about" onClick="sendEvent('Burger-Menu', 'Click', 'About Vsure');">
                  About
                </a>
              </li>
              <li>
                <a class="smooth-scroll" href="#works" onClick="sendEvent('Menu', 'Click', 'works');">
                  How
                  it Works
                </a>
              </li>

              <li>
                <a class="smooth-scroll" href="#offer" onClick="sendEvent('Burger-Menu', 'Click', 'Blog');"
                  target="_blank">
                  What We Offer
                </a>
              </li>

              <li>
                <a class="smooth-scroll" href="#partners" onClick="sendEvent('Menu', 'Click', 'Team');">
                  Partners
                </a>
              </li>
              <li>
                <a class="smooth-scroll" href="#buynow" onClick="sendEvent('Menu', 'Click', 'Team');">
                  Buy
                  Now
                </a>
              </li>
              <li>
                <a class="smooth-scroll" href="/team" onClick="sendEvent('Menu', 'Click', 'Team');">
                  Team
                </a>
              </li>
              <li>
                <a class="smooth-scroll" href="/news" onClick="sendEvent('Menu', 'Click', 'Team');">
                  News
                  & Events
                </a>
              </li>
            </ul>
            <ul class="col-xs-12 hidden-sm sub-nav">
              <li>
                <a class="smooth-scroll" target=”_blank” href="/assets/pdf/privacy-policy.pdf"
                  onClick="sendEvent('Footer', 'Click', 'Privacy Policy');">
                  Privacy
                  Policy
                </a>
              </li>
            </ul>
            <div class="social col-xs-12 hidden-sm">
              <h6>Follow us on Social Media</h6>
              <ul class>
                <li class="col-xs-1 pull-right">
                  <a href="https://www.facebook.com/vsure.life/" target="_blank">
                    <i class="fa fa-facebook"></i>
                    <span class="sr-only">Facebook</span>
                  </a>
                </li>
                <!-- <li class="col-xs-1 pull-right">
                                    <a href="#" target="_blank"><i class="fa fa-twitter"></i><span class="sr-only">Twitter</span></a>
                                </li> -->
                <li class="col-xs-1 pull-right">
                  <a href="https://www.linkedin.com/company/vsuredotcom" target="_blank">
                    <i class="fa fa-linkedin"></i>
                    <span class="sr-only">LinkedIn</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
<style>
  .full-width-image-2 {
    background: url(assets/img/medical1.jpg) no-repeat center center;
    background-size: cover;
    text-align: center;
    height: 300px;
  }

  li {
    display: block;
  }

</style>

<body>

  <section class="bg-alternate ways" id="partners">
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <h2>News & Events</h2>
    <div class="container">



      <div class="row" style="margin-top: 10px;">
        <div class="col-md-2"></div>
        <!-- START 1 May 2019 -->
        <div class="col-md-4">
          <div class="card mb-4 box-shadow">
            <img class="card-img-top img-thumbnail"
              data-src="holder.js/100px225?theme=thumb&amp;bg=55595c&amp;fg=eceeef&amp;text=Thumbnail"
              alt="Thumbnail [100%x225]" src="assets/img/news9.jpg" data-holder-rendered="true"
              style="height: 225px; width: 100%; display: block;">


            <div class="card-body">
              <p class="card-text">1 May 2019 to 30 June 2019. RM30 VSure.life Reward in conjunction with KLCCRG 3rd
                Anniversary “Color Run” on 5th May 2019 </p>
              <p class="card-text">
                <a href="assets/pdf/Terms and Conditions for VSure.life Reward in Conjunction with KLCCRG 3rd Anniversary Run.pdf"
                  target="_blank">
                  View Terms & Conditions
                </a>
              </p>
              <div class="d-flex justify-content-between align-items-center">
                <div class="btn-group">
                  <a href="assets/img/VSURE-KLCCRG 3rd ANNIVERSARY RUN_V2.png" target="_blank" type="button"
                    class="btn btn-sm btn-outline-secondary">read more...</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END 1 May 2019 -->

        <!-- START 20 Apr 2019. -->
        <div class="col-md-4">
          <div class="card mb-4 box-shadow">
            <img class="card-img-top img-thumbnail"
              data-src="holder.js/100px225?theme=thumb&amp;bg=55595c&amp;fg=eceeef&amp;text=Thumbnail"
              alt="Thumbnail [100%x225]" src="assets/img/news6.jpg" data-holder-rendered="true"
              style="height: 225px; width: 100%; display: block;">


            <div class="card-body">
              <p class="card-text">20 Apr 2019. VSure.life is proud to be associated with Mika Irfan (Horseback Archery
                Sportsman), representing Malaysia in Kazakhstan for the ASIAN YOUTH CHAMPIOSHIP AT THE NATIONAL
                EQUESTRIAN SPORTS (KOKPAR, AUDARYSPAK, TENGE ILU, JAMBY ATU) </p>
              <div class="d-flex justify-content-between align-items-center">
                <div class="btn-group">
                  <a href="https://www.facebook.com/vsure.life/posts/477029536170923" type="button"
                    class="btn btn-sm btn-outline-secondary" target="_blank">read more...</a>

                </div>

              </div>
            </div>
          </div>
        </div>
        <!-- END 20 Apr 2019. -->

        <div class="col-md-2"></div>
      </div>


      <div class="row" style="margin-top: 10px;">
        <div class="col-md-2"></div>
        <!-- START 7 Apr 2019.-->
        <div class="col-md-4">
          <div class="col-md-2"></div>
          <div class="card mb-4 box-shadow">
            <img class="card-img-top img-thumbnail" src="assets/img/news7.jpg" alt="Thumbnail [100%x225]"
              style="height: 225px; width: 100%; display: block;" data-holder-rendered="true">
            <div class="card-body">
              <p class="card-text">
                7 Apr 2019. VSURE-KLCCRG “SELFIE RUN” – Powered by VSure on KL Car Free Morning
              </p>
              <div class="d-flex justify-content-between align-items-center">
                <div class="btn-group" style="    margin-bottom: 10px;">
                  <a type="button" href="https://www.facebook.com/watch/?v=870888059910488"
                    class="btn btn-sm btn-outline-secondary" target="_blank">
                    read more...
                  </a>
                </div>
                <br>
              </div>
            </div>
          </div>
        </div>
        <!-- END 7 Apr 2019.-->

        <!-- START 11 Mar 2019 -->
        <div class="col-md-4">
          <div class="card mb-4 box-shadow">
            <img class="card-img-top img-thumbnail"
              data-src="holder.js/100px225?theme=thumb&amp;bg=55595c&amp;fg=eceeef&amp;text=Thumbnail"
              alt="Thumbnail [100%x225]" src="assets/img/news8.jpg" data-holder-rendered="true"
              style="height: 225px; width: 100%; display: block;">
            <div class="card-body">
              <p class="card-text">11 Mar 2019. VSure @ UNCDF Financial Innovation B40 Challenge – BootCamp #1
                of Insurance? </p>
              <div class="d-flex justify-content-between align-items-center">
                <div class="btn-group">
                  <a href="https://www.uncdf.org/article/4472/making-malaysian-fintech-fairer" type="button"
                    class="btn btn-sm btn-outline-secondary" style="margin-bottom:20px;" target="_blank">read
                    more...</a>
                  <br>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END 11 Mar 2019 -->

        <div class="col-md-2"></div>
      </div>


      <div class="row">
        <div class="col-md-2"></div>

        <!-- START 23 Nov 2018 -->
        <div class="col-md-4">
          <div class="col-md-2"></div>
          <div class="card mb-4 box-shadow">
            <img class="card-img-top img-thumbnail" src="assets/img/news5.jpg" alt="Thumbnail [100%x225]"
              style="height: 225px; width: 100%; display: block;" data-holder-rendered="true">
            <div class="card-body">
              <p class="card-text">
                23 Nov 2018, Kuala Lumpur – Vsure’s Web Channel, goes LIVE! Now you can purchase insurance
                online @
              </p>
              <div class="d-flex justify-content-between align-items-center">
                <div class="btn-group" style="    margin-bottom: 10px;">
                  <a type="button" href="../#offer " class="btn btn-sm btn-outline-secondary" target="_blank">
                    read more...
                  </a>

                </div>
                <br>
              </div>
            </div>
          </div>
        </div>
        <!-- END 23 Nov 2018 -->


        <!-- START 14 Nov 2108 -->
        <div class="col-md-4">
          <div class="card mb-4 box-shadow">
            <img class="card-img-top img-thumbnail"
              data-src="holder.js/100px225?theme=thumb&amp;bg=55595c&amp;fg=eceeef&amp;text=Thumbnail"
              alt="Thumbnail [100%x225]" src="assets/img/news2.jpg" data-holder-rendered="true"
              style="height: 225px; width: 100%; display: block;">
            <div class="card-body">
              <p class="card-text">
                14 Nov 2108, Kuala Lumpur – On-Demand! What does it mean in the world
                of Insurance?
              </p>
              <div class="d-flex justify-content-between align-items-center">
                <div class="btn-group">
                  <a href="https://www.facebook.com/vsure.life/posts/397202900820254?__tn__=K-R" type="button"
                    class="btn btn-sm btn-outline-secondary" target="_blank">
                    read more...
                  </a>
                </div>

              </div>
            </div>
          </div>
        </div>
        <!-- END 14 Nov 2108, -->

        <div class="col-md-2"></div>
      </div>


      <div class="row" style="margin-top: 10px;">
        <div class="col-md-2"></div>

        <!-- START 1 Aug 2018 -->
        <div class="col-md-4">
          <div class="card mb-4 box-shadow">
            <img class="card-img-top img-thumbnail"
              data-src="holder.js/100px225?theme=thumb&amp;bg=55595c&amp;fg=eceeef&amp;text=Thumbnail"
              alt="Thumbnail [100%x225]" src="assets/img/news4.jpg" data-holder-rendered="true"
              style="height: 225px; width: 100%; display: block;">
            <div class="card-body">
              <p class="card-text">
                1 Aug 2018, Iceland – VSure.life has been selected by Iceland based idea2scale accelerator as
                one...
              </p>
              <div class="d-flex justify-content-between align-items-center">
                <div class="btn-group">
                  <a href="https://www.ideatoscale.com/accelerator-1/?fbclid=IwAR1XMFUB9eC2ChVK7hVyfZHb7Vk3D5gH5LyU6VEDaroIMD_a-nEVLqHULoE#new-page-5"
                    type="button" class="btn btn-sm btn-outline-secondary" target="_blank">
                    read more...
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END 1 Aug 2018-->

        <!-- START 11 Jul 2018 -->
        <div class="col-md-4">
          <div class="col-md-2"></div>
          <div class="card mb-4 box-shadow">
            <img class="card-img-top img-thumbnail" src="assets/img/news1.jpg" alt="Thumbnail [100%x225]"
              style="height: 225px; width: 100%; display: block;" data-holder-rendered="true">
            <div class="card-body">
              <p class="card-text">
                11 Jul 2018, Hong Kong – VSure.Life Makes a Mark for Insurtech at RISE
                Hong Kong
              </p>
              <div class="d-flex justify-content-between align-items-center">
                <div class="btn-group" style="    margin-bottom: 10px;">
                  <a type="button"
                    href="https://en.prnasia.com/releases/apac/vsure-life-makes-a-mark-for-insurtech-at-rise-hong-kong-216580.shtml"
                    class="btn btn-sm btn-outline-secondary" target="_blank">
                    read more...
                  </a>

                </div>
                <br>
              </div>
            </div>
          </div>
        </div>
        <!-- END 11 Jul 2018 -->

        <div class="col-md-2"></div>
      </div>


      <div class="row" style="margin-top: 10px;">
        <div class="col-md-2"></div>
        <!-- START Jan 2018 -->
        <div class="col-md-4">
          <div class="col-md-2"></div>
          <div class="card mb-4 box-shadow">
            <img class="card-img-top img-thumbnail" src="assets/img/news3.jpg" alt="Thumbnail [100%x225]"
              style="height: 225px; width: 100%; display: block;" data-holder-rendered="true">
            <div class="card-body">
              <p class="card-text">
                Jan 2018, Kuala Lumpur – VSure on PitchIn
              </p>
              <div class="d-flex justify-content-between align-items-center">
                <div class="btn-group" style="    margin-bottom: 10px;">
                  <a type="button" href="https://www.equity.pitchin.my/businesses/vsure-tech-sdn-bhd"
                    class="btn btn-sm btn-outline-secondary" target="_blank">
                    read more...
                  </a>

                </div>
                <br>
              </div>
            </div>
          </div>
        </div>
        <!-- END Jan 2018 -->
        <div class="col-md-2"></div>
      </div>

    </div>
  </section>
  <br>
  <app-footer></app-footer>

</body>
